import React, { useCallback, useEffect, useRef, useState } from "react";
import MenuStyle from "@/components/menu/Menu.module.css";
import MenuToggleIcon from "@/components/menu/MenuToggleIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { useHasCorporateAdminRights } from "@/utils/misc";
import { animateSubmenu } from "@/components/menu/menuUtils";
import { Permission } from "@/pages/general-settings/permissions-attribution/permissions";

export type MenuItemType = {
  title: React.ReactElement;
  pathname: string;
  relatedPaths?: RegExp[];
  path?: string;
  active?: boolean;
  icon?: string;
  admin?: boolean;
  corporateAdmin?: boolean;
  permissions?: Permission[];
  submenu?: MenuItemType[];
  isVisible?: Function;
};

type ItemComponent = {
  item: MenuItemType;
  activePaths: MenuItemType[];
  onChangePage?: Function;
  isTop?: boolean; // top menu item components have different styling
  filterMenuItems?: (item: MenuItemType) => boolean;
};

export default function MenuItem({
  item,
  isTop,
  activePaths,
  onChangePage,
  filterMenuItems,
}: ItemComponent) {
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const hasCorporateAdminRights = useHasCorporateAdminRights();
  const [styleClasses, setStyleClasses] = useState(MenuStyle.item);
  const navigate = useNavigate();
  const location = useLocation();
  const submenuRef = useRef<HTMLDivElement>(null);

  const handleClickMenuItem = useCallback(() => {
    // change page if the link has no submenu
    if (!item.submenu || item.submenu?.length <= 0) {
      if (onChangePage) {
        onChangePage();
      }
      setIsOpen(false); // leaf can't be opened
      navigate(item.path);
    } else {
      // need to delay here
      // otherwise the node properties are not
      // available, and the display fail.
      // Later edit: set timeout to a larger value
      // to ensure that all submenu items are visible
      // when the page loads with a menu item open
      setTimeout(() => {
        animateSubmenu(submenuRef, !isOpen);
      }, 350);
      setIsOpen(!isOpen);
    }
  }, [isOpen, item, navigate, onChangePage]);

  // Set proper active path
  useEffect(() => {
    if (activePaths.map((x) => x.pathname).includes(item.pathname)) {
      setIsActive(true);
      // simulate click to open tab of current active link when changing route
      if (
        (item.submenu != null || item.submenu?.length > 0) &&
        isOpen === false
      ) {
        handleClickMenuItem();
      }
    } else {
      setIsActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePaths, item.pathname, item.submenu]);

  // Compute style for the item
  useEffect(() => {
    let newStyle = MenuStyle.item;
    if (isTop) {
      newStyle += ` ${MenuStyle.itemTop}`;
    }
    if (isTop && isActive && isOpen) {
      newStyle += ` ${MenuStyle.itemTopActive}`;
    }
    if (isActive) {
      newStyle += ` ${MenuStyle.itemActive}`;
    }
    setStyleClasses(newStyle);
  }, [isActive, isOpen, isTop]);

  if (!location.pathname.includes("demo")) {
    if (item.corporateAdmin && !hasCorporateAdminRights) {
      return <></>;
    }
  }

  return (
    <>
      <div className={styleClasses} onClick={() => handleClickMenuItem()}>
        {item.icon != null && (
          <div className={`${MenuStyle.icon} ${MenuStyle.menuIcon}`}>
            <img
              height="18"
              width="auto"
              src={item.icon}
              alt={`Icon for ${item.pathname}`}
            />
          </div>
        )}
        <div className={MenuStyle.title}>{item.title}</div>
        {item.submenu != null && <MenuToggleIcon open={isOpen} />}
      </div>
      {item.submenu != null && item.submenu.length > 0 && (
        <div className={`${MenuStyle.subitem}`} ref={submenuRef}>
          {item.submenu.filter(filterMenuItems).map((e: MenuItemType) => (
            <MenuItem
              item={e}
              activePaths={activePaths}
              key={e.pathname}
              onChangePage={onChangePage}
              filterMenuItems={filterMenuItems}
            />
          ))}
        </div>
      )}
    </>
  );
}
