import React from "react";
import { useBackend } from "@/utils/misc";
import { Grid, IconButton } from "@mui/material";
import { EmailSent } from "@/components/icons/EmailSent";
import { EmailOpened } from "@/components/icons/EmailOpened";
import { ClickRate } from "@/components/icons/ClickRate";
import { Target } from "@/components/icons/Target";
import DownloadButton from "@/components/buttons/DownloadButton";
import { useAuth0 } from "@auth0/auth0-react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import styled from "@emotion/styled";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { downloadEmailMessages } from "@/utils/exports";
import { useTranslation } from "react-i18next";
import { BasicStatCard } from "@/components/dashboard/BasicStatCard";
import CompromisingActions from "../CompromisingActions";
import { TimeCohorts } from "../TimeCohorts";

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 400,
  },
});

export const computeURLFromProps = ({
  dateFrom,
  dateTo,
  department,
  activeUsersOnly,
  isCorporateView,
}) => {
  let stats_url = import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/stats/basic";
  if (isCorporateView) {
    stats_url = stats_url + "/corporation";
  }
  let params = new URLSearchParams();

  params.append("date_from", dateFrom.toISOString());
  params.append("date_to", dateTo.toISOString());
  params.append("active_only", activeUsersOnly);

  if (department) {
    params.append("department", department);
  }

  return stats_url + "?" + params.toString();
};

export default function BasicStats(props: any) {
  const { t } = useTranslation();

  const { getAccessTokenSilently } = useAuth0();
  const stats_url = computeURLFromProps(props);
  const { data, error, isPending } = useBackend(stats_url.toString());
  return (
    <>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item md={3} sm={6} xs={12}>
          <BasicStatCard
            title={t("Simulations Sent")}
            icon={<EmailSent sx={{ fontSize: "32px" }} />}
            action={
              <DownloadButton
                title={t("download all simulations sent since the beginning")}
                onClick={() =>
                  downloadEmailMessages(
                    getAccessTokenSilently,
                    props.dateFrom,
                    props.dateTo,
                    props.department,
                  )
                }
              />
            }
            data={data?.nbSent?.toFixed(0)}
            isLoading={isPending}
            isError={error}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <BasicStatCard
            title={t("Open Rate")}
            icon={<EmailOpened sx={{ fontSize: "32px" }} />}
            data={`${
              data?.percentageOpen
                ? data?.percentageOpen?.toFixed(0)
                : data?.nbSent > 5
                  ? 73
                  : 0
            }%`}
            isLoading={isPending}
            isError={error}
            action={
              data?.isOffice ? (
                <CustomWidthTooltip
                  title={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div>
                        {t(
                          "Due to Office limitations, the exact opened rate can't be retrieved.",
                        )}
                      </div>
                      <div>
                        {t(
                          "This value is the approximate rate based on Mantra's internal data.",
                        )}
                      </div>
                    </div>
                  }
                >
                  <IconButton>
                    <ErrorOutlineIcon color="primary" />
                  </IconButton>
                </CustomWidthTooltip>
              ) : (
                <></>
              )
            }
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <BasicStatCard
            title={t("Click Rate")}
            icon={<ClickRate sx={{ fontSize: "32px" }} />}
            data={`${data?.percentageClick.toFixed(0)}%`}
            isLoading={isPending}
            isError={error}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <BasicStatCard
            title={t("Report Rate")}
            icon={<Target sx={{ fontSize: "32px" }} />}
            data={`${data?.percentageReport.toFixed(0)}%`}
            isLoading={isPending}
            isError={error}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={6} xs={12}>
          <CompromisingActions
            dateFrom={props.dateFrom}
            dateTo={props.dateTo}
            department={props.department}
            isCorporateView={props.isCorporateView}
            activeUsersOnly={props.activeUsersOnly}
          />
        </Grid>
        {!props.isCorporateView && (
          <Grid item sm={6} xs={12}>
            <TimeCohorts
              dateFrom={props.dateFrom}
              department={props.department}
              activeUsersOnly={props.activeUsersOnly}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
