import React from "react";
import styled from "styled-components";
import { LanguageTag } from "@/pages/awareness/courses/utils/LanguageTag";
import { Chip, Tooltip } from "@mui/material";
import { Check, ClearOutlined, MoreHoriz } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

interface ProgressTitleProps {
  $subscribed: boolean;
}

interface UserInfoTitleProps {
  $center?: boolean;
  $flex?: boolean;
}

const ProgressTile = styled.div<ProgressTitleProps>`
  width: 20px;
  height: 20px;
  border: solid 1px;
  color: #eae5e5;
  background: ${(props) => {
    if (!props.$subscribed) {
      return "repeating-linear-gradient(-45deg, transparent 0 3px, hsl(0, 0%, 45%) 3px 5px)";
    }
  }};
  border-radius: 4px;
`;

const UserInfoTile = styled.td<UserInfoTitleProps>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: ${(props) => (props.$center ? "center" : "inherit")};
  ${(props) =>
    props.$flex &&
    `
      display: flex;
      justify-content: center;
    `}
`;

const UserProgressCourseCell = ({ user, course }) => {
  const isCourseSubscribed = user.subscribed_courses.includes(course.id);
  const { t } = useTranslation();
  if (!isCourseSubscribed) {
    return (
      <Tooltip title={t("Not Subscribed")} arrow>
        <ProgressTile $subscribed={isCourseSubscribed} />
      </Tooltip>
    );
  }
  if (user.finished_courses.includes(course.id)) {
    return (
      <Tooltip title={t("Course completed")} arrow>
        <Check sx={{ color: "white", backgroundColor: "success.main" }} />
      </Tooltip>
    );
  }
  if (user.viewed_courses.includes(course.id)) {
    return (
      <Tooltip title={t("Quizz failed")} arrow>
        <ClearOutlined
          sx={{ color: "white", backgroundColor: "warning.main" }}
        />
      </Tooltip>
    );
  }
  if (user.current_course === course.id) {
    return (
      <Tooltip title={t("Ongoing")} arrow>
        <MoreHoriz sx={{ color: "white", backgroundColor: "#4CA2ED" }} />
      </Tooltip>
    );
  }
  return (
    <Tooltip
      title={
        isCourseSubscribed
          ? t("Subscribed but not started")
          : t("Not Subscribed")
      }
      arrow
    >
      <ProgressTile $subscribed={isCourseSubscribed} />
    </Tooltip>
  );
};

const theme = createTheme({
  palette: {
    warning: {
      main: "#F2994A",
      light: "#F2394A",
    },
    success: {
      main: "#43B170",
    },
    secondary: {
      main: "#626262",
    },
    error: {
      main: "#F6285FCC",
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          width: 85,
          fontWeight: 500,
        },
        colorSuccess: {
          backgroundColor: "#65C58B1A",
        },
        colorSecondary: {
          backgroundColor: "#6262621A",
        },
        colorWarning: {
          backgroundColor: "#F2994A1A",
        },
        colorError: {
          backgroundColor: "#F6285F1A",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
          width: "20px",
          height: "20px",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "12px",
        },
      },
    },
  },
});

const GetChip = (status, completion, active) => {
  const { t } = useTranslation();
  if (status === "drop_off" && completion < 100) {
    return (
      <Tooltip
        title={t("Interacted with a course more than a month ago")}
        arrow
      >
        <Chip
          label="Late"
          variant={"outlined"}
          sx={{
            color: "#467B98",
            backgroundColor: "#CFE7F5",
            borderColor: "#467B98",
          }}
        />
      </Tooltip>
    );
  }
  if (status === "on_track" || (status === "drop_off" && completion === 100)) {
    return (
      <Tooltip
        title={t("Interacted with a course less than a month ago")}
        arrow
      >
        <Chip
          label={t("On Track")}
          variant={"outlined"}
          sx={{
            color: "#569B48",
            borderColor: "#569B48",
            backgroundColor: "#A3EBC0",
          }}
        />
      </Tooltip>
    );
  }
  if (status === "not_set_up") {
    return (
      <Tooltip title={t("Can’t receive any course")} arrow>
        <Chip
          label={t("Not Set Up")}
          variant={"outlined"}
          sx={{
            color: "#435664",
            borderColor: "#435664",
            backgroundColor: "#E0E5EA",
          }}
        />
      </Tooltip>
    );
  }
  if (!active) {
    return (
      <Chip
        label={t("Inactive")}
        variant={"outlined"}
        sx={{ color: "#A6A6A6FF", backgroundColor: "#A6A6A61A" }}
      />
    );
  }
  return (
    <Tooltip title={t("Never interacted with any course")} arrow>
      <Chip
        label={t("Not Started")}
        variant={"outlined"}
        sx={{
          color: "#CE982F",
          borderColor: "#CE982F",
          backgroundColor: "#F6E6C9",
        }}
      />
    </Tooltip>
  );
};

interface UserProgress {
  firstname: string;
  lastname: string;
  active: boolean;
  language: string;
  has_chat_account: boolean;
  status: string;
  departments: string;
  current_course?: number;
  subscribed_courses: Array<number>;
  finished_courses: Array<number>;
  viewed_courses: Array<number>;
  completion: number;
}

const UserProgressRow = ({
  user,
  courses,
}: {
  user: UserProgress;
  courses: Array<any>;
}) => {
  return (
    <ThemeProvider theme={theme} key={`${user.firstname}_${user.lastname}`}>
      <tr style={user.has_chat_account ? {} : { color: "hsl(0, 0%, 65%)" }}>
        <UserInfoTile>{user.firstname}</UserInfoTile>
        <UserInfoTile>{user.lastname}</UserInfoTile>
        <UserInfoTile $center $flex>
          <LanguageTag language={user.language} />
        </UserInfoTile>
        <UserInfoTile $center>{user.departments}</UserInfoTile>
        <UserInfoTile $center>{user.completion}%</UserInfoTile>
        <UserInfoTile>
          {GetChip(user.status, user.completion, user.has_chat_account)}
        </UserInfoTile>
        {courses.map((course, i: number) => (
          <td key={i}>
            <UserProgressCourseCell
              user={user}
              course={course}
              key={`${user.firstname}_${user.lastname}_${course.id}_${i}`}
            />
          </td>
        ))}
      </tr>
    </ThemeProvider>
  );
};

export default UserProgressRow;
