import React, { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Grid, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useUserContext } from "@/utils/contexts/UserContext";
import { sentryCaptureException } from "@/utils/sentry";
import StickyHeader from "@/components/StickyHeader";
import TimeFrameDropdown from "@/pages/browser-defender/components/TimeFrameDropdown";
import GenericCard from "@/pages/browser-defender/components/GenericCard";
import { ActivateBrowserDefender } from "@/pages/browser-defender/components/ActivateBrowserDefender";
import { PasswordStrengthDistribution } from "@/pages/browser-defender/demo/passwordStrength/PasswordStrengthDistribution";
import StatCards from "@/pages/browser-defender/dashboard/StatCards";
import DataPerUser from "@/pages/browser-defender/dashboard/DataPerUser";
import { useTranslation } from "react-i18next";

export function Dashboard() {
  const { t } = useTranslation();
  const [activeFeature, setActiveFeature] = useState(true);
  const [dateFromNbWeeksAgo, setDateFromNbWeeksAgo] = useState(1);
  const [eventsData, setEventsData] = useState({
    values: [],
    loading: true,
  });
  const [dataPerUser, setDataPerUser] = useState({
    values: [],
    filteredValues: [],
    loading: true,
  });

  const [error, setError] = useState({
    events: false,
    dataPerUser: false,
  });

  const { getAccessTokenSilently } = useAuth0();
  const info_user = useUserContext();

  const fetchStatsData = useCallback(
    async (interval) => {
      try {
        const accessToken = await getAccessTokenSilently();
        const url = new URL(
          `${
            import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
          }/dashboard/event_overview?interval=${interval}`,
        ).toString();

        let resource;

        try {
          resource = await axios.get(url, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          });
        } catch (e) {
          if (e.response.status === 400) {
            setEventsData({
              values: [],
              loading: false,
            });
            setActiveFeature(false);
            return;
          }
        }

        setEventsData({
          values: resource.data,
          loading: false,
        });
      } catch (e) {
        sentryCaptureException(e);
        setError({ events: true, dataPerUser: error.dataPerUser });
      }
    },
    [getAccessTokenSilently, error.dataPerUser],
  );

  const fetchDataPerUser = useCallback(
    async (interval) => {
      try {
        const accessToken = await getAccessTokenSilently();

        const url = `${
          import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
        }/dashboard/data_per_user?interval=${interval}`;

        let resource;
        try {
          resource = await axios.get(url, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          });
        } catch (e) {
          if (e.response.status === 400) {
            setDataPerUser({
              values: [],
              filteredValues: [],
              loading: false,
            });
            setActiveFeature(false);
            return;
          }
        }

        setDataPerUser({
          values: resource.data,
          filteredValues: resource.data,
          loading: false,
        });
      } catch (e) {
        sentryCaptureException(e);
        setError({ events: error.events, dataPerUser: true });
      }
    },
    [getAccessTokenSilently, error.events],
  );

  useEffect(() => {
    if (!info_user.email) {
      return;
    }

    fetchStatsData(dateFromNbWeeksAgo);
    fetchDataPerUser(dateFromNbWeeksAgo);
  }, [dateFromNbWeeksAgo, info_user, fetchStatsData, fetchDataPerUser]);

  if (!activeFeature) {
    return (
      <div style={{ position: "relative", top: 0, left: 0 }}>
        <div
          style={{ position: "absolute", left: "0%", right: "0%", top: "0%" }}
        >
          <ActivateBrowserDefender />
        </div>
      </div>
    );
  }

  return (
    <>
      <StickyHeader>
        <Stack direction="row" gap={3} justifyContent="end" alignItems="center">
          <Typography
            variant="h2"
            style={{
              marginRight: "auto",
              maxHeight: "2rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {t("Browser Defender Dashboard")}
          </Typography>

          <TimeFrameDropdown
            dateFromNbWeeksAgo={dateFromNbWeeksAgo}
            setDateFromNbWeeksAgo={setDateFromNbWeeksAgo}
            setSelectedPeriod={() => {}}
          />
        </Stack>
      </StickyHeader>
      <Grid container alignItems="stretch">
        <StatCards error={error} eventsData={eventsData} />
        {info_user.email === "demo@acme.com" && (
          <Grid item xs={12}>
            <GenericCard title={"Password Strength Distribution"}>
              <PasswordStrengthDistribution />
            </GenericCard>
          </Grid>
        )}
        <Grid item xs={12}>
          <GenericCard
            title={t("Data per user")}
            isLoading={dataPerUser.loading}
          >
            <DataPerUser data={dataPerUser} setData={setDataPerUser} />
          </GenericCard>
        </Grid>
      </Grid>
    </>
  );
}
