import React from "react";
import { Box, ListItem, Typography, useTheme } from "@mui/material";

export const EtcRow = () => {
  const theme = useTheme();

  return (
    <ListItem sx={{ py: 0 }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          borderTop: "1px solid " + theme.palette.divider,
        }}
      >
        <Typography></Typography>
        <Typography
          sx={{
            py: "2px",
            textAlign: "center",
            fontSize: 20,
            fontWeight: "bold",
            color: "neutral.main",
          }}
        >
          {"⋮"}
        </Typography>
        <Typography></Typography>
      </Box>
    </ListItem>
  );
};
