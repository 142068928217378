import { Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export const ReporterMetadata = ({ threat }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const reportDate = threat.reportDate.toLocaleString(undefined, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

  return (
    <Stack textAlign="end" spacing={1}>
      <Typography>
        <em style={{ color: theme.palette.text.secondary }}>
          {t("Reported by")}:
        </em>{" "}
        <strong>{threat.reporter.email}</strong>
      </Typography>
      <Typography>
        <em style={{ color: theme.palette.text.secondary }}>
          {t("Report date")}:
        </em>{" "}
        {reportDate}
      </Typography>
    </Stack>
  );
};
