import { usePaginationData } from "@/utils/pagination";
import { FeedbackType } from "@/pages/threats/service/domain";
import {
  useDeleteThreatsMutation,
  useFeedbackThreatsMutation,
  useThreatListQuery,
  useUnarchiveThreatsMutation,
} from "@/pages/threats/api";
import {
  DetailedThreat,
  ThreatFilterParams,
  ThreatId,
  ThreatSourceParams,
  ThreatsService,
} from "@/pages/threats/service/types";
import { appDispatch, appSelector } from "@/store";
import {
  addThreatToSelection,
  displayThreatDetails,
  expandThreat,
  removeThreatFromSelection,
  resetThreatSelection,
  selectDetailIsLoading,
  selectDetails,
  selectIsThreatExpanded,
  selectIsThreatSelected,
  selectSelectedThreatsIds,
} from "@/pages/threats/service/threatsSlice";
import { SelectionListType } from "@/components/SelectionCheckbox";
import { useMemo } from "react";
import { useListOptions } from "@/pages/threats/service/listOptions";

export const useShowThreat = () => {
  const dispatch = appDispatch();
  return (threatId: ThreatId) => dispatch(expandThreat(threatId));
};

export const useIsThreatDisplayed = (threatId: ThreatId) => {
  return appSelector(selectIsThreatExpanded(threatId));
};

export const useThreatService = (): ThreatsService => {
  const dispatch = appDispatch();
  const pagination = usePaginationData();

  const listOptions = useListOptions();
  const { data: threatPage, isFetching } = useThreatListQuery({
    startIndex: pagination.startIndex,
    pageSize: pagination.rowsPerPage,
    listOptions: listOptions.options,
  });

  const threats = useMemo(() => threatPage?.items || [], [threatPage]);
  const totalCount = useMemo(() => threatPage?.totalCount || 0, [threatPage]);
  const selectedIds = appSelector(selectSelectedThreatsIds);
  const selectedThreats = useMemo(
    () => threats.filter((t) => selectedIds.includes(t.id)),
    [threats, selectedIds],
  );

  const selectionList: SelectionListType = {
    isSelected: (objectId) => appSelector(selectIsThreatSelected(objectId)),
    resetSelection() {
      dispatch(resetThreatSelection());
    },
    addToSelection(objectId: number) {
      dispatch(addThreatToSelection(objectId));
    },
    removeFromSelection(objectId: number) {
      dispatch(removeThreatFromSelection(objectId));
    },
    selectedIds: selectedIds,
  };

  const [triggerDeleteSelectedThreats] = useDeleteThreatsMutation();
  const [triggerUnarchiveSelectedThreats] = useUnarchiveThreatsMutation();
  const [triggerSendFeedback] = useFeedbackThreatsMutation();

  const details = appSelector(selectDetails);

  const displayThreat = useMemo<DetailedThreat | null>(() => {
    if (!details) return null;
    const threat = threats.find((t) => t.id === details.id);
    if (!threat) return null;
    return { ...threat, details: details.details };
  }, [details, threats]);

  return {
    selectionList,
    pagination,
    listOptions: listOptions.options,

    threats: threats,
    totalCount: totalCount,

    selectedThreats: selectedThreats,

    displayThreat: displayThreat,
    isFetching,
    detailsLoading: appSelector(selectDetailIsLoading),

    unarchiveSelectedThreats() {
      triggerUnarchiveSelectedThreats(selectionList.selectedIds);
    },

    sendFeedbackForSelectedThreats(
      feedbackType: FeedbackType,
      feedbackComment: string,
    ) {
      dispatch(displayThreatDetails(null));
      triggerSendFeedback({
        threatIds: selectionList.selectedIds,
        feedbackType,
        feedbackComment,
      });
    },

    deleteSelectedThreats() {
      triggerDeleteSelectedThreats(selectionList.selectedIds);
    },

    search(term) {
      dispatch(displayThreatDetails(null));
      selectionList.resetSelection();

      listOptions.search(term);
    },

    onFilterChange(filter: ThreatFilterParams) {
      dispatch(displayThreatDetails(null));
      selectionList.resetSelection();

      listOptions.onFilterChange(filter);
    },

    onSortChange(sort) {
      dispatch(displayThreatDetails(null));
      selectionList.resetSelection();

      listOptions.onSortChange(sort);
    },

    onTabChange(tab: ThreatSourceParams) {
      dispatch(displayThreatDetails(null));
      selectionList.resetSelection();

      pagination.changePage(0);
      listOptions.onTabChange(tab);
    },

    actDisplayThreat(threatId: ThreatId) {
      dispatch(displayThreatDetails(threatId));
    },
  };
};
