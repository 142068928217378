import React from "react";
import Tabs, {
  TabsContextProvider,
  useTabsContext,
} from "@/components/controls/Tabs";
import { BannerPreferencesDashboard } from "@/pages/banners/preferences/BannerPreferencesDashboard";
import StickyHeader from "@/components/StickyHeader";
import { Typography } from "@mui/material";
import { BannersSetupChecklist } from "@/pages/banners/setup/BannersSetupChecklist";
import { useTranslation } from "react-i18next";
import { useUserContext } from "@/utils/contexts/UserContext";
import { BannersAdmin } from "@/pages/banners/Admin";

const SetupInner: React.FC = () => {
  const { currentTabComponent } = useTabsContext();
  const { t } = useTranslation();

  return (
    <>
      <StickyHeader height="120px">
        <Typography variant="h2">{t("Banners Setup")}</Typography>
        <Tabs />
      </StickyHeader>
      {currentTabComponent}
    </>
  );
};

export const Setup: React.FC = () => {
  const { t } = useTranslation();
  const { is_staff } = useUserContext();

  const defaultTabs = [
    {
      anchor: "setup-checklist",
      label: t("Setup Checklist"),
      component: <BannersSetupChecklist />,
    },
    {
      anchor: "preferences",
      label: t("Preferences"),
      component: <BannerPreferencesDashboard />,
    },
  ];

  const tabs = is_staff
    ? [
        ...defaultTabs,
        { anchor: "admin", label: t("Admin"), component: <BannersAdmin /> },
      ]
    : defaultTabs;

  return (
    <TabsContextProvider value={{ tabs: tabs }}>
      <SetupInner />
    </TabsContextProvider>
  );
};
