import React, { useState } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import TaskIcon from "@mui/icons-material/Task";

type CopyToClipboardProps = {
  callToAction: React.ReactNode;
  content: string;
};

export function CopyToClipboard({
  callToAction,
  content,
}: CopyToClipboardProps) {
  const [clipboardCopy, setClipboardCopy] = useState(false);

  function copyJsonPolicy() {
    navigator.clipboard.writeText(content);
    setClipboardCopy(true);
    setTimeout(() => setClipboardCopy(false), 2000);
  }

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ justifyContent: "space-between", alignItems: "center" }}
    >
      <Typography color={"textSecondary"} sx={{ mb: "1rem" }}>
        {callToAction}
      </Typography>
      <IconButton onClick={copyJsonPolicy} disabled={content ? false : true}>
        {clipboardCopy ? <TaskIcon /> : <FileCopyIcon />}
      </IconButton>
    </Stack>
  );
}
