import React from "react";
import { IconButton, Typography } from "@mui/material";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import { EmailHeaderNameValue } from "@/pages/threats/service/domain";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

const EmailHeader = ({ header }: { header: EmailHeaderNameValue }) => {
  return (
    <Typography>
      <strong>{header.name}</strong>: {header.value}
    </Typography>
  );
};

const EmailHeaders = ({ headers }: { headers: EmailHeaderNameValue[] }) => {
  const { t } = useTranslation();
  const copyToClipboard = () => {
    const headersToExport = headers.reduce(
      (key, header) => `${key}${header.name}:  ${header.value}\n`,
      "",
    );
    navigator.clipboard.writeText(headersToExport);
  };

  return (
    <Box sx={{ marginTop: "10px", marginBottom: "10px", position: "relative" }}>
      <Box sx={{ position: "absolute", top: 0, right: 0 }}>
        <IconButton title={t("Copy Headers to Clipboard")}>
          <CopyAllIcon sx={{ color: "#bdbdbd" }} onClick={copyToClipboard} />
        </IconButton>
      </Box>
      {headers.map((header) => (
        <EmailHeader key={header.name + header.value} header={header} />
      ))}
    </Box>
  );
};

export default EmailHeaders;
