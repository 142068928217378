import React from "react";
import { Badge } from "@/pages/my-progress/badges/Badge";
import { useBackend } from "@/utils/misc";

// badges 1st row
import binoculars from "@/pages/my-progress/badges/icons/binoculars.svg";
import target from "@/pages/my-progress/badges/icons/target.svg";
import ninja from "@/pages/my-progress/badges/icons/ninja.svg";
import email from "@/pages/my-progress/badges/icons/email.svg";
import fraud from "@/pages/my-progress/badges/icons/fraud.svg";
import personal from "@/pages/my-progress/badges/icons/personal.svg";
// badges 2nd row
import market from "@/pages/my-progress/badges/icons/market.svg";
import staff from "@/pages/my-progress/badges/icons/staff.svg";
import runner from "@/pages/my-progress/badges/icons/runner.svg";
import medallion from "@/pages/my-progress/badges/icons/medallion.svg";
import { useTranslation } from "react-i18next";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";

export function Badges() {
  // Perform the request to retrieve all the badges for the user
  const endpoint_url =
    import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/current_user/badges";
  const { data, error, isPending } = useBackend(endpoint_url);
  const { t } = useTranslation();

  let body;
  if (isPending || !data) body = <div>{t("Loading")}...</div>;
  if (error) body = <div>{t("An error occurred")}</div>;
  if (data) {
    if (data?.length === 0) {
      body = <div>{t("No data available")}</div>;
    } else {
      body = (
        <Grid container direction="column">
          <Grid item xs={5}>
            <Grid container>
              <Grid item xs={2.4}>
                <Badge
                  icon={binoculars}
                  title={t("Reporter")}
                  tooltip={t("You reported your first email")}
                  enabled={data.reporter}
                />
              </Grid>
              <Grid item xs={2.4}>
                <Badge
                  icon={target}
                  title={t("Sniper")}
                  tooltip={t("You reported 3 simulated phishing in a row")}
                  enabled={data.sniper}
                />
              </Grid>
              <Grid item xs={2.4}>
                <Badge
                  icon={ninja}
                  title={t("Ninja")}
                  tooltip={t("You have reported 10 simulated phishing emails")}
                  enabled={data.ninja}
                />
              </Grid>
              <Grid item xs={2.4}>
                <Badge
                  icon={email}
                  title={t("Curious")}
                  tooltip={t("You have opened your first email")}
                  enabled={data.curious}
                />
              </Grid>
              <Grid item xs={2.4}>
                <Badge
                  icon={fraud}
                  title={t("Investigator")}
                  tooltip={t("You have opened 3 simulated phishing in a row")}
                  enabled={data.investigator}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid container>
              <Grid item xs={2.4}>
                <Badge
                  icon={personal}
                  title={t("Proactive")}
                  tooltip={t("You have opened 10 simulated phishing emails")}
                  enabled={data.proactive}
                />
              </Grid>
              <Grid item xs={2.4}>
                <Badge
                  icon={market}
                  title={t("Informant")}
                  tooltip={t("You have reported a threat")}
                  enabled={data.informant}
                />
              </Grid>
              <Grid item xs={2.4}>
                <Badge
                  icon={staff}
                  title={t("Secret agent")}
                  tooltip={t("You have reported 3 threats")}
                  enabled={data.secretAgent}
                />
              </Grid>
              <Grid item xs={2.4}>
                <Badge
                  icon={runner}
                  title={t("Sprinter")}
                  tooltip={t(
                    "You opened an email very quickly after receiving it",
                  )}
                  enabled={data.sprinter}
                />
              </Grid>
              <Grid item xs={2.4}>
                <Badge
                  icon={medallion}
                  title={t("Completionist")}
                  tooltip={t("You have got all the badges")}
                  enabled={data.completionist}
                />
                <Grid />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }
  }

  return (
    <Card sx={{ height: "unset" }}>
      <CardHeader title={t("Badges")} />
      <CardContent>{body}</CardContent>
    </Card>
  );
}
