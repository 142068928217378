import * as React from "react";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DownloadsPolicyDialog from "@/pages/browser-defender/setup/DownloadsPolicyDialog";
import { useTranslation } from "react-i18next";

function MediaTypeAccordion({
  mediaType,
  onChangeCallback,
  expanded,
  originalChoice = [],
  canEdit = true,
}) {
  const [selectAllStatus, setSelectAllStatus] = React.useState(
    originalChoice.length === mediaType.media_types.length ? 1 : 0,
  ); // 1 = all selected, 0 = manual selection, -1 = none selected
  const [selectedItems, setSelectedItems] = React.useState(originalChoice);
  const { t } = useTranslation();

  function toggleSelection() {
    if (selectAllStatus === 1) {
      setSelectedItems([]);
      setSelectAllStatus(-1);
      return;
    }
    setSelectedItems(mediaType.media_types.map((item) => item));
    setSelectAllStatus(1);
  }

  function handleCheckboxToggle(event) {
    if (event.target.checked) {
      const selection = [...selectedItems, event.target.name];
      setSelectedItems(selection);
      setSelectAllStatus(
        selection.length === mediaType.media_types.length ? 1 : 0,
      );
    } else {
      const selection = selectedItems.filter(
        (item) => item !== event.target.name,
      );

      setSelectedItems(selection);
      setSelectAllStatus(selection.length === 0 ? -1 : 0);
    }
  }

  // t("Executables & scripting")
  // t("Archive & packaging")
  // t("Documents & data")
  // t("Media")
  // t("System & configuration")
  // t("Security & cryptography")
  // t("Potentially dangerous or malicious")
  // t("Miscellaneous")
  const mediaTypeLabel = t(mediaType.label);

  return (
    <Accordion expanded={expanded} onChange={onChangeCallback(mediaType.key)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Badge
          showZero
          badgeContent={selectedItems.length}
          color="primary"
          sx={{ mr: 3 }}
        >
          <CheckBoxIcon color="action" />
        </Badge>
        <Typography sx={{ fontWeight: "bold" }}>{mediaTypeLabel}</Typography>
      </AccordionSummary>
      {canEdit && (
        <AccordionActions>
          <Button size="small" onClick={toggleSelection}>
            {selectAllStatus === 1 ? t("Unselect all") : t("Select all")}
          </Button>
        </AccordionActions>
      )}
      <AccordionDetails>
        <FormGroup name={mediaType.key}>
          <Grid container spacing={2}>
            {mediaType.media_types.map((item, index) => {
              const selected =
                selectAllStatus === 1 ||
                (selectAllStatus === 0 && selectedItems.includes(item));
              return (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selected}
                        disabled={!canEdit}
                        name={item}
                        inputProps={{ "data-category": mediaType.key }}
                        onChange={handleCheckboxToggle}
                      />
                    }
                    label={item}
                  />
                </Grid>
              );
            })}
          </Grid>
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  );
}

export default function DownloadsPolicyForm({
  availableMediaTypes,
  actionButtonCallback,
  actionButtonText,
  callToActionText,
  callToAction,
  dialogTitle,
  record = null,
  canEdit = true,
}) {
  if (!record) {
    record = {
      name: "",
      media_types: {},
      max_size_allowed: 0,
      blocked_sources: [],
    };
  }
  const { t } = useTranslation();

  const [expanded, setExpanded] = React.useState(null);
  const [newBlockedDomain, setNewBlockedDomain] = React.useState("");
  const [blockedSources, setBlockedSources] = React.useState(
    record.blocked_sources,
  );
  const [maxSizeAllowed, setMaxSizeAllowed] = React.useState(
    record.max_size_allowed,
  );

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  function addBlockedSource() {
    setBlockedSources([...blockedSources, newBlockedDomain]);
    setNewBlockedDomain("");
  }

  function removeBlockedSource(domain) {
    setBlockedSources(blockedSources.filter((item) => item !== domain));
  }

  const savePolicy = (data) => {
    data["blocked_sources"] = blockedSources;
    if (record["id"]) {
      data["id"] = record["id"];
    } else {
      setBlockedSources([]);
      setMaxSizeAllowed(0);
    }

    actionButtonCallback(data);
  };

  const resetForm = () => {
    setBlockedSources(record.blocked_sources);
    setMaxSizeAllowed(record.max_size_allowed);
  };

  return (
    <DownloadsPolicyDialog
      callToActionText={callToActionText}
      callToAction={callToAction}
      actionButtonText={actionButtonText}
      actionButtonCallback={(formData) => savePolicy(formData)}
      dialogTitle={dialogTitle}
      cancelCallback={resetForm}
      maxWidth="md"
      canEdit={canEdit}
    >
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{ align: "left", width: "20%" }}>
              <Typography sx={{ fontWeight: "bold" }}>
                {t("Policy name")}
              </Typography>
            </TableCell>
            <TableCell style={{ align: "left" }}>
              <TextField
                style={{ width: "100%" }}
                size="small"
                name="name"
                defaultValue={record ? record.name : ""}
                disabled={!canEdit}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{ align: "left", width: "20%", verticalAlign: "top" }}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                {t("Media types")}
              </Typography>
              <Tooltip
                title={t("Block all downloads with the selected media types")}
              >
                <HelpOutlineIcon />
              </Tooltip>
            </TableCell>
            <TableCell style={{ align: "left" }}>
              <Box>
                {availableMediaTypes &&
                  availableMediaTypes.map((item) => {
                    let selectedExtensions = [];

                    if (record.media_types.length > 0) {
                      const mediaTypes = record.media_types.filter(
                        (x) => x.key === item.key,
                      );

                      if (mediaTypes.length > 0) {
                        selectedExtensions = mediaTypes[0].media_types;
                      }
                    }

                    return (
                      <MediaTypeAccordion
                        key={item.key}
                        expanded={expanded === item.key}
                        mediaType={item}
                        onChangeCallback={handleChange}
                        originalChoice={selectedExtensions}
                        canEdit={canEdit}
                      />
                    );
                  })}
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ align: "left", verticalAlign: "top" }}>
              <Typography sx={{ fontWeight: "bold" }}>
                {t("Max file size") + " (MB)"}
              </Typography>
              <Tooltip
                title={t(
                  "Block all downloads for files bigger than the defined limit",
                )}
              >
                <HelpOutlineIcon />
              </Tooltip>
            </TableCell>
            <TableCell style={{ align: "left" }}>
              <TextField
                value={maxSizeAllowed}
                onChange={(e) => setMaxSizeAllowed(e.target.value)}
                helperText={t("Use 0 to disable blocking by file size")}
                size="small"
                name="max_size_allowed"
                disabled={!canEdit}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ align: "left", verticalAlign: "top" }}>
              <Typography sx={{ fontWeight: "bold" }}>
                {t("Blocked Domains")}
              </Typography>
              <Tooltip
                title={t("Block all downloads from the defined domains")}
              >
                <HelpOutlineIcon />
              </Tooltip>
            </TableCell>
            <TableCell>
              <Grid container spacing={2}>
                {canEdit && (
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      label={t("Add Blocked Domain")}
                      value={newBlockedDomain}
                      onChange={(e) => setNewBlockedDomain(e.target.value)}
                      sx={{ marginRight: "10px" }}
                      size="small"
                    />
                    <Button
                      onClick={addBlockedSource}
                      variant="contained"
                      color="primary"
                    >
                      {t("Add")}
                    </Button>
                  </Grid>
                )}
                <Stack direction="row" spacing={1}>
                  {blockedSources.map((domain, index) => (
                    <Tooltip title={domain} key={index}>
                      <Chip
                        label={domain}
                        onDelete={
                          canEdit ? () => removeBlockedSource(domain) : null
                        }
                        variant="outlined"
                        style={{ width: "auto" }}
                      />
                    </Tooltip>
                  ))}
                </Stack>
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </DownloadsPolicyDialog>
  );
}
