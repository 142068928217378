import styled from "styled-components";

export const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface StyledBlurProps {
  blur?: number;
}
export const Blur = styled.div<StyledBlurProps>`
  filter: blur(${(props) => props?.blur ?? 5}px);
`;
