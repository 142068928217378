import React, { ReactNode } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { useTranslation } from "react-i18next";

const riskIcons = {
  high: <WarningAmberIcon sx={{ color: "#F6285F" }} />,
  medium: <ErrorOutlineOutlinedIcon sx={{ color: "#FBE909" }} />,
  low: <CheckCircleOutlineOutlinedIcon sx={{ color: "#65c58b" }} />,
};

/**
 * HACK: in order to have all possible translations, we need to help i18next-parser
 */
function PermissionExplanation({ value }: { value: string }): ReactNode {
  const { t } = useTranslation();

  // t("read copy-pasted data")
  // t("modify copy-pasted data")
  // t("read and change bookmarks");
  // t("detect physical location");
  // t("read and change browsing history on all signed-in devices");
  // t("manage apps, extensions, and themes");
  // t("read and change entries in the reading list");
  // t("read the browsing history on all signed-in devices");
  // t("read a list of the most frequently visited websites");
  // t("read all text spoken using synthesized speech");
  // t("read and change data on the active tab");
  // t("read copy-pasted data");
  // t("modify copy-pasted data");
  // t("change settings that control websites' access to features (cookies, JavaScript, plugins, geolocation, microphone, camera etc.)");
  // t("read and change all data on all websites");
  // t("block content on any page");
  // t("block content on any page");
  // t("read browsing history");
  // t("capture content of screen");
  // t("manage downloads");
  // t("read and change the browsing history on all signed-in devices");
  // t("read information about user identity");
  // t("communicate with cooperating native applications");
  // t("read and change all data on all websites");
  // t("change privacy-related settings");
  // t("read and change all data on all websites");
  // t("read and change the browsing history on all signed-in devices");
  // t("read and change all data on all websites");
  // t("read and change all data on all websites");
  // t("read browsing history");
  return <>{t(value)}</>;
}

export default function ExtensionRisksList({ risks }) {
  return (
    <List style={{ marginLeft: "2rem" }}>
      {risks.map((item, index) => (
        <ListItem key={index} sx={{ padding: "0.1rem" }}>
          <ListItemIcon sx={{ minWidth: "30px" }}>
            {riskIcons[item.level]}
          </ListItemIcon>
          <PermissionExplanation value={item.explanation} />
        </ListItem>
      ))}
    </List>
  );
}
