import React, { useCallback, useEffect, useState } from "react";
import { Paper, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { FullPageSpinner } from "@/components/Spinner";
import style from "./Threats.module.css";
import clsx from "clsx";
import { isElementVisible } from "@/utils/misc";
import { ThreatListFooter } from "./ThreatListFooter";
import { ThreatListHeader } from "./ThreatListHeader";
import { useTranslation } from "react-i18next";

function ThreatStatusIcon({ status }: { status: string }) {
  let statusIcon = null;
  if (status.toLowerCase() === "spam") {
    statusIcon = <i className="fas fa-ban" />;
  } else if (status.toLowerCase() === "safe") {
    statusIcon = <i className="fas fa-check text-success" />;
  } else if (status.toLowerCase() === "suspicious") {
    statusIcon = <i className="fas fa-question text-warning" />;
  } else if (status.toLowerCase() === "unsafe") {
    statusIcon = <i className="fas fa-exclamation-circle text-danger" />;
  }
  return <>{statusIcon}</>;
}

type ThreatSubjectRowProps = {
  row: Threat;
  onClick: React.MouseEventHandler;
  active: boolean;
  selected: boolean;
  id: string;
  isArchiveDisplayed: boolean;
};

function ThreatSubjectRow({
  row,
  onClick,
  selected,
  active,
  id,
  isArchiveDisplayed,
}: ThreatSubjectRowProps) {
  const formattedDate = dayjs(row.report_date).format("DD-MM-YYYY");
  const { t } = useTranslation();
  return (
    <Tooltip
      title={
        isArchiveDisplayed
          ? row.feedback_giver_email
            ? `Sorted by ${row.feedback_giver_email}`
            : "Unknown sorter"
          : ""
      }
      placement="right-end"
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [-7, -20],
              },
            },
          ],
        },
      }}
    >
      <div
        className={clsx(
          style.threatRow,
          selected && style.selectedThreatRow,
          !selected && style.notSelected,
          active && style.activeThreatRow,
        )}
        onClick={onClick}
        id={id}
      >
        <div style={{ flex: "0 0 75%", width: "75%" }}>
          <Typography fontSize={14} fontWeight={600} lineHeight={1.5} noWrap>
            {row.from_name} &lt;{row.from_email}&gt;
          </Typography>
          <Typography
            fontSize={12}
            fontWeight={550}
            lineHeight="20px"
            noWrap
            sx={{
              color: "#5012FF",
            }}
          >
            {row.subject}
          </Typography>
          <Typography fontSize={10} fontWeight={400} lineHeight="20px" noWrap>
            {t("Reported by {{reporter}}")}
            <span style={{ fontWeight: 550 }}>{row.reporter_email}</span>
          </Typography>
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", flex: "0 0 25%" }}
        >
          <Typography fontSize={12} fontWeight={400} sx={{ textAlign: "end" }}>
            {formattedDate}
          </Typography>
          <div style={{ textAlign: "end" }}>
            <i
              className="fa-solid fa-paperclip"
              style={{
                visibility: row.count_attachment > 0 ? "visible" : "hidden",
                filter: "opacity(0.35)",
              }}
            ></i>
          </div>
          <div style={{ textAlign: "end", marginTop: "auto" }}>
            <ThreatStatusIcon status={row.feedback_type} />
          </div>
        </div>
      </div>
    </Tooltip>
  );
}

export function ThreatList({
  isArchiveDisplayed,
  setArchiveMode,
  threats,
  isLoading,
  selectedIds,
  setSelectedIds,
  setActiveId,
  activeId,
  resetThreatDetails,
  offset,
  rowPerPages,
  totalThreatsCount,
  nextPaginate,
  prevPaginate,
}) {
  const [firstIndexRange, setFirstIndexRange] = useState<number | null>(null);

  const selectRange = useCallback(
    (newId: number) => {
      const threatsIds: number[] = threats.map((el: { id: number }) => el.id);
      const a: number = threatsIds.indexOf(firstIndexRange);
      const b: number = threatsIds.indexOf(newId);
      let first: number = a;
      let second: number = b;
      if (a > b) {
        first = b;
        second = a;
      }
      const range: number[] = [];
      for (let i: number = first; i <= second; i++) {
        range.push(threats[i].id);
      }
      setSelectedIds(range);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [threats, setSelectedIds, firstIndexRange],
  );

  const selectThreats = useCallback(
    (e: React.MouseEvent, newId: number) => {
      e.preventDefault();
      document.getSelection().removeAllRanges();

      if ((e.ctrlKey || e.metaKey) && !selectedIds.includes(newId)) {
        setSelectedIds([...selectedIds, newId]);
      } else if ((e.ctrlKey || e.metaKey) && selectedIds.includes(newId)) {
        const newSelectedIds = selectedIds.filter((el: number) => el !== newId);
        setSelectedIds(newSelectedIds);
        if (newSelectedIds.length === 0) {
          setActiveId(null);
          resetThreatDetails();
          return;
        }
        setActiveId(newSelectedIds[newSelectedIds.length - 1]);
        return;
      } else if (e.shiftKey && selectedIds.length === 0) {
        setFirstIndexRange(newId);
      } else if (e.shiftKey && selectedIds.length > 0) {
        selectRange(newId);
      } else {
        setFirstIndexRange(newId);
        setSelectedIds([newId]);
      }
      setActiveId(newId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedIds,
      setSelectedIds,
      setFirstIndexRange,
      firstIndexRange,
      selectRange,
    ],
  );

  useEffect(
    function handleKeyboardEvents() {
      const selectThreatsWithKeyboard = (e: {
        key: string;
        shiftKey: boolean;
      }) => {
        if (activeId === null) {
          return;
        }
        const threatsIds: number[] = threats.map((el: { id: number }) => el.id);
        const indexOfActive: number = threatsIds.indexOf(activeId);
        let nextId: number | null = null;
        if (e.key === "ArrowDown" && indexOfActive <= threatsIds.length - 1) {
          nextId = threatsIds[indexOfActive + 1];
        } else if (e.key === "ArrowUp" && indexOfActive > 0) {
          nextId = threatsIds[indexOfActive - 1];
        }
        if (nextId === null) {
          return;
        }
        if (e.shiftKey) {
          selectRange(nextId);
          setActiveId(nextId);
        } else {
          setFirstIndexRange(nextId);
          setSelectedIds([nextId]);
          setActiveId(nextId);
        }
        const domEl: HTMLElement = document.getElementById(`threat-${nextId}`);
        const domList: HTMLElement = document.getElementById(`threat-list`);
        if (!isElementVisible(domEl, domList)) {
          domEl.scrollIntoView(false);
        }
      };

      document.addEventListener("keydown", selectThreatsWithKeyboard);
      return () =>
        document.removeEventListener("keydown", selectThreatsWithKeyboard);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeId, threats, setSelectedIds, selectRange],
  );

  return (
    <Paper
      sx={{
        flex: "0 0 40%",
        width: "40%",
      }}
    >
      <ThreatListHeader
        isArchiveDisplayed={isArchiveDisplayed}
        setArchiveMode={setArchiveMode}
      />
      {isLoading ? (
        <FullPageSpinner />
      ) : (
        <div
          style={{
            overflowY: "scroll",
            height: "76vh",
          }}
          id="threat-list"
        >
          {threats.map((threat: Threat) => {
            return (
              <ThreatSubjectRow
                id={`threat-${threat.id}`}
                row={threat}
                key={threat.id}
                selected={selectedIds.includes(threat.id)}
                active={activeId === threat.id}
                onClick={(e) => selectThreats(e, threat.id)}
                isArchiveDisplayed={isArchiveDisplayed}
              />
            );
          })}
        </div>
      )}
      <ThreatListFooter
        offset={offset}
        rowPerPages={rowPerPages}
        threats={threats}
        totalThreatsCount={totalThreatsCount}
        prevPaginate={prevPaginate}
        nextPaginate={nextPaginate}
      />
    </Paper>
  );
}
