import {
  selectIsLoading,
  selectStepperFromChecklistState,
} from "@/pages/awareness/setup/SetupChecklistSlice";
import React, { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { AwarenessStepperStateKey } from "../SetupChecklistTypes";
import MessagingPlatformInstalled from "./stepComponents/MessagingPlatformInstalled";
import SelectedMessagingPlatform from "./stepComponents/SelectedMessagingPlatform";
import AssignCourse from "./stepComponents/AssignCourse";
import CourseDeliveryActivated from "./stepComponents/CourseDeliveryActivated";
import {
  SetupChecklist,
  SetupChecklistContext,
} from "@/components/stepper/SetupChecklist";
import { FullPageSpinner } from "@/components/Spinner";
import { useTranslation } from "react-i18next";
import { useSetupStatusQuery } from "../awarenessChecklistApi";

const useAwarenessChecklist = () => {
  const { isError } = useSetupStatusQuery();
  const steps = useSelector(selectStepperFromChecklistState);
  const isLoading = useSelector(selectIsLoading);

  return {
    steps,
    isLoading,
    isError,
  };
};

const AwarenessCurrentComponent: React.FC = () => {
  const checklist = useContext(SetupChecklistContext);

  switch (checklist.activeIndex) {
    case AwarenessStepperStateKey.MESSAGING_PLATFORM:
      return <SelectedMessagingPlatform />;
    case AwarenessStepperStateKey.MESSAGING_PLATFORM_INSTALLED:
      return <MessagingPlatformInstalled />;
    case AwarenessStepperStateKey.ONE_COURSE_ASSIGNED:
      return <AssignCourse />;
    case AwarenessStepperStateKey.COURSE_DELIVERY_ACTIVATED:
      return <CourseDeliveryActivated />;
  }
};

const AwarenessSetupChecklist: React.FC = () => {
  const { steps, isLoading, isError } = useAwarenessChecklist();
  const { t } = useTranslation();

  const disableChangeStepOnClick = useMemo(() => {
    if (steps.length === 0) {
      return true;
    }
    return steps[0].status !== "succeeded";
  }, [steps]);

  return (
    <>
      {isLoading && !isError ? (
        <FullPageSpinner />
      ) : (
        <SetupChecklist
          steps={steps}
          disableGoToLastInvalidStep={!disableChangeStepOnClick}
          disableChangeStepOnClick={disableChangeStepOnClick}
        >
          <AwarenessCurrentComponent />
        </SetupChecklist>
      )}
      {isError ? t("An error occurred") : null}
    </>
  );
};

export default AwarenessSetupChecklist;
