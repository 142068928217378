import React, { useState } from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import dayjs from "dayjs";
import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { T } from "@/components/LocalizedString";

function selectPeriodFromGraph(
  e: any,
  isMonthly: boolean,
  setSelectedPeriod: Function,
) {
  if (!e) {
    return;
  }
  const value = e?.activePayload[0]?.payload?.full_date;
  if (!value) {
    return;
  }
  setSelectedPeriod({
    unit: isMonthly ? "month" : "week",
    value: isMonthly
      ? dayjs(value, "DD/MM/YYYY").date(1)
      : dayjs(value, "DD/MM/YYYY"),
  });
}

function labelFromDataKey(datakey: string) {
  if (datakey === "rate_click") {
    return T("all simulations");
  }
  if (datakey === "rate_click_with_banners") {
    return T("with banners");
  }
  if (datakey === "rate_click_without_banners") {
    return T("without banners");
  }
}

function constructData(dataset: any, isMonthly: boolean, isCorporate: boolean) {
  if (isCorporate && dataset["average_data"] == null) {
    return;
  }
  const firstCompanyId = Object.keys(dataset["data"])[0];
  const firstCompanyDataset = dataset["data"][firstCompanyId];
  const key = isMonthly ? "monthly" : "weekly";
  const data = isCorporate
    ? dataset["average_data"][key]
    : firstCompanyDataset[key];
  const formattedData = data.map((el: any) => {
    const shallow_copy = Object.assign({}, el);
    shallow_copy.full_date = dayjs(shallow_copy.date, "YYYY-MM-DD").format(
      "DD/MM/YYYY",
    );
    shallow_copy.date = dayjs(shallow_copy.date, "YYYY-MM-DD").format(
      isMonthly ? "MMM YYYY" : "MMM DD",
    );
    shallow_copy.rate_click = Math.round(shallow_copy.rate_click * 100);
    shallow_copy.rate_click_with_banners = Math.round(
      shallow_copy.rate_click_with_banners * 100,
    );
    shallow_copy.rate_click_without_banners = Math.round(
      shallow_copy.rate_click_without_banners * 100,
    );
    return shallow_copy;
  });
  return formattedData;
}

const ActiveDot = (props: any) => {
  const { cx, cy } = props;
  return (
    <svg
      x={cx - 6.5}
      y={cy - 6}
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="6.28125"
        cy="6"
        r="5"
        fill="white"
        stroke="#4CA2ED"
        strokeWidth="2"
      />
      <ellipse cx="6.28125" cy="6" rx="2" ry="2" fill="#4CA2ED" />
    </svg>
  );
};

const CustomTooltip = (props: any) => {
  const { payload, showBanners, isCorporateView } = props;
  const { t } = useTranslation();
  if (payload?.length > 0) {
    return (
      <Box
        sx={{
          color: "white",
          background: "rgba(0,0,0,0.85)",
          padding: ".5rem .75rem",
          borderRadius: "8px",
        }}
      >
        <Typography sx={{ fontWeight: "bold", fontSize: ".9rem" }}>
          {payload[0].payload.full_date}
        </Typography>
        {showBanners && !isCorporateView ? (
          <div>
            {payload.map((el: any) => {
              if (el.dataKey === "rate_click" && !el.inactive) {
                return (
                  <div key={el.dataKey}>
                    <svg width="10" height="10">
                      <rect
                        width="10"
                        height="10"
                        stroke="white"
                        strokeWidth="1px"
                        fill={el.color}
                      />
                    </svg>
                    <span style={{ paddingLeft: "10px" }}>
                      {`${t("all")} : ${el.value}% (${el.payload.count_click}/${
                        el.payload.count_open
                      })`}
                    </span>
                  </div>
                );
              } else if (
                el.dataKey === "rate_click_with_banners" &&
                !el.inactive
              ) {
                return (
                  <div key={el.dataKey}>
                    <svg width="10" height="10">
                      <rect
                        width="10"
                        height="10"
                        stroke="white"
                        strokeWidth="1px"
                        fill={el.color}
                      />
                    </svg>
                    <span style={{ paddingLeft: "10px" }}>
                      {`${t("with banners")} : ${el.value}% (${
                        el.payload.count_click_with_banners
                      }/${el.payload.count_open_with_banners})`}
                    </span>
                  </div>
                );
              } else if (
                el.dataKey === "rate_click_without_banners" &&
                !el.inactive
              ) {
                return (
                  <div key={el.dataKey}>
                    <svg width="10" height="10">
                      <rect
                        width="10"
                        height="10"
                        stroke="white"
                        strokeWidth="1px"
                        fill={el.color}
                      />
                    </svg>
                    <span style={{ paddingLeft: "10px" }}>
                      {`${t("without banners")} : ${el.value}% (${
                        el.payload.count_click_without_banners
                      }/${el.payload.count_open_without_banners})`}
                    </span>
                  </div>
                );
              }
              return <></>;
            })}
          </div>
        ) : (
          <Typography sx={{ fontSize: ".85rem" }}>
            {`${isCorporateView ? t("Average") + " : " : ""} ${
              payload[0].payload.rate_click
            }% (${payload[0].payload.count_click}/${
              payload[0].payload.count_open
            })`}
          </Typography>
        )}
      </Box>
    );
  }

  return <></>;
};

const YTick = (props: any) => {
  const { y, payload } = props;
  return (
    <g transform={`translate(${0},${y})`}>
      <text x={0} y={0} textAnchor="start" fill="#666">
        {payload.value}%
      </text>
    </g>
  );
};

const CustomLegend = (props: any) => {
  const { payload, clickEvent } = props;
  return (
    <Stack direction="row" justifyContent="center">
      {payload.map((el: any) => {
        return (
          <Box
            key={el.dataKey}
            sx={{ marginRight: "35px", cursor: "pointer", color: "#666" }}
            onClick={() => clickEvent(el.dataKey)}
          >
            <svg width="35px" height="2px">
              <line
                x1={0}
                y1={0}
                x2={25}
                y2={0}
                stroke={el.color}
                strokeWidth="3"
                strokeDasharray={el.payload.strokeDasharray}
              ></line>
            </svg>
            <Typography
              variant="body_small"
              style={{ textDecoration: el.inactive ? "line-through" : "none" }}
            >
              {labelFromDataKey(el.dataKey)}
            </Typography>
          </Box>
        );
      })}
    </Stack>
  );
};

const LineChart = ({
  dataset,
  isMonthly,
  showBanners,
  setSelectedPeriod,
  isCorporateView,
}) => {
  const [showAllSimulations, setShowAllSimulations] = useState(true);
  const [showWithBanners, setShowWithBanners] = useState(true);
  const [showWithoutBanners, setShowWithoutBanners] = useState(true);
  const keyX = "date";
  const keyY = "rate_click";
  const total_key_with_banners = `rate_click_with_banners`;
  const total_key_without_banners = `rate_click_without_banners`;

  function clickOnLegend(dataKey: string) {
    if (dataKey === keyY) {
      setShowAllSimulations((v) => !v);
    } else if (dataKey === total_key_with_banners) {
      setShowWithBanners((v) => !v);
    } else if (dataKey === total_key_without_banners) {
      setShowWithoutBanners((v) => !v);
    }
  }

  let data = [];
  if (!dataset) {
    return <div>pending...</div>;
  }
  data = constructData(dataset, isMonthly, isCorporateView);

  if (!data || data?.length <= 0) {
    return <div>pending...</div>;
  }

  return (
    <ResponsiveContainer width="100%" height={280}>
      <ComposedChart
        data={data}
        margin={{ left: 0, top: 10, right: 10, bottom: 0 }}
        onClick={(e) => selectPeriodFromGraph(e, isMonthly, setSelectedPeriod)}
      >
        <defs>
          <linearGradient id="blueGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="20%" stopColor="#4CA2ED" stopOpacity={1} />
            <stop offset="97%" stopColor="#4CA2ED" stopOpacity={0.05} />
            <stop offset="100%" stopColor="#4CA2ED" stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray={5} horizontal={false} />
        <Tooltip
          cursor={{
            color: "#4CA2ED",
            stroke: "#4CA2ED",
            strokeDasharray: 5,
            strokeWidth: 1,
          }}
          content={
            <CustomTooltip
              isMonthly={isMonthly}
              showBanners={showBanners}
              isCorporateView={isCorporateView}
            />
          }
        />
        <Area
          type="monotone"
          dataKey={keyY}
          fill="url(#blueGradient)"
          hide={!showAllSimulations}
          activeDot={<ActiveDot />}
        />
        {showBanners && !isCorporateView && (
          <>
            <Line
              type="monotone"
              dot={false}
              stroke="#1dc9b7"
              strokeDasharray="8 8"
              strokeWidth="1.5"
              dataKey={total_key_with_banners}
              hide={!showWithBanners}
              activeDot={<ActiveDot />}
            />
            <Line
              type="monotone"
              dot={false}
              stroke="#1662ff"
              strokeDasharray="10 5"
              strokeWidth="1.5"
              dataKey={total_key_without_banners}
              hide={!showWithoutBanners}
              activeDot={<ActiveDot />}
            />
          </>
        )}
        <Legend
          iconType="plainline"
          wrapperStyle={{
            paddingTop: showBanners && !isCorporateView ? "25px" : "10px",
          }}
          content={
            showBanners && !isCorporateView ? (
              <CustomLegend isMonthly={isMonthly} clickEvent={clickOnLegend} />
            ) : (
              <></>
            )
          }
        />
        <YAxis width={50} axisLine={false} tickLine={false} tick={<YTick />} />
        <XAxis dataKey={keyX} axisLine={false} tickLine={false} dy={15} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default LineChart;
