import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import React, { useState } from "react";
import { FeedbackType, useFeedbackNames } from "@/pages/threats/service/domain";
import { useTranslation } from "react-i18next";

type ThreatFeedbackDialogProps = {
  onConfirm: (feedbackType: FeedbackType, comment?: string) => void;
  onCancel: () => void;
  threatCount: number;
  action: FeedbackType | null;
};

const ThreatFeedbackDialog = ({
  onConfirm,
  onCancel,
  threatCount,
  action,
}: ThreatFeedbackDialogProps) => {
  const [comment, setComment] = useState("");
  const { t } = useTranslation();
  const feedbackNames = useFeedbackNames();

  const placeholder = t("Optional comment");

  const dialogTitle = t('Classify {{count}} emails as "{{action}}"', {
    count: threatCount,
    action: feedbackNames(action),
  });
  const inputTooLong = comment.length > 255;
  const handleConfirm = () => {
    onConfirm(action, comment);
  };

  return (
    <Dialog open={action !== null} maxWidth={"xs"} fullWidth>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <Divider />
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextField
            rows={5}
            variant={"outlined"}
            multiline
            sx={{ width: "100%", backgroundColor: "#FBFBFC" }}
            placeholder={placeholder}
            error={inputTooLong}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setComment(event.target.value);
            }}
          />

          <Typography
            sx={{
              color: "#9d9d9f",
              fontStyle: "italic",
              fontSize: "12px",
              paddingTop: "5px",
            }}
          >
            {inputTooLong
              ? t("You have reached the character limit (255).")
              : t(
                  "This comment will be included in your feedback email. Leave it blank if you don't want to provide a comment.",
                )}
          </Typography>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant={"outlined"} onClick={onCancel}>
          {t("Cancel")}
        </Button>
        <Button
          variant={"contained"}
          disabled={inputTooLong}
          onClick={handleConfirm}
        >
          {t("Send")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ThreatFeedbackDialog;
