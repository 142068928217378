import React from "react";
import { Button, ButtonGroup, Stack } from "@mui/material";
import { FeedbackType, useFeedbackNames } from "@/pages/threats/service/domain";
import Box from "@mui/material/Box";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useTranslation } from "react-i18next";
import {
  ThreatSafeIcon,
  ThreatSpamIcon,
  ThreatSuspiciousIcon,
  ThreatUnsafeIcon,
} from "@/pages/threats/components/FeedbackIcons";

const UnarchiveButton = ({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <ButtonGroup color="neutral" disabled={disabled}>
      <Button
        onClick={onClick}
        startIcon={<UnarchiveIcon />}
        disabled={disabled}
        title={t("Unarchive threat")}
      >
        {t("Unarchive")}
      </Button>
    </ButtonGroup>
  );
};

/**
 * Threat DeleteButton
 *
 * XXX: this is not the same icon as other Trash buttons
 */
const DeleteButton = ({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <ButtonGroup color="neutral" disabled={disabled}>
      <Button onClick={onClick} title={t("delete one or more threat")}>
        <DeleteOutlineIcon />
      </Button>
    </ButtonGroup>
  );
};

export function FeedbackButtons({
  onClick,
  disabled = false,
}: {
  onClick: (feedbackType: FeedbackType) => void;
  disabled: boolean;
}) {
  const { t } = useTranslation();
  const feedbackNames = useFeedbackNames();

  return (
    <ButtonGroup color="neutral" disabled={disabled}>
      <Button
        onClick={() => onClick("spam")}
        title={t("Archive this threat(s) if containing possibly spam element")}
        startIcon={<ThreatSpamIcon disabled={disabled} />}
      >
        {feedbackNames("spam")}
      </Button>
      <Button
        onClick={() => onClick("safe")}
        title={t("Archive this threat(s) if not representing any danger")}
        startIcon={<ThreatSafeIcon disabled={disabled} />}
      >
        {feedbackNames("safe")}
      </Button>
      <Button
        onClick={() => onClick("suspicious")}
        title={t(
          "Archive this threat(s) if containing possibly suspicious elements",
        )}
        startIcon={<ThreatSuspiciousIcon disabled={disabled} />}
      >
        {feedbackNames("suspicious")}
      </Button>
      <Button
        onClick={() => onClick("unsafe")}
        title={t(
          "Archive this threat(s) if containing threatening elements without any doubt",
        )}
        startIcon={<ThreatUnsafeIcon disabled={disabled} />}
      >
        {feedbackNames("unsafe")}
      </Button>
    </ButtonGroup>
  );
}

type ThreatCategorisationButtonsProps = {
  disabled?: boolean;
  selectedCount?: number;
  archivePane: boolean;

  unarchive: () => void;
  sendFeedback: (feedbackType: FeedbackType) => void;
  deleteThreats: () => void;
};

export const ThreatCategorisationButtons = ({
  disabled = false,
  selectedCount = 0,
  archivePane,
  unarchive,
  sendFeedback,
  deleteThreats,
}: ThreatCategorisationButtonsProps) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={1}
      marginBottom=".5em"
    >
      {selectedCount > 0 && (
        <Box sx={{ marginRight: "10px" }}>
          <strong>{selectedCount}</strong> {t("selected")}
        </Box>
      )}
      {archivePane ? (
        <UnarchiveButton onClick={unarchive} disabled={disabled} />
      ) : (
        <FeedbackButtons onClick={sendFeedback} disabled={disabled} />
      )}
      <DeleteButton onClick={deleteThreats} disabled={disabled} />
    </Stack>
  );
};

export default FeedbackButtons;
