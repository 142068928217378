import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LearningUserType } from "@/types/awareness";
import {
  CoursesType,
  SubscriptionsPayload,
  SubscriptionsType,
} from "@/pages/awareness/courses/utils/courseTypes";
import { AllowedSharingPolicy } from "@/pages/awareness/courses/utils/sharingPolicies";
import { RootState } from "@/store";
import { FilterAssignment } from "@/pages/awareness/courses/utils/filterAssignmentEnum";

export const defaultLearningUserType: LearningUserType = {
  id: -1,
  email: "",
  company_id: -1,
  finished_courses: [],
  viewed_courses: [],
  current_course: -1,
  messaging_platform: "",
  language: "en",
  has_chat_account: false,
  course_subscriptions: [],
  bot_installed: false,
};

export type CourseCatalogSliceType = {
  departments: any[];
  courses: CoursesType[];
  onboardingCourses: CoursesType[];
  runCourses: CoursesType[];
  userInfos: LearningUserType;
  search: string;
  filterDepartments?: FilterAssignment;
  filterSharingPolicy: string;
  isAdmin: boolean;
  isPublicWebchat: boolean;
  isDemoAcme: boolean;
};

export const initialState: CourseCatalogSliceType = {
  departments: [],
  courses: [],
  onboardingCourses: [],
  runCourses: [],
  userInfos: defaultLearningUserType,
  search: "",
  filterDepartments: FilterAssignment.NoFilter,
  filterSharingPolicy: "no-policy",
  isAdmin: false,
  isPublicWebchat: false,
  isDemoAcme: false,
};

/**
 * Returns a company SubscriptionType for a complete Company
 * @param course
 */
const companySubscription = (course: CoursesType): SubscriptionsType[] => {
  return [
    {
      course: course.id,
      department: null,
      id: 0,
      label: "Everyone",
    },
  ];
};

export const createCourseSlice = (name: string) => {
  return createSlice({
    name,
    initialState,
    reducers: {
      updateSubscription: (
        state,
        action: PayloadAction<SubscriptionsPayload>,
      ) => {
        const tmp = state.courses.find(
          (c) => c.id === action.payload.course_id,
        );
        if (tmp) {
          if (action.payload.type === "not_subscribed") {
            tmp.subscriptions = [];
            tmp.subscription_type = "not_subscribed";
          } else if (action.payload.type === "company") {
            tmp.subscription_type = "company";
            tmp.subscriptions = companySubscription(tmp);
          } else if (action.payload.type === "departments") {
            tmp.subscription_type = "departments";
            const new_subscriptions = action.payload.departments_id.map(
              (department) => ({
                course: tmp.id,
                department: department,
                id: department,
                label:
                  state.departments.find((d) => d.id === department)?.name ||
                  "",
              }),
            );
            tmp.subscriptions = new_subscriptions;
          }
        }
      },
      subscribeAll: (state, action: PayloadAction<number[]>) => {
        state.courses
          .filter((course) => action.payload.includes(course.id))
          .forEach((course) => {
            course.subscription_type = "company";
            course.subscriptions = companySubscription(course);
          });
      },
      setSearch: (state, action: PayloadAction<string>) => {
        state.search = action.payload;
      },
      setDepartments: (state, action: PayloadAction<any[]>) => {
        state.departments = action.payload;
      },
      setOnboardingCourses: (state, action: PayloadAction<CoursesType[]>) => {
        state.onboardingCourses = action.payload;
      },
      setRunCourses: (state, action: PayloadAction<CoursesType[]>) => {
        state.runCourses = action.payload;
      },
      setCourses: (state, action: PayloadAction<CoursesType[]>) => {
        state.courses = action.payload;
      },
      setUserInfos: (state, action: PayloadAction<LearningUserType>) => {
        state.userInfos = action.payload;
        if (state.userInfos.email === "demo@acme.com") {
          state.isDemoAcme = true;
        }
      },
      setFilterDepartments: (state, action: PayloadAction<number>) => {
        state.filterDepartments = action.payload;
      },
      setIsAdmin: (state, action: PayloadAction<boolean>) => {
        state.isAdmin = action.payload;
      },
      setIsPublicWebchat: (state, action: PayloadAction<boolean>) => {
        state.isPublicWebchat = action.payload;
      },
      setFilterSharingPolicy: (state, action: PayloadAction<string>) => {
        state.filterSharingPolicy = action.payload;
      },
    },
  });
};

// Helper functions
const keepOnlyCoursesUserCanFollow = (
  courses: CoursesType[],
  userInfos: LearningUserType,
) => {
  return courses.filter(
    (course) =>
      userInfos.course_subscriptions.includes(course.id) &&
      course.available_languages.includes(userInfos.language),
  );
};

function applyUserFilters(
  state: RootState,
  courses: CoursesType[],
  sliceName: "courseCatalog" | "courseSharing",
): CoursesType[] {
  const { filterSharingPolicy, filterDepartments, departments, search } =
    state[sliceName];

  if (filterSharingPolicy !== "no-policy") {
    courses = courses.filter(
      (course) => course.sharing_policy === filterSharingPolicy,
    );
  }

  if (filterDepartments !== FilterAssignment.NoFilter) {
    courses = courses.filter((course) => {
      const subscriptionCount = course.subscriptions.length;
      switch (filterDepartments) {
        case FilterAssignment.NotAssigned:
          return subscriptionCount === 0;
        case FilterAssignment.Assigned:
          return subscriptionCount > 0;
        case FilterAssignment.AllDepartments:
          return (
            subscriptionCount === departments.length &&
            !(
              subscriptionCount === 1 &&
              course.subscriptions[0].department === null
            )
          );
        case FilterAssignment.Everyone:
          return (
            subscriptionCount === 1 &&
            course.subscriptions[0].department === null
          );
        default: // Assigned to a specific department
          return course.subscriptions.some(
            (s) => s.department === filterDepartments,
          );
      }
    });
  }

  if (search?.length > 0) {
    courses = courses.filter((course) => {
      // TODO use fuse.js
      return course.label_name.toLowerCase().includes(search.toLowerCase());
    });
  }
  return courses;
}

// Common selectors
export const createSelectors = (
  selectState: (state: any) => CourseCatalogSliceType,
  sliceName: "courseCatalog" | "courseSharing",
) => ({
  selectDepartments: (state: any) => selectState(state).departments,
  selectCourses: (state: any) => selectState(state).courses,
  selectUserInfos: (state: any) => selectState(state).userInfos,
  selectIsDemoAcme: (state: any) => selectState(state).isDemoAcme,
  selectFilterDepartments: (state: any) => selectState(state).filterDepartments,
  selectFilterSharingPolicy: (state: any) =>
    selectState(state).filterSharingPolicy,
  selectOnboardingCourses: (state: any) => selectState(state).onboardingCourses,
  selectRunCourses: (state: any) => selectState(state).runCourses,
  selectSearchValue: (state: any) => selectState(state).search,
  selectHasFilter: (state: any) => {
    const sliceState = selectState(state);
    return (
      sliceState.filterDepartments !== -1 ||
      sliceState.filterSharingPolicy !== "no-policy" ||
      sliceState.search.length > 0
    );
  },
  selectCourseBySearch: (state: RootState) => {
    const sliceState = selectState(state);
    let courses = sliceState.courses;
    if (sliceState.isPublicWebchat) {
      courses = courses.filter((course) => course.public);
      return courses;
    }
    if (!sliceState.isAdmin) {
      courses = keepOnlyCoursesUserCanFollow(courses, sliceState.userInfos);
      return courses;
    }
    return applyUserFilters(state, courses, sliceName);
  },
  selectCustomCourseBySearch: (state: RootState) => {
    const sliceState = selectState(state);
    let courses = sliceState.courses;
    courses = courses.filter(
      (course) =>
        course.sharing_policy === AllowedSharingPolicy.COMPANY_ONLY ||
        course.sharing_policy === AllowedSharingPolicy.COMPANIES_OF_CORPORATE,
    );
    return applyUserFilters(state, courses, sliceName);
  },
  selectOnboardingCourseBySearch: (state: RootState) => {
    const sliceState = selectState(state);
    let courses = sliceState.onboardingCourses;

    if (!sliceState.isAdmin) {
      courses = keepOnlyCoursesUserCanFollow(courses, sliceState.userInfos);
      return courses;
    }

    return applyUserFilters(state, courses, sliceName);
  },
  selectRunCoursesBySearch: (state: RootState) => {
    const sliceState = selectState(state);
    let courses = sliceState.runCourses;

    if (!sliceState.isAdmin) {
      courses = keepOnlyCoursesUserCanFollow(courses, sliceState.userInfos);
      return courses;
    }

    return applyUserFilters(state, courses, sliceName);
  },
});
