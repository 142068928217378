import { Box, Button, Card, Checkbox, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { TemplateDrawer } from "./TemplateDrawer";
import { TemplateContent } from "./TemplateContent";
import TemplateTypeIcon from "./TemplateTypeIcon";
import {
  processLabel,
  processSaasLabel,
  TemplateType,
  TemplateTypeEnum,
} from "./templateUtils";
import { useTemplateCatalogContext } from "@/pages/templates/TemplateCatalogContextProvider";
import { useTranslation } from "react-i18next";

export const TemplateCard = ({ template }: { template: TemplateType }) => {
  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(false);
  const templateCatalogContext = useTemplateCatalogContext();
  const { t } = useTranslation();
  const openDrawer = () => {
    setShouldFetch(true);
    setOpen(true);
  };

  const closeDrawer = () => {
    setShouldFetch(false);
    setOpen(false);
  };

  return (
    <>
      <Card
        sx={{
          minWidth: "300px",
          height: "300px",
          zIndex: "50",
          borderRadius: "10px",
          position: "relative",
          "&:hover, &.Mui-Active": {
            borderColor: "#4B2BD7",
            borderWidth: "2px",
          },
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Box
          className={hover ? "hover" : ""}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(157, 164, 255, 0)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "100",
            borderRadius: "8px",
            cursor: "pointer",
            transition: "all 0.25s ease",
            "&:hover": {
              backgroundColor: "rgba(157, 164, 255, 0.32)",
              backdropFilter: "blur(4px)",
            },
          }}
          onClick={openDrawer}
        >
          {hover ? <Button variant="contained">{t("View")}</Button> : ""}

          {templateCatalogContext.isInSelectionMode && (
            <Checkbox
              checked={templateCatalogContext.selectedTemplates.includes(
                template,
              )}
              onClick={(event) => {
                templateCatalogContext.selectTemplate(template);
                event.stopPropagation();
              }}
              sx={{ position: "absolute", right: 0, top: 0 }}
            />
          )}
        </Box>
        <Stack sx={{ height: "100%" }}>
          <Box sx={{ height: "100%", overflow: "hidden", borderRadius: "8px" }}>
            <TemplateContent content={template.content} scrolling={false} />
          </Box>
          <Box
            sx={{
              height: "25%",
              padding: "1rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                variant="h6"
                sx={{ textTransform: "capitalize", fontWeight: "bold" }}
              >
                {processLabel(template.name)}
              </Typography>
              <Typography>{processSaasLabel(template.saas)}</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TemplateTypeIcon type={template.category as TemplateTypeEnum} />
            </Box>
          </Box>
        </Stack>
      </Card>
      <TemplateDrawer
        name={template.name}
        isOpen={open}
        availableLanguages={template.available_languages}
        shouldFetch={shouldFetch}
        closeDrawer={closeDrawer}
        enableActivationToggle={!templateCatalogContext.isInSelectionMode}
        enableSendNow={!templateCatalogContext.isInSelectionMode}
      />
    </>
  );
};
