import React from "react";
import { useDownloadUsers } from "@/pages/general-settings/UsersManagement/utils";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

export function ExportCSVLink() {
  const { startDownload } = useDownloadUsers();
  const { t } = useTranslation();
  return (
    <Button onClick={() => startDownload()}>
      {t("download your current users in CSV here")}
    </Button>
  );
}
