import { Box, Button, Typography } from "@mui/material";
import React from "react";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useSelector } from "react-redux";
import { selectAwarenessSetupChecklistState } from "../../SetupChecklistSlice";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CourseDeliveryActivated: React.FC = () => {
  const { courseDeliveryActivated } = useSelector(
    selectAwarenessSetupChecklistState,
  );
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" gap="1.5rem">
      <Typography variant="h5">
        {t("Activate automatic course delivery")}
      </Typography>
      <Box mb="1rem">
        {t(
          "Define the spacing between two courses and activate the automatic course delivery.",
        )}
      </Box>
      <Box display="flex" alignItems="center" gap="1.5rem">
        <Link to="/awareness/setup#preferences">
          <Button variant="contained">{t("Activate auto delivery")}</Button>
        </Link>
        <Box display="flex" alignItems="center" gap="0.5rem">
          {courseDeliveryActivated ? (
            <>
              <CheckCircleOutlinedIcon color="success" />
              <Typography variant="body_extrasmall" color="#2e7d32">
                {t("Completed")}
              </Typography>
            </>
          ) : (
            <>
              <CancelOutlinedIcon color="error" />
              <Typography variant="body_extrasmall" color="error">
                {t("Not Completed")}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CourseDeliveryActivated;
