import { SvgIcon } from "@mui/material";
import React from "react";

export const PasswordExposedIcon = ({
  fontSize = 12,
  color = "#98A5B3",
}: {
  fontSize?: string | number;
  color?: string;
}) => {
  return (
    <SvgIcon sx={{ fontSize: fontSize }} htmlColor={color}>
      <svg
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.6343 16.0417V11.6667C10.6343 7.63959 13.8325 4.375 17.7776 4.375C21.2334 4.375 24.1161 6.88007 24.7779 10.2083M13.2059 30.625H22.3492C24.7496 30.625 25.9498 30.625 26.8666 30.1482C27.673 29.7287 28.3287 29.0594 28.7396 28.2362C29.2067 27.3003 29.2067 26.0752 29.2067 23.625V23.0417C29.2067 20.5914 29.2067 19.3663 28.7396 18.4305C28.3287 17.6072 27.673 16.938 26.8666 16.5185C25.9498 16.0417 24.7496 16.0417 22.3492 16.0417H13.2059C10.8055 16.0417 9.60537 16.0417 8.68855 16.5185C7.8821 16.938 7.22644 17.6072 6.81553 18.4305C6.34839 19.3663 6.34839 20.5914 6.34839 23.0417V23.625C6.34839 26.0752 6.34839 27.3003 6.81553 28.2362C7.22644 29.0594 7.8821 29.7287 8.68855 30.1482C9.60537 30.625 10.8055 30.625 13.2059 30.625Z"
          stroke="currentColor"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
