import React, { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { sentryCaptureException } from "@/utils/sentry";
import { useUserContext } from "@/utils/contexts/UserContext";
import axios from "axios";
import StickyHeader from "@/components/StickyHeader";
import GenericCard from "@/pages/browser-defender/components/GenericCard";
import DataTable from "@/pages/browser-defender/demo/passwordStrength/DataTable";
import { FullPageSpinner } from "@/components/Spinner";
import { useTranslation } from "react-i18next";

export function WeakPasswords() {
  const [passwordInfo, setPasswordInfo] = useState({
    values: [],
    filteredValues: [],
    loading: true,
  });
  const { getAccessTokenSilently } = useAuth0();
  const info_user = useUserContext();
  const { t } = useTranslation();

  const fetchPasswordInfo = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const url = new URL(
        `${import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST}/demo/weak_passwords`,
      );

      const resource = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setPasswordInfo({
        values: resource.data,
        filteredValues: resource.data,
        loading: false,
      });
    } catch (e) {
      sentryCaptureException(e);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (!info_user.email) {
      return;
    }

    fetchPasswordInfo();
  }, [fetchPasswordInfo, info_user]);

  if (passwordInfo.loading) {
    return <FullPageSpinner />;
  }

  return (
    <>
      <Box>
        <StickyHeader>
          <Stack
            direction="row"
            gap={3}
            justifyContent="end"
            alignItems="center"
          >
            <Typography
              variant="h2"
              style={{
                marginRight: "auto",
                maxHeight: "2rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {t("Password strength")}
            </Typography>
          </Stack>
        </StickyHeader>
        <Grid container alignItems="stretch">
          <Grid item xs={12}>
            <GenericCard title={"Details"} isLoading={passwordInfo.loading}>
              <DataTable data={passwordInfo} setData={setPasswordInfo} />
            </GenericCard>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
