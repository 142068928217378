import React from "react";
import Grid from "@mui/material/Grid";
import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import {
  Bar,
  BarChart,
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useTranslation } from "react-i18next";

export function DangerousDownloadsDemoDetailsGraphs() {
  const { t } = useTranslation();

  return (
    <Grid container>
      <GraphCard title={t("Downloads risk repartition")}>
        <Box display="flex" gap={1} sx={{ flexDirection: "column" }}>
          <DemoDownloadRepartitionDonutGraph
            data={demoDownloadRiskRepartitionFakeData}
          />
          <DemoDownloadRepartitionRecap
            data={demoDownloadRiskRepartitionFakeData}
          />
        </Box>
      </GraphCard>
      <GraphCard title={t("Top download domains")}>
        <DemoDownloadTop5BarChart
          color="#1662FF"
          data={topDownloadDomainsFakeData}
        />
      </GraphCard>
      <GraphCard title={t("Top users who downloaded dangerous files")}>
        <DemoDownloadTop5BarChart color="#35D9C0" data={topUsersFakeData} />
      </GraphCard>
      <GraphCard title={t("Top file types")}>
        <DemoDownloadTop5BarChart color="#9E98DA" data={topFileTypesFakeData} />
      </GraphCard>
    </Grid>
  );
}

interface GraphCardProps {
  title: string;
  children: string | React.JSX.Element | Array<React.JSX.Element>;
}

function GraphCard(props: GraphCardProps) {
  return (
    <Grid item xs={6}>
      <Card>
        <CardHeader title={props.title} />
        <CardContent>{props.children}</CardContent>
      </Card>
    </Grid>
  );
}

interface DemoDonutGraphDataPiece {
  name: string;
  value: number;
  color: string;
}

const demoDownloadRiskRepartitionFakeData: Array<DemoDonutGraphDataPiece> = [
  { name: "Low", value: 192, color: "#a7bef1" },
  { name: "Moderate", value: 105, color: "#4ca2ed" },
  { name: "High", value: 68, color: "#1662ff" },
  { name: "Very High", value: 25, color: "#19439e" },
];

function DemoDownloadRepartitionDonutGraph({
  data,
}: {
  data: Array<DemoDonutGraphDataPiece>;
}) {
  return (
    <ResponsiveContainer width="100%" height={200}>
      <PieChart>
        <Pie
          data={data}
          nameKey="name"
          dataKey="value"
          label={renderCustomizedLabel}
          labelLine={false}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={100}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

function DemoDownloadRepartitionRecap({
  data,
}: {
  data: Array<DemoDonutGraphDataPiece>;
}) {
  return (
    <Box display="flex" sx={{ justifyContent: "space-around" }}>
      {data.map((dataPiece, index) => (
        <div key={index}>
          <Typography>{dataPiece.name}</Typography>
          <Box display="flex" gap={1} sx={{ alignItems: "center" }}>
            <Box
              sx={{
                aspectRatio: "1/1",
                height: "11px",
                borderRadius: "50%",
                border: "1px solid white",
                boxShadow: "0px 0px 1px 0px rgba(0,0,0,0.75)",
                backgroundColor: dataPiece.color,
              }}
            />
            <Typography variant="h5">{dataPiece.value}</Typography>
          </Box>
        </div>
      ))}
    </Box>
  );
}

interface DemoBarGraphDataPiece {
  name: string;
  value: number;
}

const topDownloadDomainsFakeData: Array<DemoBarGraphDataPiece> = [
  { name: "evil-domain.io", value: 23 },
  { name: "phishy-domain.io", value: 20 },
  { name: "sncf-connect.com", value: 15 },
  { name: "domain-update.xyz", value: 14 },
  { name: "not-risky-domain.io", value: 6 },
];

const topUsersFakeData: Array<DemoBarGraphDataPiece> = [
  { name: "Peter Johnson", value: 5 },
  { name: "Callan Myers", value: 3 },
  { name: "Esmerelda Nelson", value: 3 },
  { name: "Lennie Jones", value: 2 },
  { name: "Lyndsey Campbell", value: 2 },
];

const topFileTypesFakeData: Array<DemoBarGraphDataPiece> = [
  { name: ".docx", value: 31 },
  { name: ".pdf", value: 26 },
  { name: ".xslsm", value: 23 },
  { name: ".zip", value: 19 },
  { name: ".exe", value: 6 },
];

function DemoDownloadTop5BarChart({
  data,
  color,
}: {
  data: Array<DemoBarGraphDataPiece>;
  color: string;
}) {
  return (
    <ResponsiveContainer width="100%" height={250}>
      <BarChart data={data} layout="vertical" barCategoryGap={10}>
        <XAxis hide axisLine={false} type="number" />
        <YAxis
          dataKey="name"
          type="category"
          width={150}
          axisLine={false}
          tickLine={false}
        />
        <Tooltip />
        <Bar
          name="amount"
          dataKey="value"
          fill={color}
          label={{ position: "right" }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
