import { useAuth0 } from "@auth0/auth0-react";
import {
  Autocomplete,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import React from "react";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useTranslation } from "react-i18next";

const fontColor = "#7F8995";
const themes = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          marginBottom: 0,
          fontSize: "1.5rem",
        },
      },
    },
  },
});
export default function CustomCampaignDetailsHeader({
  customCampaignDetails,
  departments,
  setSelectedDepartment,
}) {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();

  const downloadTemplate = async () => {
    const accessToken = await getAccessTokenSilently();
    const url =
      import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
      "/custom_campaign/details_custom_campaign/" +
      customCampaignDetails.id +
      "/download";

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: "blob",
      })
      .then((response) => {
        let url = window.URL.createObjectURL(new Blob([response.data]));
        let a = document.createElement("a");
        a.href = url;
        a.download = "template.html";
        a.click();
      });
  };
  const onSelectedDepartmentChange = (event: any, newValue) => {
    if (newValue) {
      setSelectedDepartment(newValue.id);
    } else {
      setSelectedDepartment("");
    }
  };
  return (
    <ThemeProvider theme={themes}>
      <Grid
        container
        alignItems={"center"}
        paddingLeft={"16px"}
        height={"80px"}
      >
        <Grid item xs={3}>
          <Tooltip
            title={
              <Typography fontSize={"1rem"}>
                {customCampaignDetails.campaign_name}
              </Typography>
            }
            arrow
          >
            <Typography
              fontWeight={"bold"}
              fontSize={"1.5rem"}
              margin={0}
              noWrap
              maxWidth={"100%"}
            >
              {customCampaignDetails.campaign_name}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            disablePortal
            onChange={onSelectedDepartmentChange}
            sx={{ maxWidth: "300px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ marginBottom: 0 }}
                label={
                  <Typography fontSize={"1rem"}>
                    <PersonOutlineIcon />
                    {t("Targeted departments")}
                  </Typography>
                }
              />
            )}
            loadingText={t("Loading") + "..."}
            options={departments}
          />
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography fontSize={"12px"} color={fontColor}>
            {t("Date")}
          </Typography>
          <Typography>{customCampaignDetails.sent_date}</Typography>
        </Grid>
        <Grid
          item
          xs={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography fontSize={"12px"} color={fontColor}>
            {t("Sent")}
          </Typography>
          <Typography>
            {customCampaignDetails.simulations_sent_count}
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography fontSize={"12px"} color={fontColor}>
            {t("Open Rate")}
          </Typography>
          <Typography>
            {customCampaignDetails.simulations_opened_rate}
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography fontSize={"12px"} color={fontColor}>
            {t("Click Rate")}
          </Typography>
          <Typography>
            {customCampaignDetails.simulations_clicked_rate}
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography fontSize={"12px"} color={fontColor}>
            {t("Template")}
          </Typography>
          <IconButton sx={{ maxHeight: "29.25px" }} onClick={downloadTemplate}>
            <FileDownloadIcon />
          </IconButton>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
