import { T } from "@/components/LocalizedString";

export type ExtensionStatusType =
  | "active"
  | "disabled"
  | "inactive"
  | "partially_configured"
  | "unknown";

export const ExtensionStatusColors = {
  active: {
    color: "#1dc9b7",
    backgroundColor: "#1dc9b71A",
    label: T("Active"),
  },
  disabled: {
    color: "#949291",
    backgroundColor: "#9492911A",
    label: T("Disabled by user"),
  },
  inactive: {
    color: "#EE97A7",
    backgroundColor: "#FF5F7C1A",
    label: T("Last seen +30 days ago"),
  },
  partially_configured: {
    color: "#5CD85A",
    backgroundColor: "#5CD85A1A",
    label: T("Active w/o password"),
  },
  unknown: {
    color: "#EE97A7",
    backgroundColor: "#FF5F7C1A",
    label: T("Never installed"),
  },
};
