import React from "react";
import { Column } from "@/components/sort/SelectOrderByComponent";
import { ColumnSort } from "@/pages/threats/service/domain";
import SortComponent from "@/components/sort/SortComponent";

type SortToolsProps = {
  onChange: (value: ColumnSort[]) => void;
  columns: Column[];
};

export const SortTools = ({ onChange, columns }: SortToolsProps) => {
  return (
    <SortComponent
      setOrderBy={(values) =>
        onChange(
          values
            .filter((v) => v.column !== null)
            .map((v) => ({
              column: v.label,
              direction: v.order,
            })),
        )
      }
      orderChoices={columns}
    />
  );
};
