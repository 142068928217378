import React, { useRef } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import css from "@/pages/banners/dashboard/MediumBarChart.module.css";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

type ChartData = {
  last_seven_days: number[];
  previous_seven_days: number[];
};

function CalculateVariation(data: ChartData): number {
  const thisWeekCount = Object.values(data.last_seven_days).reduce(
    (partialSum, a) => partialSum + a,
    0,
  );
  const previousWeekCount = Object.values(data.previous_seven_days).reduce(
    (partialSum, a) => partialSum + a,
    0,
  );
  return Math.floor((thisWeekCount / previousWeekCount - 1) * 100);
}

type MediumBarCharProps = {
  title: string;
  data: ChartData;
  compact?: boolean;
};
export default function MediumBarChart({
  title,
  data,
  compact = false,
}: MediumBarCharProps) {
  let labels = Object.keys(data.previous_seven_days);
  const { t } = useTranslation();
  let displayedData = {
    labels: labels,

    datasets: [
      {
        label: "Last Seven Days",
        fill: true,
        pointRadius: 0,
        lineTension: 0.5,
        borderColor: "hsl(252, 86%, 69%)",
        backgroundColor: function (context) {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 150);
          gradient.addColorStop(0, "rgba(136,108,244,0.3)");
          gradient.addColorStop(1, "rgba(136, 108, 244,0)");
          return gradient;
        },
        data: Object.values(data.previous_seven_days),
        labels: Object.keys(data.previous_seven_days).sort(),
      },
      {
        label: "Previous 7 days",
        fillColor: "start",
        fill: true,
        pointRadius: 0,
        lineTension: 0.5,
        borderColor: "rgb(20, 183, 234)",
        backgroundColor: function (context) {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 150);
          gradient.addColorStop(0, "rgba(20, 183, 234, 0.3)");
          gradient.addColorStop(1, "rgba(136, 108, 244, 0)");

          return gradient;
        },
        data: Object.values(data.last_seven_days),
        labels: Object.keys(data.last_seven_days).sort(),
      },
    ],
  };
  let variation = CalculateVariation(data);
  let sumThisWeek = Object.values(data.last_seven_days).reduce(
    (partialSum, a) => partialSum + a,
    0,
  );
  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <div className={css.topChart}>
          <div style={{ display: "flex" }}>
            <span>
              <b>{sumThisWeek} </b>
            </span>
            {variation < 0 ? (
              <>
                <ArrowDownwardIcon
                  style={{ color: "#03d34a", paddingBottom: "3px" }}
                />
                <span>
                  {variation}% {t("decrease in the 7 last days")}
                </span>
              </>
            ) : (
              <>
                <ArrowUpwardIcon
                  style={{ color: "#D9305E", paddingBottom: "3px" }}
                />
                <span>
                  {variation}% {t("increase in the 7 last days")}
                </span>
              </>
            )}
          </div>
        </div>
        <div className={css.chartLegend}>
          <div style={{ display: "flex", marginRight: "10px" }}>
            <div
              style={{ border: "2.5px solid hsl(252, 86%, 69%)" }}
              className={css.circle}
            ></div>
            <p style={{ margin: "0px", marginLeft: "20px" }}>
              <b>{t("Previous 7 days")}</b>
            </p>
          </div>

          <Grid style={{ display: "flex" }}>
            <div
              style={{ border: "2.5px solid rgb(20, 183, 234)" }}
              className={css.circle}
            ></div>

            <p style={{ margin: "0px", marginLeft: "20px" }}>
              <b>{t("Last 7 days")}</b>
            </p>
          </Grid>
        </div>
        <div style={{ paddingTop: "15px", width: "100%" }}>
          <MainChart data={displayedData} />
        </div>
      </CardContent>
    </Card>
  );
}

function MainChart({ data }) {
  const chartRef = useRef(null);
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
  );
  let options = {
    responsive: true,
    maintainAspectRatio: false,

    legend: {
      display: false,
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        intersect: false,
        callbacks: {
          label: function (tooltipItem) {
            const index = tooltipItem.dataIndex;
            return (
              tooltipItem.dataset.labels[index] +
              ": " +
              tooltipItem.dataset.data[index]
            );
          },
          title: function () {},
        },
      },
    },
  };
  return (
    <div>
      <Line options={options} data={data} ref={chartRef} />
    </div>
  );
}
