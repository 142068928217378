export enum StepperStateKey {
  COMPANY_SETUP = 0,
  API_ACCESS = 1,
  DEPLOYMENT_TEST = 2,

  HISTORICAL_ANALYSIS = 3, // HistoricalAnalysis
  SAFE_LIST_SETUP = 4,
  SAMPLE_EMAILS = 5, // SampleEmails
  ENGAGE_COMPANY = 6,
}

type BannerControllerInfo = {
  banner_controller: "GMAIL" | "OFFICE" | "DEMO";
  office_consent?: boolean;
  gmail_service_account_id?: string;
  gmail_consent?: boolean;
};

export type BannerSetupChecklistStatus = {
  banner_controller_infos?: BannerControllerInfo;
  company_in_catalogue: boolean;
  engaged_users: number;
  active_users: number;
  disabled_users: number;
  deployment_test_done: boolean;
  historical_analysis_reports_count: number;
  have_safe_lists: boolean;
  sent_emails_done: boolean;
  auto_engage: boolean;
};

export type CompanyEngagementStatus = {
  company: { name: string; id: number; domain: string };
  status: BannerSetupChecklistStatus;
};
