import React, { ReactNode } from "react";
import { Box, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

type InfoTooltipProps = {
  content: ReactNode;
};

const InfoTooltip = (props: InfoTooltipProps): JSX.Element => {
  return (
    <Box>
      <Tooltip title={props.content} arrow>
        <InfoIcon
          sx={{
            ml: 1,
            fontSize: "medium",
            cursor: "pointer",
            verticalAlign: "middle",
            color: "darkgray",
          }}
        />
      </Tooltip>
    </Box>
  );
};

export default InfoTooltip;
