import React from "react";
import { useTranslation } from "react-i18next";

export function ThreatAttachment({ attachment, ...props }) {
  const { t } = useTranslation();
  let className;

  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
  switch (attachment.content_type) {
    case "image/bmp":
    case "image/gif":
    case "image/jpeg":
    case "image/png":
      className = "far fa-file-image";
      break;
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "application/vnd.oasis.opendocument.spreadsheet":
      className = "far fa-file-excel";
      break;
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "application/vnd.oasis.opendocument.text":
      className = "far fa-file-word";
      break;
    case "application/vnd.ms-powerpoint":
    case "application/vnd.oasis.opendocument.presentation":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      className = "far fa-file-powerpoint";
      break;
    case "application/pdf":
      className = "far fa-file-pdf";
      break;
    case "application/x-freearc":
    case "application/x-bzip":
    case "application/x-bzip2":
    case "application/gzip":
    case "application/java-archive":
    case "application/vnd.rar":
    case "application/x-tar":
    case "application/zip":
    case "application/x-7z-compressed":
      className = "far fa-file-archive";
      break;
    default:
      className = "far fa-file";
  }
  return (
    <div
      style={{
        display: "flex",
        fontSize: "0.9rem",
        color: "rgba(0,0,0, 0.9)",
        alignItems: "center",
      }}
      {...props}
    >
      <i className={className} />
      <div style={{ paddingLeft: "0.25rem" }}>
        {attachment.name} ({Math.round(attachment.size / 1024)} {t("kb")}
      </div>
    </div>
  );
}
