import React, { useContext } from "react";
import { ManualDeployment } from "@/pages/browser-defender/setup/checklist/ManualDeployment";
import { ManagedDeployment } from "@/pages/browser-defender/setup/checklist/ManagedDeployment";
import {
  SetupChecklist as SetupChecklistComponent,
  SetupChecklistContext,
} from "@/components/stepper/SetupChecklist";
import { StepperStateKey } from "@/pages/browser-defender/setup/checklist/ChecklistTypes";
import { SetupStep } from "@/components/stepper/Stepper";
import { useTranslation } from "react-i18next";

const CurrentComponent: React.FC = () => {
  const checklist = useContext(SetupChecklistContext);

  switch (checklist.activeIndex) {
    case StepperStateKey.MANUAL_DEPLOY:
      return <ManualDeployment />;
    case StepperStateKey.MANAGED_DEPLOY:
      return <ManagedDeployment />;
  }
};

export const SetupChecklist: React.FC = () => {
  const { t } = useTranslation();

  const steps: SetupStep[] = [
    { label: t("Manual deployment"), status: "uncompleted", mandatory: false },
    { label: t("Managed deployment"), status: "uncompleted", mandatory: false },
  ];

  return (
    <SetupChecklistComponent steps={steps}>
      <CurrentComponent />
    </SetupChecklistComponent>
  );
};
