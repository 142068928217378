import React, { ReactNode } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

export type ChecklistPaneProps = {
  title: ReactNode;
  children?: React.ReactNode;
};

export const ChecklistPane: React.FC<ChecklistPaneProps> = ({
  title,
  children,
}: ChecklistPaneProps) => {
  return (
    <Box display="flex" flexDirection="column" gap="1.5rem">
      <Typography variant="h5">{title}</Typography>
      {children}
    </Box>
  );
};
