import React from "react";
import { Button, ButtonGroup } from "@mui/material";
import { useTranslation } from "react-i18next";

export function FeedbackButtons({ updateThreatsStatuses, disabled }) {
  const { t } = useTranslation();
  return (
    <ButtonGroup
      color="neutral"
      variant="outlined"
      disabled={disabled}
      sx={{ flex: "0 0 auto" }}
    >
      <Button
        onClick={() => updateThreatsStatuses("spam")}
        title="Archive this threat(s) if containing possibly spam element"
      >
        <i className="fas fa-ban" />
        &nbsp; {t("Spam")}
      </Button>
      <Button
        onClick={() => updateThreatsStatuses("safe")}
        title="Archive this threat(s)  if not representing any danger"
      >
        <i className="fas fa-check text-success" />
        &nbsp; {t("Safe")}
      </Button>
      <Button
        onClick={() => updateThreatsStatuses("suspicious")}
        title="Archive this threat(s)  if containing possibly suspicious elements"
      >
        <i className="fas fa-question text-warning" />
        &nbsp; {t("Suspicious")}
      </Button>
      <Button
        onClick={() => updateThreatsStatuses("unsafe")}
        title="Archive this threat(s)  if containing threatening elements without any doubt"
      >
        <i className="fas fa-exclamation-circle text-danger" />
        &nbsp; {t("Unsafe")}
      </Button>
    </ButtonGroup>
  );
}
