import {
  Autocomplete,
  Box,
  Button,
  LinearProgress,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { extractErrorMessage } from "@/utils/misc";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation, Trans } from "react-i18next";

const TEMPLATE_KEY = "template";
export const SendAnySimulation = () => {
  const { t } = useTranslation();
  const [templateList, setTemplateList] = useState([]);
  const [lastSimuSent, setLastSimuSent] = useState(null);
  const [errorMessageSimu, setErrorMessageSimu] = useState("");
  const [template, setTemplate] = useState(null);
  const [outboundRequest, setOutboundRequest] = useState(false);
  const { user } = useAuth0();

  useEffect(() => {
    const f = async () => {
      const result = await axios.get(
        `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/templates/name`,
      );
      setTemplateList(result.data.templates);
      try {
        setTemplate(JSON.parse(localStorage.getItem(TEMPLATE_KEY)));
      } catch (e) {
        console.error(e);
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    f();
  }, []);

  const sendSimulation = async () => {
    try {
      if (!template) {
        setErrorMessageSimu("Please select a template");
        return;
      }
      localStorage.setItem(TEMPLATE_KEY, JSON.stringify(template));
      setOutboundRequest(true);
      const response = await axios.post(
        `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/email_to_user`,
        {
          toUser: user.email,
          template: template.name,
          template_language: template.language,
          prod_smtp: true,
        },
      );
      setLastSimuSent(response.data.id);
      setOutboundRequest(false);
      setErrorMessageSimu(null);
    } catch (error) {
      setLastSimuSent(false);
      setOutboundRequest(false);
      setErrorMessageSimu(extractErrorMessage(error));
    }
  };

  if (templateList.length <= 0) {
    return <></>;
  }

  return (
    <>
      <Box>
        <Stack direction="row" gap="1rem" my="1rem">
          <Autocomplete
            sx={{ width: "50%", background: "white" }}
            options={templateList}
            value={template}
            getOptionLabel={(option) => `${option.name} | ${option.language}`}
            isOptionEqualToValue={(option) =>
              templateList.length &&
              templateList.findIndex((o) => o === option) >= 0
            }
            onChange={(e, value) => {
              setTemplate(value);
              setLastSimuSent(null);
            }}
            loadingText={t("Loading") + "..."}
            renderInput={(params) => (
              <TextField {...params} label={t("Template")} />
            )}
          />
          <Button
            variant="contained"
            color="yellow"
            disabled={outboundRequest}
            onClick={sendSimulation}
          >
            <Trans i18nKey="SendAnySimulationText">
              Send a&nbsp;
              <span role="img" aria-label="phishing">
                🎣
              </span>
              &nbsp; simulation
            </Trans>
          </Button>
        </Stack>
        <div>
          {lastSimuSent && template && (
            <div style={{ color: "black" }}>
              <strong>
                {template.name} | {template.language}{" "}
              </strong>
              <Trans
                i18nKey="SendAnySimulationResult"
                values={{ user_email: user.email, simulation_id: lastSimuSent }}
              >
                sent to
                <strong>{user.email}</strong>, simulation id=
                <strong>{lastSimuSent}</strong>
              </Trans>
              <CheckCircleIcon color="success" style={{ marginLeft: "1rem" }} />
            </div>
          )}
          {errorMessageSimu && (
            <div style={{ color: "red" }}>{errorMessageSimu}</div>
          )}
        </div>
      </Box>
      {outboundRequest && <LinearProgress className="mt-2" />}
    </>
  );
};
