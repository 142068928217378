import axios from "axios";
import { useState } from "react";
import { useSnackbar } from "material-ui-snackbar-provider";
import { downloadThreatCSV } from "./utils";
import { useAuth0 } from "@auth0/auth0-react";
import { ROW_PER_PAGE } from "@/pages/oldthreats/ThreatHooks";

type DownloadThreatsAsCSVParams = {
  archived: boolean;
  numPages: number;
};

const useDownloadThreatsAsCSV = ({
  archived,
  numPages,
}: DownloadThreatsAsCSVParams) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const snackbar = useSnackbar();
  const { getAccessTokenSilently } = useAuth0();

  const getThreats = async (): Promise<Threat[]> => {
    let threats: Threat[] = [];
    const accessToken = await getAccessTokenSilently();
    for (let i = 0; i < numPages; i++) {
      const response = await axios.get(
        `${
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION
        }/threats-legacy-ui/threats?archived=${archived}&current_page=${
          i + 1
        }&rows_per_page=${ROW_PER_PAGE}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );
      const data = await response.data;
      threats = [...threats, ...data.threats];
    }
    return threats;
  };

  const downloadAsCsv = async () => {
    setIsLoading(true);
    try {
      const threats = await getThreats();
      downloadThreatCSV(threats);
    } catch {
      snackbar.showMessage("Error while fetching threats");
    }
    setIsLoading(false);
  };

  return {
    downloadAsCsv,
    isLoading,
  };
};

export default useDownloadThreatsAsCSV;
