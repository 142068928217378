import { T } from "@/components/LocalizedString";
import { ReactNode } from "react";

export interface UserWithPermissions {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  permissions: Array<Permission>;
}

export enum PermissionCategory {
  SIMULATION = "simulation",
  AWARENESS = "awareness",
  BANNERS = "banners",
  BROWSER = "browser",
  THREATS = "threats",
  SETTINGS = "general-settings",
  PERMISSIONS_ATTRIBUTION = "permissions",
  CORPORATE = "corporate-admin",
}

export const CategoryTitle = new Map<PermissionCategory, ReactNode>([
  [PermissionCategory.SIMULATION, T("Phishing Simulation")],
  [PermissionCategory.AWARENESS, T("Cyber Awareness")],
  [PermissionCategory.BANNERS, T("Smart Banners")],
  [PermissionCategory.BROWSER, T("Browser Defender")],
  [PermissionCategory.THREATS, T("Threats")],
  [PermissionCategory.SETTINGS, T("General Settings")],
  [PermissionCategory.PERMISSIONS_ATTRIBUTION, T("Permissions")],
  [PermissionCategory.CORPORATE, T("Corporate")],
]);

export class Permission {
  public static readonly SIMULATION_DASHBOARD = new Permission(
    "simulation:dashboard",
    T("Dashboard"),
    T("Access to your company's Phishing Simulation dashboard"),
    PermissionCategory.SIMULATION,
    true,
  );
  public static readonly SIMULATION_CUSTOM_CAMPAIGN = new Permission(
    "simulation:custom-campaign",
    T("Custom Campaign"),
    T("Create and send custom Phishing Simulation campaigns"),
    PermissionCategory.SIMULATION,
    true,
  );
  public static readonly SIMULATION_TEMPLATE_CATALOG = new Permission(
    "simulation:template-catalog",
    T("Template Catalog"),
    T("Access to template catalog"),
    PermissionCategory.SIMULATION,
    false,
  );
  public static readonly SIMULATION_AUDIT = new Permission(
    "simulation:audit",
    T("Audit"),
    T("Access to phishing simulation audit page"),
    PermissionCategory.SIMULATION,
    true,
  );
  public static readonly SIMULATION_SETUP = new Permission(
    "simulation:setup",
    T("Setup"),
    T("Access to preferences and test simulation delivery"),
    PermissionCategory.SIMULATION,
    true,
  );
  public static readonly AWARENESS_DASHBOARD = new Permission(
    "awareness:dashboard",
    T("Dashboard"),
    T("Access to your company's Cyber Awareness dashboard"),
    PermissionCategory.AWARENESS,
    true,
  );
  public static readonly AWARENESS_COURSE_CATALOG_ADMIN = new Permission(
    "awareness:course-catalog-admin",
    T("Courses Admin"),
    T("Manage course content and assignments"),
    PermissionCategory.AWARENESS,
    true,
  );
  public static readonly AWARENESS_MANAGE_USERS = new Permission(
    "awareness:manage-users",
    T("Manage Users"),
    T("Manage users"),
    PermissionCategory.AWARENESS,
    true,
  );
  public static readonly AWARENESS_SETUP = new Permission(
    "awareness:setup",
    T("Setup"),
    T("Access to preferences and chatbot setup"),
    PermissionCategory.AWARENESS,
    true,
  );
  public static readonly THREATS = new Permission(
    "threats",
    T("Threats"),
    T("View and categorize real emails reported by users"),
    PermissionCategory.THREATS,
    true,
  );
  public static readonly SETTINGS_COMPANY_SCAN = new Permission(
    "settings:company-scan",
    T("Company Scan"),
    T(
      "Manage company information, groups & departments mapping and Saas attribution",
    ),
    PermissionCategory.SETTINGS,
    true,
  );
  public static readonly SETTINGS_GENERAL = new Permission(
    "settings:general",
    T("General Settings"),
    T(
      "Access to users list and manage provisioning from your active directory",
    ),
    PermissionCategory.SETTINGS,
    true,
  );
  public static readonly CORPORATE_ADMIN = new Permission(
    "corporate-admin",
    T("Corporate Admin"),
    T(
      "Browse through all subsidiaries of your group and access to corporate aggregated dashboards",
    ),
    PermissionCategory.CORPORATE,
    true,
  );
  public static readonly BROWSER_DEFENDER = new Permission(
    "browser-defender",
    T("Browser Defender"),
    T("Access to Browser Defender section in the app"),
    PermissionCategory.BROWSER,
    true,
  );
  public static readonly BANNERS = new Permission(
    "banners",
    T("Smart Banners"),
    T("Access to Smart Banners section in the app"),
    PermissionCategory.BANNERS,
    true,
  );
  public static readonly EDIT_PERMISSIONS = new Permission(
    "settings:edit-permissions",
    T("Edit Permissions"),
    T(
      "Add and remove custom permissions to other users (you cannot modify your own)",
    ),
    PermissionCategory.PERMISSIONS_ATTRIBUTION,
    true,
  );

  // Non editable permissions
  public static readonly MY_PROGRESS = new Permission(
    "my-progress",
    T("My Progress"),
    T("View your progress in the training"),
    PermissionCategory.AWARENESS,
    false,
  );
  public static readonly BASIC_USER_PERMISSIONS = new Permission(
    "basic-user-permissions",
    T("Basic User"),
    T("Basic user permissions"),
    PermissionCategory.PERMISSIONS_ATTRIBUTION,
    false,
  );
  public static readonly BASIC_ADMIN_PERMISSIONS = new Permission(
    "basic-admin-permissions",
    T("Basic Admin"),
    T("Basic admin permissions"),
    PermissionCategory.PERMISSIONS_ATTRIBUTION,
    false,
  );
  public static readonly AWARENESS_COURSE_CATALOG_USER = new Permission(
    "awareness:course-catalog-user",
    T("Course User"),
    T("Access to course content"),
    PermissionCategory.AWARENESS,
    false,
  );
  public static readonly SUPPORT = new Permission(
    "support",
    T("Support"),
    T("Access to support"),
    PermissionCategory.SETTINGS,
    false,
  );

  private constructor(
    public readonly code: string,
    public readonly name: ReactNode,
    public readonly description: ReactNode,
    public readonly category: PermissionCategory,
    public readonly editable: boolean = false,
  ) {
    this.code = code;
    this.name = name;
    this.description = description;
    this.category = category;
    this.editable = editable;
  }

  private static readonly ALL_PERMISSIONS = [
    Permission.SIMULATION_DASHBOARD,
    Permission.SIMULATION_CUSTOM_CAMPAIGN,
    Permission.SIMULATION_TEMPLATE_CATALOG,
    Permission.SIMULATION_AUDIT,
    Permission.SIMULATION_SETUP,
    Permission.AWARENESS_DASHBOARD,
    Permission.AWARENESS_COURSE_CATALOG_ADMIN,
    Permission.AWARENESS_MANAGE_USERS,
    Permission.AWARENESS_SETUP,
    Permission.THREATS,
    Permission.SETTINGS_GENERAL,
    Permission.SETTINGS_COMPANY_SCAN,
    Permission.EDIT_PERMISSIONS,
    Permission.CORPORATE_ADMIN,
    Permission.BROWSER_DEFENDER,
    Permission.BANNERS,
    Permission.MY_PROGRESS,
    Permission.BASIC_USER_PERMISSIONS,
    Permission.BASIC_ADMIN_PERMISSIONS,
    Permission.AWARENESS_COURSE_CATALOG_USER,
    Permission.SUPPORT,
  ];

  public static fromCode(code: string): Permission {
    return Permission.ALL_PERMISSIONS.find((p) => p.code === code);
  }

  public static all(): Array<Permission> {
    return Permission.ALL_PERMISSIONS;
  }

  public static allEditable(): Array<Permission> {
    return Permission.ALL_PERMISSIONS.filter((p) => p.editable);
  }
  public static allEditableWithoutCorporate(): Array<Permission> {
    return Permission.ALL_PERMISSIONS.filter(
      (p) => p.editable && p !== Permission.CORPORATE_ADMIN,
    );
  }
}
