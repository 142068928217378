import React, { ReactNode } from "react";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { AppRoutes } from "@/router/AppRoutes";

import BeamerInit from "@/components/utils/BeamerInit";
import ComposeProviders from "@/components/utils/ComposeProviders";
import ReduxProvider from "@/components/utils/ReduxProvider";
import SnackbarProvider from "@/components/utils/SnackbarProvider";
import ThemeProvider from "@/components/utils/ThemeProvider";
import { UserContextProvider } from "@/utils/contexts/UserContext";
import SentryInit from "@/components/utils/SentryInit";
import { AuthProvider } from "@/utils/auth";
import { BrowserRouter } from "react-router-dom";
import LocalizationProvider from "@/components/utils/LocalizationProvider";
import { useTranslation } from "react-i18next";

const App = (): ReactNode => {
  const { t } = useTranslation();
  return (
    <BrowserRouter>
      <AuthProvider>
        <SentryErrorBoundary fallback={<p>{t("An error has occurred")}</p>}>
          <ComposeProviders
            components={[
              SentryInit,
              ReduxProvider,
              SnackbarProvider,
              UserContextProvider,
              LocalizationProvider,
              ThemeProvider,
              BeamerInit,
            ]}
          >
            <AppRoutes />
          </ComposeProviders>
        </SentryErrorBoundary>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
