import { useState } from "react";
import { BACKEND_URL } from "@/pages/banners/remote-apis/config";
import { useBackend } from "@/utils/misc";
import axios from "axios";
import { Buffer } from "buffer";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { selectAuth0Token } from "@/utils/contexts/AuthSlice";

export function useAnalyzeHistoricalMessages() {
  const [isSendError, setIsSendError] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const token = useSelector(selectAuth0Token);

  const analyzeHistoricalMessage = async ({
    userEmails,
    maxEmails,
    startDate,
    endDate,
  }) => {
    try {
      setIsSending(true);
      const url = new URL(
        `${BACKEND_URL}/banners/historical_analysis_reports_analyze`,
      );
      await axios.post(
        url.href,
        {
          start_date: startDate,
          end_date: endDate,
          max_emails: maxEmails,
          email_addresses: userEmails,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setIsSendError(false);
      setIsSending(false);
      enqueueSnackbar(
        "The mailboxes are being analyzed. Please wait a few minutes and refresh the page to see the report.",
      );
    } catch (error) {
      setIsSendError(true);
      setIsSending(false);
      enqueueSnackbar(
        "A technical error prevented the mailboxes from being analyzed.",
        { variant: "error" },
      );
    }
  };

  return { isSendError, isSending, analyzeHistoricalMessage };
}

export function useHistoricalReportList() {
  const url = new URL(`${BACKEND_URL}/banners/historical_analysis_reports`);

  const { error, data, isPending } = useBackend(url.href, "GET");
  return {
    reports: data,
    error,
    isPending,
  };
}

export function useDownloadReport() {
  const token = useSelector(selectAuth0Token);

  const downloadReport = async (url) => {
    try {
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const downloadUrl = response.data;
      const downloadResponse = await axios.get(downloadUrl, {
        responseType: "arraybuffer",
      });
      const contentType = downloadResponse.headers["content-type"];

      const data = Buffer.from(downloadResponse.data, "binary").toString(
        "base64",
      );
      return { contentType, data };
    } catch (error) {
      enqueueSnackbar("A technical error occurred.", { variant: "error" });
      return { contentType: null, data: null };
    }
  };

  return { downloadReport };
}
