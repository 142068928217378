import React, { useEffect, useState } from "react";
import SaaSSelector from "@/pages/general-settings/commonUserProvisioning/DepartmentsSaas/SaaSSelector";
import { extractErrorMessage } from "@/utils/misc";
import { Card, CardContent, Typography } from "@mui/material";
import TemplateAttributionSelector from "@/pages/phishing-simulation/company/TemplateAttributionSelector";
import axios from "axios";
import { useSnackbar } from "material-ui-snackbar-provider";
import { BigSpinner } from "@/components/Spinner";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";

export function TemplateAttribution() {
  const [departments, setDepartments] = useState([]);
  const [saasList, setSaasList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [companySaasList, setCompanySaasList] = useState([]);
  const [isLoadingDepartments, setIsLoadingDepartments] = useState(true);
  const [isLoadingSaasList, setIsLoadingSaasList] = useState(true);
  const [isLoadingCompanySaasList, setIsLoadingCompanySaasList] =
    useState(true);
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  // Update company saas
  const onChange = (newSaasList) => {
    const f = async () => {
      try {
        const result = await axios.put(
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/company/saas",
          newSaasList,
        );
        setCompanySaasList(result.data);
        setErrorMessage(null);
        snackbar.showMessage("Company saas updated");
      } catch (error) {
        setErrorMessage(extractErrorMessage(error));
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    f();
  };

  // Populate the company saas list
  useEffect(() => {
    const f = async () => {
      try {
        const result = await axios.get(
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/company/saas",
        );

        setCompanySaasList(result.data);
        setErrorMessage(null);
      } catch (error) {
        setErrorMessage(extractErrorMessage(error));
      } finally {
        setIsLoadingCompanySaasList(false);
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    f();
  }, [setIsLoadingCompanySaasList]);

  // Populate the departments
  useEffect(() => {
    const f = async () => {
      try {
        const result = await axios.get(
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/departments",
        );

        setDepartments(result.data);
        setErrorMessage(null);
      } catch (error) {
        setErrorMessage(extractErrorMessage(error));
      } finally {
        setIsLoadingDepartments(false);
      }
    };

    // noinspection JSIgnoredPromiseFromCall
    f();
  }, [setIsLoadingDepartments]);

  useEffect(() => {
    const f = async () => {
      // update only when the array is not empty
      if (departments.length > 0) {
        try {
          await axios.put(
            import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/departments",
            departments,
          );
          setErrorMessage(null);
        } catch (error) {
          setErrorMessage(extractErrorMessage(error));
        }
      }
    };

    f();
  }, [departments]);

  // Populate the saas list
  useEffect(() => {
    const f = async () => {
      try {
        const result = await axios.get(
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/saas",
        );

        setSaasList(result.data);
        setErrorMessage(null);
      } catch (error) {
        setErrorMessage(extractErrorMessage(error));
      } finally {
        setIsLoadingSaasList(false);
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    f();
  }, [setIsLoadingSaasList]);

  if (isLoadingSaasList || isLoadingDepartments || isLoadingCompanySaasList) {
    return <BigSpinner />;
  }

  return (
    <Card>
      <CardContent>
        <Box mt={2}>
          <Typography style={{ color: "black" }}>
            {t("Select the templates used by each department.")}
          </Typography>
        </Box>
        {errorMessage !== null && (
          <Typography style={{ color: "red" }}>{errorMessage}</Typography>
        )}

        <div className="row">
          <div className="col-md-6">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">{t("Department")}</th>
                  <th scope="col">{t("Used Template tags")}</th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ width: "20%" }}>{t("Everyone")}</td>
                  <td>
                    <TemplateAttributionSelector
                      saasList={saasList}
                      companySaasList={companySaasList}
                      onChange={onChange}
                    />
                  </td>
                </tr>
                {departments.map((department) => (
                  <tr key={department.id}>
                    <td style={{ width: "20%" }}>{department.name}</td>
                    <td>
                      <SaaSSelector
                        department={department}
                        departments={departments}
                        setDepartments={setDepartments}
                        saasList={saasList}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
