import React from "react";
import { Box, Typography } from "@mui/material";
import { EmailSent } from "@/components/icons/EmailSent";
import { useTranslation } from "react-i18next";

export const Push = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box sx={{ marginLeft: "4rem", display: "flex", marginTop: "3rem" }}>
        <EmailSent sx={{ fontSize: "32px" }} />

        <Typography
          sx={{
            fontSize: "25px",
            fontWeight: "500",
            marginLeft: "1rem",
            color: "#0A2653",
          }}
        >
          {t("Push latest cybersecurity news and trends to your team !")}
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: "600",
          color: "#5E60FB",
          marginLeft: "7.4rem",
          marginTop: "1.5rem",
        }}
      >
        Coming soon ...
      </Typography>
    </React.Fragment>
  );
};
