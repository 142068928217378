import { StyledBox } from "@/pages/awareness/setup/Teams/StyledBox";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Collapse,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { debounce } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchByEmailQuery } from "@/learningApi";

export const CollapseAutocomplete = ({
  openCollapse,
  selectedUsers,
  actuallyDeployedUsers,
  setDeployedUsers,
}) => {
  const { t } = useTranslation();
  const [debouncedSearchString, setDebouncedSearchString] = useState("");
  const { data: available_options, isFetching } = useSearchByEmailQuery(
    debouncedSearchString,
  );

  const debouncedUpdateSearch = debounce(setDebouncedSearchString, 500);

  const removeSelectedUser = (id) => {
    const tmp = selectedUsers.filter((user) => user.id !== id);
    setDeployedUsers(tmp);
  };

  const handleChange = (event, newValue) => {
    setDeployedUsers(newValue);
  };

  return (
    <Collapse
      in={openCollapse}
      timeout="auto"
      sx={{ paddingLeft: "5rem" }}
      unmountOnExit
      key={"users_subset_collapsed"}
    >
      <Autocomplete
        size="small"
        multiple
        options={available_options ? available_options : []}
        getOptionLabel={(option) => option.email}
        value={selectedUsers}
        loading={isFetching}
        loadingText={t("Loading") + "..."}
        onChange={handleChange}
        onInputChange={(event, value) => debouncedUpdateSearch(value)}
        renderTags={() => null}
        renderInput={(params) => (
          <TextField sx={{ width: "80%" }} {...params} label="email" />
        )}
      />
      {selectedUsers.map((value, index) => (
        <StyledBox sx={{ justifyContent: "unset" }} key={index}>
          <IconButton
            onClick={() => {
              removeSelectedUser(value.id);
            }}
          >
            <CloseIcon sx={{ width: "0.8em" }} />
          </IconButton>
          <Typography
            sx={{
              px: "1.5rem",
              width: "60%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            color={"text.secondary"}
          >
            {value.email}
          </Typography>
          {actuallyDeployedUsers.includes(value.id) ? (
            <CheckCircleOutlineOutlinedIcon color={"success"} />
          ) : (
            <CancelOutlinedIcon color={"error"} />
          )}
        </StyledBox>
      ))}
    </Collapse>
  );
};
