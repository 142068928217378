import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  DialogContentText,
} from "@mui/material";
import { ButtonSpinner } from "@/components/Spinner";
import { useTranslation } from "react-i18next";

type DeleteConfirmationDialogProps = {
  isShown: boolean;
  onConfirm: () => void;
  onConfirmText: string;
  onCancel: () => void;
  onCancelText: string;
  title: string;
  content?: string;
  isLoading?: boolean;
  onClose?: () => void;
  confirmText?: string;
  extraContent?: React.JSX.Element;
};

export const DeleteConfirmationDialog = ({
  isShown,
  onConfirm,
  onConfirmText,
  onCancel,
  onCancelText,
  title,
  content = "",
  isLoading = false,
  onClose = () => false,
  confirmText = null,
  extraContent = null,
}: DeleteConfirmationDialogProps) => {
  const [inputValue, setInputValue] = React.useState("");
  const { t } = useTranslation();

  if (!confirmText) {
    confirmText = t("DELETE");
  }

  const isConfirmEnabled = inputValue === confirmText;
  const verifyAndConfirm = () => {
    if (isConfirmEnabled) {
      onConfirm();
    } else {
      alert(t("Please type the correct text to confirm."));
    }
  };

  return (
    <Dialog
      open={isShown}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
        {extraContent}
        <DialogContentText color="error" sx={{ mt: 2, marginBottom: "5px" }}>
          {t("Confirm deletion by typing")} &quot;<strong>{confirmText}</strong>
          &quot; {t("below")}.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label={confirmText}
          fullWidth
          variant="outlined"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        {isLoading ? <ButtonSpinner sx={{ marginRight: "1rem" }} /> : ""}
        <Button
          onClick={onCancel}
          variant="contained"
          color="primary"
          autoFocus
        >
          {onCancelText}
        </Button>
        <Button
          onClick={verifyAndConfirm}
          variant="outlined"
          color="warning"
          disabled={!isConfirmEnabled}
        >
          {onConfirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
