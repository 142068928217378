import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
} from "@mui/material";
import { useFetchGeneralSettingsUsersQuery } from "@/apis/usersApi";
import { usePaginationData } from "@/utils/pagination";
import { useTranslation } from "react-i18next";
import { SearchField } from "@/components/SearchField";
import { FullPageSpinner } from "@/components/Spinner";
import { T } from "@/components/LocalizedString";

const COLUMNS = [
  { name: "firstname", label: T("First Name") },
  { name: "lastname", label: T("Last Name") },
  { name: "email", label: T("Email") },
  { name: "department", label: T("Department") },
  { name: "location", label: T("Location") },
  { name: "status", label: T("Status") },
];

const UserRow = ({ user }): React.JSX.Element => {
  const { t } = useTranslation();

  return (
    <TableRow>
      {COLUMNS.map((column) => (
        <TableCell key={column.name}>
          {column.name === "status"
            ? user.active
              ? t("Active")
              : t("Inactive")
            : column.name === "department"
              ? user.departments.join(", ") || ""
              : user[column.name] || ""}
        </TableCell>
      ))}
    </TableRow>
  );
};

const UserHeadRow = (): React.JSX.Element => {
  return (
    <TableRow>
      {COLUMNS.map((column) => (
        <TableCell key={column.name}>
          <strong>{column.label}</strong>
        </TableCell>
      ))}
    </TableRow>
  );
};

export const UserList = ({ activeUsersOnly }): React.JSX.Element => {
  const { t } = useTranslation();
  const pagination = usePaginationData();

  const [listOptions, setListOptions] = useState({
    sort: "lastname",
    searchValue: "",
    language: null,
    department: null,
    location: null,
    activeOnly: activeUsersOnly,
  });

  const updateListOptions = useCallback((newOptions) => {
    setListOptions((prevOptions) => ({
      ...prevOptions,
      ...newOptions,
    }));
  }, []);

  useEffect(() => {
    updateListOptions({ activeOnly: activeUsersOnly });
  }, [activeUsersOnly, updateListOptions]);

  const {
    data: usersData,
    error,
    isLoading,
    isFetching,
  } = useFetchGeneralSettingsUsersQuery({
    startIndex: pagination.startIndex,
    pageSize: pagination.rowsPerPage,
    searchValue: listOptions.searchValue,
    ...listOptions,
  });

  const search = (term: string) => {
    setListOptions((prev) => ({ ...prev, searchValue: term }));
  };

  if (isLoading) {
    return <FullPageSpinner />;
  }

  if (error) {
    return (
      <Box>
        {t("Error")} : {JSON.stringify(error)}
      </Box>
    );
  }

  if (!usersData || !usersData.users) {
    return <Box>{t("Error: Invalid user data")}</Box>;
  }

  return (
    <Card>
      <CardHeader title={t("Users")} />
      <Toolbar>
        <SearchField
          value={listOptions.searchValue}
          onChange={(e) => search(e.target.value)}
        />
      </Toolbar>
      <CardContent>
        <Paper>
          <TableContainer>
            <Box>{isFetching && <LinearProgress />}</Box>
            <Table stickyHeader>
              <TableHead>
                <UserHeadRow />
              </TableHead>
              <TableBody>
                {usersData.users.map((user) => (
                  <UserRow key={user.id} user={user} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            labelRowsPerPage={t("Rows per page") + " :"}
            component="div"
            count={usersData.total_count}
            rowsPerPage={pagination.rowsPerPage}
            page={
              !usersData.total_count || usersData.total_count <= 0
                ? 0
                : pagination.currentPage
            }
            onPageChange={(_, newPage) => {
              pagination.changePage(newPage);
            }}
            onRowsPerPageChange={(event) => {
              const newPageSize = parseInt(event.target.value, 10);
              pagination.changePageSize(newPageSize);
            }}
          />
        </Paper>
      </CardContent>
    </Card>
  );
};
