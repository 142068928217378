import React from "react";
import { useSnackbar } from "material-ui-snackbar-provider";
import CloudDownloadButton from "@/components/buttons/cloudDownloadButton";
import { useLazyThreatEmlQuery } from "@/pages/threats/api";
import { useTranslation } from "react-i18next";

const DownloadEmlButton = ({ threat }) => {
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const [triggerFetchEml] = useLazyThreatEmlQuery();

  const download = (data: string, mimeType: string, filename: string) => {
    let el = document.createElement("a");
    el.setAttribute(
      "href",
      `data:${mimeType};charset=utf-8,` + encodeURIComponent(data),
    );
    el.setAttribute("download", filename);
    el.style.display = "none";
    document.body.appendChild(el);
    el.click();
    document.body.removeChild(el);
  };

  const handleClick = async () => {
    triggerFetchEml(threat.id, true).then((eml) => {
      if (!eml?.data?.payload) {
        snackbar.showMessage(
          t("An error occurred while fetching EML. Please try again later."),
        );
        return;
      }
      const decoded = atob(eml.data.payload);
      download(decoded, "text/plain", "message.eml");
    });
  };

  return (
    <CloudDownloadButton
      title={t("Download EML")}
      onClick={() => handleClick()}
    />
  );
};

export default DownloadEmlButton;
