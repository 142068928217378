import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { useSelector } from "react-redux";
import { selectAwarenessSetupChecklistState } from "../../SetupChecklistSlice";
import { capitalize } from "@/utils/strings";
import { useTranslation } from "react-i18next";
import SelectMessagingPlatform from "./SelectMessagingPlatform";
import ActiveStepPane from "@/components/stepper/ActiveStepPane";
import { MessagingPlatform } from "../../SetupChecklistTypes";
import { ConfirmationDialog } from "@/components/modals/ConfirmationDialog";
import {
  UpdateMessagingPlatformRequest,
  useUpdateMessagingPlatformMutation,
} from "../../awarenessChecklistApi";
import { useSnackbar } from "material-ui-snackbar-provider";

const SelectedMessagingPlatform: React.FC = () => {
  const { messagingPlatform: validatedMessagingPlatform } = useSelector(
    selectAwarenessSetupChecklistState,
  );
  const [messagingPlatform, setMessagingPlatform] = useState<
    MessagingPlatform | ""
  >("");
  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  const [next, setNext] = useState<() => void | null>(null);
  const { t } = useTranslation();
  const [updateMessagingPlatform, { isLoading, isError, isSuccess }] =
    useUpdateMessagingPlatformMutation();
  const snackbar = useSnackbar();

  const onClickNext = useMemo(() => {
    if (validatedMessagingPlatform != null) {
      return (currentNext: () => void) => currentNext();
    }
    return (currentNext: () => void) => {
      setIsModalShown(true);
      setNext(() => currentNext);
    };
  }, [validatedMessagingPlatform, setIsModalShown, setNext]);

  useEffect(() => {
    if (isSuccess) {
      setIsModalShown(false);
      if (next != null) {
        next();
      }
    }
  }, [isSuccess, setIsModalShown, next]);

  useEffect(() => {
    if (isError) {
      snackbar.showMessage(t("Error: unable to select messaging platform"));
    }
  }, [isError, snackbar, t]);

  return (
    <ActiveStepPane
      onClickNext={onClickNext}
      clickNextDisabled={
        messagingPlatform === "" && validatedMessagingPlatform == null
      }
    >
      <Stack spacing={2}>
        <Typography variant="h5">
          {t("Check that the messaging platform is selected properly")}
        </Typography>
        <Box display="flex" alignItems="center" gap="2.5rem">
          {validatedMessagingPlatform == null ? (
            <SelectMessagingPlatform
              messagingPlatform={messagingPlatform}
              setMessagingPlatform={setMessagingPlatform}
            />
          ) : (
            <>
              {t("Your messaging platform is")}{" "}
              {capitalize(validatedMessagingPlatform)}
              <CheckCircleOutlinedIcon color="success" />
            </>
          )}
        </Box>
      </Stack>
      <SelectMessagingPlatformModal
        isShown={isModalShown}
        onConfirm={() =>
          updateMessagingPlatform({
            messaging_platform: messagingPlatform,
          } as UpdateMessagingPlatformRequest)
        }
        isLoading={isLoading}
        onCancel={() => setIsModalShown(false)}
        messagingPlatform={capitalize(messagingPlatform)}
      />
    </ActiveStepPane>
  );
};

type SelectMessagingPlatformModalProps = {
  isShown: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  isLoading: boolean;
  messagingPlatform: string;
};

const SelectMessagingPlatformModal = (
  props: SelectMessagingPlatformModalProps,
) => {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      title="Validate messaging platform"
      isShown={props.isShown}
      isLoading={props.isLoading}
      onConfirm={props.onConfirm}
      onConfirmText="Confirm"
      onCancel={props.onCancel}
      onCancelText="Cancel"
    >
      {t(
        `Are you sure you want to select ${props.messagingPlatform} as your messaging platform ? You will not be able to change it later.`,
      )}
    </ConfirmationDialog>
  );
};

export default SelectedMessagingPlatform;
