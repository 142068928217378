import React, { ReactElement } from "react";
import ClicksPerDimensionChart from "@/pages/phishing-simulation/dashboard/ClicksPerDimensionChart";
import { Card, CardContent, CardHeader } from "@mui/material";
import { Spinner } from "@/components/Spinner";
import { useTranslation } from "react-i18next";

export default function ClicksPerDimension({
  title,
  backgroundColor,
  dataErrorPending,
}) {
  const { t } = useTranslation();

  let body: ReactElement | null;
  if (dataErrorPending.isPending || !dataErrorPending.data) body = <Spinner />;
  if (dataErrorPending.error) body = <div>{t("An error occurred")}</div>;
  if (dataErrorPending.data && !dataErrorPending.isPending) {
    if (dataErrorPending.data?.length === 0) {
      body = <div>{t("No data available")}</div>;
    } else {
      body = (
        <ClicksPerDimensionChart
          labels={Object.keys(dataErrorPending.data)}
          clickRates={Object.values(dataErrorPending.data)}
          backgroundColor={backgroundColor}
        />
      );
    }
  }
  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>{body}</CardContent>
    </Card>
  );
}
