import React, { useState } from "react";
import { StatsCard } from "@/components/dashboard/StatsCard";
import { useAllCompaniesStatusQuery } from "@/pages/banners/setup/BannerSetupChecklist";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import {
  Collapse,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { StepStatusType } from "@/components/stepper/Stepper";
import ListItemIcon from "@mui/material/ListItemIcon";
import { WarningIcon } from "@/components/stepper/icons/WarningIcon";
import { CompletedIcon } from "@/components/stepper/icons/CompletedIcon";
import CancelIcon from "@mui/icons-material/Cancel";
import { BannerSetupChecklistStatus } from "@/pages/banners/setup/BannersChecklistTypes";
import { useTranslation } from "react-i18next";

type SetupChecks = {
  title: string;
  value: StepStatusType;
};

const StatusIcon = ({ type }: { type: StepStatusType }) => {
  switch (type) {
    case "active":
    case "succeeded":
      return <CompletedIcon />;
    case "error":
      return <CancelIcon color="warning" />;
    case "uncompleted":
    case "warning":
      return <WarningIcon />;
  }
};

const permissionGranted = (status: BannerSetupChecklistStatus) => {
  if (status.banner_controller_infos === null) return "uncompleted";

  if (status.banner_controller_infos.banner_controller === "DEMO")
    return "succeeded";

  if (status.banner_controller_infos.banner_controller === "GMAIL") {
    if (status.banner_controller_infos.gmail_service_account_id === null)
      return "error";

    if (status.banner_controller_infos.gmail_consent) {
      return "succeeded";
    } else {
      return "error";
    }
  }

  if (status.banner_controller_infos.banner_controller === "OFFICE") {
    return status.banner_controller_infos.office_consent !== true
      ? "error"
      : "succeeded";
  }

  return "uncompleted";
};

const CompanyItem = ({ company }) => {
  const [openCollapse, setOpenCollapse] = useState(false);
  const checks: SetupChecks[] = [
    {
      title: "Company in Catalogue",
      value: company.status.company_in_catalogue ? "succeeded" : "error",
    },
    { title: "Permission Granted", value: permissionGranted(company.status) },
  ];

  const allCompleted = checks.some((v) => v.value !== "succeeded")
    ? "warning"
    : "succeeded";

  return (
    <>
      <TableRow onClick={() => setOpenCollapse((prev) => !prev)}>
        <TableCell style={{ width: "5%" }}>
          <StatusIcon type={allCompleted} />
        </TableCell>
        <TableCell>{company.company.name}</TableCell>
        <TableCell>{company.company.domain}</TableCell>
        <TableCell>
          <LinearProgress
            variant="determinate"
            value={
              (100 *
                (company.status.engaged_users +
                  company.status.disabled_users)) /
              company.status.active_users
            }
          />
          {company.status.engaged_users} + {company.status.disabled_users} /{" "}
          {company.status.active_users}
        </TableCell>
      </TableRow>
      <Collapse in={openCollapse}>
        <TableRow>
          <TableCell colSpan={3}>
            <List>
              {checks.map((check) => (
                <ListItem key={JSON.stringify(check)}>
                  <ListItemIcon>
                    <StatusIcon type={check.value} />
                  </ListItemIcon>
                  <ListItemText primary={check.title} />
                </ListItem>
              ))}
            </List>
          </TableCell>
        </TableRow>
      </Collapse>
    </>
  );
};

export const BannersAdmin = () => {
  const { data, isLoading } = useAllCompaniesStatusQuery();
  const { t } = useTranslation();
  return (
    <StatsCard title="Smart Banners Admin" isLoading={isLoading}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>{t("Company")}</TableCell>
              <TableCell>{t("Domain")}</TableCell>
              <TableCell>{t("Engagement Status")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!data ? (
              <>{t("No Data")}</>
            ) : (
              data.map((company) => {
                return (
                  <CompanyItem key={company.company.id} company={company} />
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </StatsCard>
  );
};
