import dayjs from "dayjs";

// Simulation constants
export const SIMULATION_SCRIBEHOW_REPORT_BUTTON_DEPLOYMENT_OFFICE: string =
  "https://scribehow.com/shared/Deploy_the_Oulook_Mantra_report_button__GfOn5w14RjWNdDHPjjrg-Q";
export const SIMULATION_SCRIBEHOW_REPORT_BUTTON_DEPLOYMENT_GMAIL: string =
  "https://scribehow.com/shared/Install_Mantra_Phishing_Report_Button_in_Google_Workspace__kY_H3dhESJGbY2T4CY8cCg";
export const SIMULATION_SCRIBEHOW_SETUP_MICROSOFT_SSO_API_SYNC: string =
  "https://scribehow.com/page-embed/Setup_For_Microsoft_Simulation_SSO_API_Sync__YYb0p6qZTPObuQnhE2gj8w";
export const SIMULATION_SCRIBEHOW_SETUP_GOOGLE_SSO_API_SYNC: string =
  "https://scribehow.com/page/Setup_for_Google_Simulation_SSO_API_Sync__xYsiB6buQRu_ZFuN9eF1Ug";
export const SIMULATION_SCRIBEHOW_ADD_COMPANY_SIGNATURE: string =
  "https://scribehow.com/shared/How_to_Add_a_Company_Signature__6dp_4Yd8SAeODm5lZacO8w";

// Awareness constants
export const AWARENESS_SCRIBEHOW_INSTALL_ALEX_BOT_TEAMS: string =
  "https://scribehow.com/shared/Install_Alex_Bot_on_Teams__PJCVKUY8SNmipPlbb0zv5Q";
export const AWARENESS_SCRIBEHOW_SETUP_TEAMS: string =
  "https://scribehow.com/page-embed/Awareness_Setup_Teams__bEY7pwTITLubuarJG74nlg";
export const AWARENESS_SCRIBEHOW_SETUP_SLACK: string =
  "https://scribehow.com/page-embed/Awareness_Setup_Slack__ODwVnGP6QVmqlMuSu0uAAg";

// Banners constants
export const BANNERS_SCRIBEHOW_ON_GOOGLE_URL: string =
  "https://scribehow.com/shared/Google_Allow_Mantra_to_display_Banners_to_your_users__9n8BRGLuRDiNPc-6GHzLtA";
export const BANNERS_SCRIBEHOW_ON_GOOGLE_GRANT_CONSENT_URL: string =
  "https://scribehow.com/shared/Google_Allow_Mantra_to_display_Banners_to_your_users__9n8BRGLuRDiNPc-6GHzLtA#155c4c59";
export const BANNERS_SCRIBEHOW_ON_OFFICE_URL: string =
  "https://scribehow.com/shared/Office_Allow_Mantra_to_display_Banners_to_your_users__5F1dEVMVQ6e2a9dNM6vmQA";

// Browser constants
type DeploymentPolicyUrls = {
  CHROMEINTUNE: string;
  EDGEINTUNE: string;
  CHROMEGPO: string;
  EDGEGPO: string;
  GWS: string;
  MANUAL: string;
};

type BrowserExtensionGeneration = {
  legacy: DeploymentPolicyUrls;
  enterprise: DeploymentPolicyUrls;
};

export const BROWSER_SCRIBEHOW_DEPLOYMENT_POLICIES: BrowserExtensionGeneration =
  {
    legacy: {
      CHROMEINTUNE:
        "https://scribehow.com/shared/ChromeIntune_Create_Browser_Defender_Deployment_Policy__ai2j6lxRRKKighdM3mgfkw",
      EDGEINTUNE:
        "https://scribehow.com/shared/EdgeIntune_Create_Browser_Defender_Deployment_Policy__O5hWvdbRTaO_6fgrzi-Hhw",
      CHROMEGPO:
        "https://scribehow.com/shared/ChromeGPO_Create_Browser_Defender_Deployment_Policy__GZhyz-pjQPmWymNt6sJxxQ",
      EDGEGPO:
        "https://scribehow.com/shared/EdgeGPO_Create_Browser_Defender_Deployment_Policy__XsudHyJZSLqGuMIZPL7kxw",
      GWS: "https://scribehow.com/shared/Create_Browser_Defender_Deployment_Policy__HY4yFajMTQmmGz68fM0Jdw",
      MANUAL:
        "https://scribehow.com/shared/Create_Browser_Defender_Deployment_Policy__HY4yFajMTQmmGz68fM0Jdw",
    },
    enterprise: {
      CHROMEINTUNE:
        "https://scribehow.com/shared/IntuneChromeEnterprise_Create_Browser_Defender_Deployment_Policy__xh0Q-WvjTf6VSYtlUDh12w",
      EDGEINTUNE:
        "https://scribehow.com/shared/IntuneEdgeEnterprise_Create_Browser_Defender_Deployment_Policy__Ln6IuQ3bRF2RBex0C6sS2Q",
      CHROMEGPO:
        "https://scribehow.com/shared/GPOChromeEnterprise_Create_Browser_Defender_Deployment_Policy__VLA93g5fQPKuFvF3AF-wzg",
      EDGEGPO:
        "https://scribehow.com/shared/GPOEdgeEnterprise_Create_Browser_Defender_Deployment_Policy__ZakeiFPxR9aWoId3_8-P8A",
      GWS: "https://scribehow.com/shared/Create_Browser_Defender_Deployment_Policy__HY4yFajMTQmmGz68fM0Jdw",
      MANUAL:
        "https://scribehow.com/shared/Create_Browser_Defender_Deployment_Policy__HY4yFajMTQmmGz68fM0Jdw",
    },
  };

// Mantra constants
export const MANTRA_WEBSITE_URL: string = "https://mantra.ms";
export const MANTRA_CREATION_DATE: dayjs.Dayjs = dayjs("2020-12-17");
export const MANTRA_DOMAINS: Array<string> = [
  "mantra.ms",
  "getplexx.com",
  "mantrams.onmicrosoft.com",
  "getplexx.onmicrosoft.com",
];
export const MANTRA_SCRIBEHOW_COMMON_ACTIONS: string =
  "https://scribehow.com/page-embed/Common_Actions__0rQKj4gjRQOASQ45d4FPDQ";
