import React, { useState } from "react";
import { TimeFrameSelector } from "@/components/controls/TimeFrameSelector";
import { BannerMailsWithBannerBarChart } from "@/pages/banners/dashboard/BannerMailsWithBannerChart";
import dayjs from "dayjs";
import MediumBarChart from "@/pages/banners/dashboard/MediumBarChart";
import { ButtonSpinner } from "@/components/Spinner";
import { useStatsQuery } from "@/pages/banners/remote-apis/bannerUsersApi";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import { BannerMailsProcessedAreaChart } from "@/pages/banners/dashboard/BannerMailsProcessedChart";
import { BasicStatCard } from "@/components/dashboard/BasicStatCard";
import { StatsCard } from "@/components/dashboard/StatsCard";
import { TitleBar } from "@/components/dashboard/TitleBar";

function FakeDemoGraphs({ banners_types }: { banners_types: object }) {
  return (
    <Grid item container spacing={2}>
      <Grid item md={6} xs={12}>
        <MediumBarChart
          data={banners_types["Company Impersonation"]}
          title={"Company Impersonation"}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <MediumBarChart
          data={banners_types["Employee Impersonation"]}
          title={"Employee Impersonation"}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <MediumBarChart
          data={banners_types["Contact Impersonation"]}
          title={"Contact Impersonation"}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <MediumBarChart
          data={banners_types["Brand Impersonation"]}
          title={"Brand Impersonation"}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <MediumBarChart
          data={banners_types["Contact Impersonation"]}
          title={"Suspicious attachments"}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <MediumBarChart
          data={banners_types["Suspicious File Transfer"]}
          title={"Suspicious file transfer"}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <MediumBarChart
          data={banners_types["Suspicious Financial Operation"]}
          title={"Suspicious financial operation"}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <MediumBarChart
          data={banners_types["Account Take-Over"]}
          title={"Account Take-Over"}
        />
      </Grid>
    </Grid>
  );
}

const formatNumber = (value) =>
  Intl.NumberFormat("en", { notation: "compact" }).format(value);

export function BannerDashboard() {
  const [period, setPeriod] = useState("P7D");
  const { t } = useTranslation();

  const endDate = dayjs().endOf("day");
  const startDate = endDate.subtract(dayjs.duration(period)).startOf("day");

  const { data, isError, isLoading, isFetching } = useStatsQuery({
    startDate: startDate.toDate(),
    endDate: endDate.toDate(),
  });

  const percentage = data?.messagesProcessed
    ? ((data?.messagesWithBanner / data?.messagesProcessed) * 100).toFixed(2) +
      " %"
    : "-";

  return (
    <>
      <TitleBar title={t("Smart Banners Dashboard")}>
        {isFetching && <ButtonSpinner />}
        <TimeFrameSelector value={period} onChange={setPeriod} withLastWeek />
      </TitleBar>

      <Grid container alignItems="stretch">
        <Grid item md={3} sm={6} xs={12}>
          <BasicStatCard
            icon={<></>}
            isLoading={isLoading}
            isError={isError}
            title={t("Mailboxes monitored")}
            data={formatNumber(data?.mailboxesMonitored)}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <BasicStatCard
            icon={<></>}
            isLoading={isLoading}
            isError={isError}
            title={t("Emails processed")}
            data={formatNumber(data?.messagesProcessed)}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <BasicStatCard
            icon={<></>}
            isLoading={isLoading}
            isError={isError}
            title={t("Banners added")}
            data={formatNumber(data?.messagesWithBanner)}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <BasicStatCard
            icon={<></>}
            isLoading={isLoading}
            isError={isError}
            title={t("Percentage of banners added")}
            data={percentage}
          />
        </Grid>

        <Grid item xs={12}>
          <StatsCard title={t("Emails processed")} isLoading={isLoading}>
            <BannerMailsProcessedAreaChart dataset={data?.dailyStatistics} />
          </StatsCard>
        </Grid>
        <Grid item xs={12}>
          <StatsCard title={t("Banners added")} isLoading={isLoading}>
            <BannerMailsWithBannerBarChart dataset={data?.dailyStatistics} />
          </StatsCard>
        </Grid>
        {!isLoading && data.bannerTypes && (
          <FakeDemoGraphs banners_types={data.bannerTypes} />
        )}
      </Grid>
    </>
  );
}
