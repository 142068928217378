import React, { PropsWithChildren, ReactElement } from "react";
import { Box, Card, CardContent, CardHeader, Stack } from "@mui/material";
import { Spinner } from "@/components/Spinner";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export type StatsCardProps = {
  title: string;
  isLoading?: boolean;
  link?: string;
  icon?: ReactElement;
  action?: ReactElement;
  isError?: any;
};

export const StatsCard = ({
  title,
  isLoading = false,
  link,
  icon = <></>,
  action,
  isError,
  children,
}: PropsWithChildren<StatsCardProps>) => {
  const { t } = useTranslation();

  const cardHeader = (
    <CardHeader avatar={icon} action={action} title={title}></CardHeader>
  );

  return (
    <Card sx={{ height: "100%" }}>
      {link ? (
        <Link style={{ color: "inherit" }} to={link}>
          {cardHeader}
        </Link>
      ) : (
        cardHeader
      )}
      <CardContent sx={{ px: 3, pt: 1, pb: 0 }}>
        <Stack spacing={2}>
          {isError && t("An error occurred")}

          {isLoading ? (
            <Box sx={{ marginBottom: "15px" }}>
              <Spinner />
            </Box>
          ) : (
            children
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};
