import * as React from "react";
import { styled } from "@mui/material/styles";

import { Box, Tab, Tabs } from "@mui/material";

function CustomTabPanel({ value, index, children }) {
  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanelHeader({
  tabsHeaders,
  tabsContents,
  setCurrentTab = (item) => {},
  defaultTab = null,
}) {
  if (!defaultTab) {
    defaultTab = 0;
  }
  const [value, setValue] = React.useState(defaultTab);
  const handleChange = (event, newValue) => {
    setCurrentTab(tabsHeaders[newValue].key);
    setValue(newValue);
  };

  const StyledTabs = styled(Tabs)(({ theme }) => ({
    "& .MuiTab-root": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.h5.fontWeight,
      fontSize: theme.typography.h5.fontSize,
      lineHeight: theme.typography.h5.lineHeight,
      textTransform: "none",
    },
    "& .MuiTab-root.Mui-selected": {
      color: theme.palette.text.primary,
    },
  }));

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          marginRight: "auto",
          display: "flex",
          alignItems: "center",
          maxWidth: "75%",
        }}
      >
        <StyledTabs value={value} onChange={handleChange}>
          {tabsHeaders.map((info, index) => {
            return <Tab label={info.label} key={index} {...a11yProps(index)} />;
          })}
        </StyledTabs>
        <Box
          sx={{
            marginLeft: "1.5rem",
            fontWeight: "500",
            fontSize: "0.9rem",
            fontStyle: "italic",
            overflowX: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            color: "rgb(169, 169, 169)",
            maxWidth: "60%",
          }}
        >
          {tabsHeaders[value].helper}
        </Box>
      </Box>
      {tabsContents.map((label, index) => {
        return (
          <CustomTabPanel value={value} index={index} key={index}>
            {label}
          </CustomTabPanel>
        );
      })}
    </Box>
  );
}

export default TabPanelHeader;
