import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportIcon from "@mui/icons-material/Report";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import React from "react";
import { T } from "@/components/LocalizedString";

export const labellingOptions = {
  noAction: {
    title: T("Don't %ACTION_LOWER% any alert"),
    description: T(
      "Label all events related to this domain and do not %ACTION_LOWER% any of them",
    ),
    apiAttribute: "no_action",
  },
  archiveSelected: {
    title: T("%ACTION_UPPER% selection"),
    description: T(
      "Label all events related to this domain and %ACTION_LOWER% %SELECTED_COUNT% selected alerts",
    ),
    apiAttribute: "%ACTION_LOWER%_selected",
  },
  archiveSimilarByUserAndDomain: {
    title: T("%ACTION_UPPER% related alerts for this user and domain"),
    description: T(
      "Label all events related to this domain and %ACTION_LOWER% %RELATED_COMBINED_COUNT% related domain and user alerts",
    ),
    apiAttribute: "%ACTION_LOWER%_similar_by_user_and_domain",
  },
  archiveSimilarByDomain: {
    title: T("%ACTION_UPPER% related alerts for domain"),
    description: T(
      "Label all events related to this domain and %ACTION_LOWER% %RELATED_DOMAIN_COUNT% related domain alerts",
    ),
    apiAttribute: "%ACTION_LOWER%_similar_by_domain",
  },
};

export const passwordMisuseDomainLabelButtonOptions = [
  {
    key: "reuse",
    value: {
      title: T("Credentials reuse"),
      description: T("Keep triggering alerts for domain(s) and ..."),
      icon: <ReportProblemIcon sx={{ color: "#F19949" }} />,
    },
  },
  {
    key: "safe",
    value: {
      title: T("Authorized domain"),
      description: T("Stop triggering alerts for domain(s) and ..."),
      icon: <CheckCircleIcon sx={{ color: "#35D9C0" }} />,
    },
  },
  {
    key: "blocked",
    value: {
      title: T("Blocked domain"),
      description: T("Block browsing on domain(s) and ..."),
      icon: <ReportIcon sx={{ color: "#F6285F" }} />,
    },
  },
];

export const mantraDomainLabelOptions = [
  {
    key: "potential_phishing",
    value: {
      title: T("Phishing attack"),
      description: "",
      icon: <AutoAwesomeIcon sx={{ color: "#F6285F" }} />,
    },
  },
  {
    key: "reuse",
    value: {
      title: T("Credentials reuse"),
      description: "",
      icon: <AutoStoriesIcon sx={{ color: "#030ffc" }} />,
    },
  },
];
