export enum MessagingPlatform {
  SLACK = "slack",
  TEAMS = "teams",
  WEBCHAT = "webchat",
}

export type AwarenessSetupChecklist = {
  messagingPlatform: MessagingPlatform | null;
  oneCourseAssigned: boolean;
  courseDeliveryActivated: boolean;
  messagingPlatformInstalled: boolean;
  isLoading: boolean;
};

export enum AwarenessStepperStateKey {
  MESSAGING_PLATFORM = 0,
  MESSAGING_PLATFORM_INSTALLED = 1,
  ONE_COURSE_ASSIGNED = 2,
  COURSE_DELIVERY_ACTIVATED = 3,
}
