import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React from "react";
import HelpCenterRoundedIcon from "@mui/icons-material/HelpCenterRounded";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import { useTheme } from "@mui/material";

type ThreatIcon = {
  disabled?: boolean;
};

export const ThreatSpamIcon = ({ disabled = false }: ThreatIcon) => {
  const theme = useTheme();
  return (
    <BlockOutlinedIcon
      htmlColor={
        disabled ? theme.palette.text.disabled : theme.palette.neutral.dark
      }
    />
  );
};

export const ThreatSafeIcon = ({ disabled = false }: ThreatIcon) => {
  const theme = useTheme();
  return (
    <CheckCircleIcon
      htmlColor={
        disabled ? theme.palette.text.disabled : theme.palette.success.light
      }
    />
  );
};

export const ThreatSuspiciousIcon = ({ disabled = false }: ThreatIcon) => {
  const theme = useTheme();
  return (
    <HelpCenterRoundedIcon
      htmlColor={
        disabled ? theme.palette.text.disabled : theme.palette.yellow.main
      }
    />
  );
};

export const ThreatUnsafeIcon = ({ disabled = false }: ThreatIcon) => {
  const theme = useTheme();
  return (
    <ReportProblemRoundedIcon
      htmlColor={
        disabled ? theme.palette.text.disabled : theme.palette.error.main
      }
    />
  );
};
