import React from "react";
import { Typography } from "@mui/material";
import { StatsCard, StatsCardProps } from "@/components/dashboard/StatsCard";

type BasicStatCardProps = {
  data: number | string;
} & StatsCardProps;

export const BasicStatCard = ({ data, ...props }: BasicStatCardProps) => {
  return (
    <StatsCard {...props}>
      <Typography variant="h3" sx={{ marginBottom: "15px" }}>
        {data}
      </Typography>
    </StatsCard>
  );
};
