import React, { useState } from "react";
import {
  ThreatPagination,
  ThreatTable,
} from "@/pages/threats/components/PaginatedTable";
import DetailPaneHeader from "@/pages/threats/components/DetailPaneHeader";
import Details from "@/pages/threats/components/details/Details";
import ThreatTabs from "@/pages/threats/components/ThreatTabs";
import { FilterTools } from "@/pages/threats/components/FilterTools";
import { SortTools } from "@/pages/threats/components/SortTools";
import { SearchField } from "@/components/SearchField";
import TableBody from "@mui/material/TableBody";
import ThreatRow from "@/pages/threats/components/ThreatRow";
import {
  ThreatsDetailsPane,
  ThreatsLayout,
  ThreatsListPane,
  ThreatsPaginationPane,
  ThreatsTitlePane,
  ThreatsToolPane,
} from "@/pages/threats/components/ThreatsLayout";
import { Column } from "@/components/sort/SelectOrderByComponent";
import { useThreatService } from "@/pages/threats/service/service";
import { ThreatSourceParams } from "@/pages/threats/service/types";
import { useTranslation } from "react-i18next";

const Threats = ({
  disableThreatsInBanners: disableBannersInThreats = false,
}) => {
  const {
    selectionList,
    pagination,
    listOptions,
    threats,
    totalCount,
    selectedThreats,
    unarchiveSelectedThreats,
    deleteSelectedThreats,
    sendFeedbackForSelectedThreats,
    search,
    onFilterChange,
    onSortChange,
    onTabChange,
    displayThreat,
    isFetching,
    detailsLoading,
    actDisplayThreat,
  } = useThreatService();

  const { t } = useTranslation();

  const [onArchivedTab, setOnArchivedTab] = useState(false);

  if (disableBannersInThreats) onTabChange({ source: "user", archived: false });

  const columns: Column[] = [
    { label: t("Report Date"), name: "report_date" },
    { label: t("Banner level"), name: "banner_level" },
    { label: t("Source"), name: "source" },
    { label: t("Sender"), name: "sender" },
    { label: t("Recipient"), name: "recipient" },
  ];

  return (
    <ThreatsLayout>
      <ThreatsTitlePane>
        <ThreatTabs
          disableBannersInThreats={disableBannersInThreats}
          onChange={(tab: ThreatSourceParams) => {
            onTabChange(tab);
            setOnArchivedTab(tab.archived);
          }}
        />
        <DetailPaneHeader
          areThreatsArchived={onArchivedTab}
          selectedThreats={selectedThreats}
          onDelete={deleteSelectedThreats}
          onUnarchive={unarchiveSelectedThreats}
          onSendFeedback={sendFeedbackForSelectedThreats}
        />
      </ThreatsTitlePane>
      <ThreatsToolPane>
        <SearchField
          value={listOptions.searchTerm}
          onChange={(e) => search(e.target.value)}
        />
        <FilterTools value={listOptions} onChange={onFilterChange} />
        <SortTools onChange={onSortChange} columns={columns} />
      </ThreatsToolPane>
      <ThreatsListPane>
        <ThreatTable data={threats} isLoading={isFetching}>
          <TableBody>
            {threats.map((threat) => (
              <ThreatRow
                key={threat.id}
                threat={threat}
                displayThreat={actDisplayThreat}
                isDisplayed={displayThreat?.id === threat.id}
                selectionList={selectionList}
              />
            ))}
          </TableBody>
        </ThreatTable>
      </ThreatsListPane>
      <ThreatsPaginationPane>
        <ThreatPagination pagination={pagination} totalCount={totalCount} />
      </ThreatsPaginationPane>
      <ThreatsDetailsPane>
        <Details isLoading={detailsLoading} threat={displayThreat} />
      </ThreatsDetailsPane>
    </ThreatsLayout>
  );
};

export default Threats;
