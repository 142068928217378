import React, { useEffect, useMemo } from "react";
import { LocalizationProvider as MuiLocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useUserContext } from "@/utils/contexts/UserContext";
import { useTranslation } from "react-i18next";

import "@/components/utils/dateSetup";
import { useSearchParams } from "react-router-dom";

const LocalizationProvider = ({ children }: { children: React.ReactNode }) => {
  const { language } = useUserContext();
  const { i18n } = useTranslation();
  const [searchParams] = useSearchParams();

  const appLanguage = useMemo(() => {
    if (searchParams.has("lang")) {
      return searchParams.get("lang");
    }
    return language;
  }, [language, searchParams]);

  useEffect(() => {
    dayjs.locale(appLanguage);
    (async () => {
      await i18n.changeLanguage(appLanguage);
    })();
  }, [appLanguage, i18n]);

  return (
    <MuiLocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={appLanguage}
    >
      {children}
    </MuiLocalizationProvider>
  );
};

export default LocalizationProvider;
