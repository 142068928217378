import { alpha, Chip, useTheme } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import React from "react";
import ThreatStatusIcon from "@/pages/threats/components/ThreatStatusIcon";
import MailboxText from "@/pages/threats/components/MailboxText";
import Box from "@mui/material/Box";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import {
  SelectionCheckbox,
  SelectionListType,
} from "@/components/SelectionCheckbox";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/system";
import { Threat } from "@/pages/threats/service/types";

const ThreatItem = ({ threat }) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const reportDate = threat.reportDate.toLocaleString(undefined, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

  const subjectColor = {
    null: theme.palette.primary.main,
    info: theme.palette.primary.main,
    suspicious: "orange",
    "very-suspicious": "red",
  }[threat.bannerLevel];

  const warningIcon =
    threat.bannerLevel === "suspicious" ||
    threat.bannerLevel === "very-suspicious" ? (
      <ReportProblemOutlinedIcon sx={{ marginRight: "10px" }} />
    ) : null;

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Stack sx={{ flex: 2 }}>
          <Box
            sx={{
              color: subjectColor,
              fontWeight: "bold",
              marginBottom: "5px",
            }}
          >
            {warningIcon} {threat.subject}
          </Box>
          <Box>
            <MailboxText label={t("From")} mailbox={threat.sender} />
          </Box>
          <Box>
            <MailboxText label={t("To")} mailbox={threat.receiver} />
          </Box>
        </Stack>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Box sx={{ paddingRight: "10px" }}>
              <AttachmentOutlinedIcon
                visibility={threat.hasAttachments ? "visible" : "hidden"}
              />
            </Box>
            <Box>{reportDate}</Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Stack direction="row" spacing={1}>
              {threat.bannerLevel !== null && <BannerChip />}
              {threat.reporter !== null && <UserSourceChip />}
            </Stack>
            <ThreatStatusIcon feedback={threat.feedback} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

type ThreatRowProps = {
  threat: Threat;
  displayThreat: (threatId: number) => void;
  isDisplayed: boolean;
  selectionList: SelectionListType;
};

const ThreatRow = ({
  threat,
  displayThreat,
  isDisplayed,
  selectionList,
}: ThreatRowProps) => {
  return (
    <TableRow
      hover
      sx={{
        "& .MuiTableCell-body": {
          fontSize: "1rem",
          borderBottom: 1,
          borderColor: "divider",
        },
      }}
      onClick={() => displayThreat(threat.id)}
      style={{
        background: isDisplayed
          ? "#e0e1ff"
          : selectionList.isSelected(threat.id)
            ? "#eff0ff"
            : "transparent",
      }}
    >
      <TableCell style={{ width: "10px", padding: 0 }}>
        <SelectionCheckbox
          color="primary"
          objectId={threat.id}
          selectionList={selectionList}
        />
      </TableCell>
      <TableCell component="th" scope="row" align="left">
        <ThreatItem threat={threat} />
      </TableCell>
    </TableRow>
  );
};

const UserSourceChip = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Chip
      variant="outlined"
      size={"small"}
      sx={{
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        fontWeight: "normal",
      }}
      label={t("User reported")}
    />
  );
};

const BannerChip = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Chip
      variant="outlined"
      size={"small"}
      sx={{
        backgroundColor: alpha(theme.palette.yellow.main, 0.1),
        borderColor: theme.palette.yellow.main,
        color: theme.palette.yellow.main,
        fontWeight: "normal",
      }}
      label={t("Banners")}
    />
  );
};

export default ThreatRow;
