import React, { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { debounce } from "lodash";
import DownloadIcon from "@mui/icons-material/CloudDownload";

import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useLearningResource,
  useSimulationResource,
} from "@/utils/ResourceGet";
import UserProgressRow from "@/pages/awareness/progress/UserProgressRow";
import {
  CourseHeader,
  CourseProgressTable,
  FilterOverlay,
  PageControls,
  SpinnerOverlay,
} from "@/pages/awareness/progress/TableComponents";
import { downloadUserProgressRawData } from "@/pages/awareness/utils";
import { useCourses } from "@/pages/awareness/hooks";
import { useSnackbar } from "material-ui-snackbar-provider";
import dayjs from "dayjs";
import { HeaderBox } from "@/utils/styled-components";
import { useUserContext } from "@/utils/contexts/UserContext";
import { ButtonSpinner } from "@/components/Spinner";
import { useTranslation } from "react-i18next";
import { T } from "@/components/LocalizedString";

export function CoursesProgress() {
  const { t } = useTranslation();

  const [isSubscribed, setSubscribed] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState({ users: [], page_count: 0 });
  const [courses, coursesError] = useCourses();
  const user = useUserContext();
  const [languages, languagesError] = useLearningResource("languages/", [
    "en",
    "fr",
  ]);
  const [departments, departmentsError] = useSimulationResource(
    "departments_from_user?with_id=True",
    [],
  );
  const all_status_options = [
    { label: t("Late"), value: "drop_off" },
    { label: t("On Track"), value: "on_track" },
    { label: t("Not Started"), value: "not_started" },
    { label: t("Not Set Up"), value: "not_set_up" },
  ];
  const { getAccessTokenSilently } = useAuth0();
  const snackbar = useSnackbar();
  const FILTERS = [
    "firstname",
    "lastname",
    "language",
    "department",
    "status",
    "min_completion",
    "max_completion",
  ];

  const FILTERS_LABELS = {
    firstname: T("firstname"),
    lastname: T("lastname"),
    language: T("language"),
    department: T("department"),
    status: T("status"),
    min_completion: T("min_completion"),
    max_completion: T("max_completion"),
  };

  const [activeFilters, setActiveFilters] = useState([]);
  const subscribedCourses = courses.filter((course) => course.is_subscribed);

  // to be able to perform two updates before fetching data (eg. update firstname + reset page to 1),
  // need to keep all request params in a single 'state object' https://stackoverflow.com/a/64326782/

  const [progressRequest, setProgressRequest] = useState({
    firstname: null,
    lastname: null,
    language: null,
    department_id: null,
    status: null,
    min_completion: null,
    max_completion: null,
    page: 1,
    order_by: "completion",
    direction: "desc",
  });

  const updateProgressRequest = useMemo(
    () =>
      function (updates) {
        setProgressRequest((prev) => ({ ...prev, ...updates, page: 1 }));
        setCurrentPage(1);
      },
    [setProgressRequest],
  );

  // cannot use progressRequest.page to "control" the input, because we want to debounce updates
  // made to progressRequest (let user type several digits of the page number before fetching)
  const [currentPage, setCurrentPage] = useState(1);

  const fetchProgressPage = useMemo(
    () => async (requestData) => {
      const accessToken = await getAccessTokenSilently();
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${import.meta.env.VITE_APP_ENDPOINT_LEARNING}/users/progress`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
            params: { ...requestData, company_id: user?.current_company.id },
          },
        );
        setProgress(response.data);
      } catch (error) {
        const message =
          error.response?.status === 403
            ? "Forbidden"
            : "An error occurred. Tech team has been notified.";
        snackbar.showMessage(message);
      } finally {
        setIsLoading(false);
      }
    },
    [getAccessTokenSilently, snackbar, user],
  );

  useEffect(() => {
    if (departmentsError) {
      snackbar.showMessage(
        "Failed to fetch departments. Tech team has been notified.",
      );
    }
  }, [departmentsError, snackbar]);

  useEffect(() => {
    if (coursesError) {
      snackbar.showMessage(
        "Failed to fetch courses. Tech team has been notified.",
      );
    }
  }, [coursesError, snackbar]);

  useEffect(() => {
    if (languagesError) {
      snackbar.showMessage(
        "Failed to fetch languages. Tech team has been notified.",
      );
    }
  }, [languagesError, snackbar]);

  useEffect(() => {
    // retain only non-null params (filter / order_by), then fetch data
    const request = {};
    Object.entries(progressRequest)
      .filter(([k, v]) => v)
      .forEach(([k, v]) => (request[k] = v));
    request["order_by"] = `${request["direction"] === "desc" ? "-" : ""}${
      request["order_by"]
    }`;
    delete request["direction"];
    fetchProgressPage(request);
  }, [fetchProgressPage, progressRequest]);

  function filterBy(paramName) {
    return debounce(
      (event) => updateProgressRequest({ [paramName]: event.target.value }),
      1000,
    );
  }

  function filterByLanguage(event, language) {
    updateProgressRequest({ language: language?.code });
  }

  function filterByDepartment(event, department) {
    updateProgressRequest({ department_id: department.id });
  }

  function filterByStatus(event, status) {
    updateProgressRequest({ status: status.value });
  }

  function decrementPage() {
    const newPage = Math.max(1, progressRequest.page - 1);
    setProgressRequest((prev) => ({ ...prev, page: newPage }));
    setCurrentPage(newPage);
  }

  function incrementPage() {
    const newPage = Math.min(progress.page_count, progressRequest.page + 1);
    setProgressRequest((prev) => ({ ...prev, page: newPage }));
    setCurrentPage(newPage);
  }

  const setRequestPage = useMemo(
    () =>
      debounce((newPage) => {
        setProgressRequest((prev) => {
          if (prev.page !== newPage) {
            return { ...prev, page: newPage };
          } else {
            return prev; // important to return the same object, to avoid triggering change detection
          }
        });
      }, 1000),
    [setProgressRequest],
  );

  useEffect(() => {
    setRequestPage(Number(currentPage) || 1);
  }, [setRequestPage, currentPage]);

  function orderBy(column) {
    setProgressRequest((prev) => {
      let direction;
      if (prev.order_by === column) {
        // toggle direction
        direction = prev.direction === "asc" ? "desc" : "asc";
      } else {
        direction = "asc";
      }
      return { ...prev, page: 1, order_by: column, direction: direction };
    });
    setCurrentPage(1);
  }

  function arrow(column) {
    if (progressRequest.order_by === column) {
      return progressRequest.direction === "asc" ? " ↓" : " ↑";
    } else {
      return "";
    }
  }

  function shouldHide(filter) {
    return activeFilters.indexOf(filter) < 0;
  }

  function addFilter(event) {
    let column = event.target.value;
    if (FILTERS.indexOf(column) >= 0 && activeFilters.indexOf(column) < 0) {
      setActiveFilters((prev) => [...prev, column]);
    }
  }

  const onPageNumberTyped = useMemo(
    () =>
      function (event) {
        let pageTyped = event?.target?.value;
        let correctedPage;
        if (pageTyped !== "") {
          correctedPage = Math.min(
            Math.max(1, Number(pageTyped) || 1),
            progress?.page_count || 1,
          );
        } else {
          correctedPage = ""; // allow empty input, to let user type digits other than 1 at the beginning
        }
        setCurrentPage(correctedPage);
      },
    [progress?.page_count],
  );

  const downloadCSV = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${
          import.meta.env.VITE_APP_ENDPOINT_LEARNING
        }/users/progress_in_csv/?company_id=${user?.current_company.id}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );
      downloadUserProgressRawData(
        `user_awareness_progress_${dayjs().format("YYYY-MM-DD_HH-mm-ss")}.csv`,
        response.data,
      );
    } catch (error) {
      const message =
        error.response?.status === 403
          ? t("Forbidden")
          : t("An error occurred. Tech team has been notified.");
      snackbar.showMessage(message);
    } finally {
      setIsLoading(false);
    }
  }, [t, snackbar, getAccessTokenSilently, user]);

  return (
    <Card>
      <CardHeader
        title={t("Users Progress")}
        action={
          <HeaderBox>
            <Button
              disabled={isLoading}
              variant={"outlined"}
              size={"small"}
              sx={{ marginRight: "5px" }}
              startIcon={<DownloadIcon />}
              onClick={downloadCSV}
            >
              {t("Download CSV")}
            </Button>
          </HeaderBox>
        }
      />

      <CardContent>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={isSubscribed}
                onChange={() => setSubscribed(!isSubscribed)}
              />
            }
            label={t("Display subscribed courses only")}
          />
        </FormGroup>

        {/* "position: relative" for SpinnerOverlay below */}
        <div style={{ position: "relative" }}>
          {/* "position: relative" for FilterOverlay below */}
          <div style={{ position: "relative", overflow: "scroll" }}>
            <CourseProgressTable className="table table-hover">
              <thead>
                <tr>
                  <th scope="col" style={{ width: 150, height: 140 }}>
                    <div
                      onClick={() => orderBy("firstname")}
                      style={{ cursor: "pointer" }}
                    >
                      {t("First Name")}
                      {arrow("firstname")}
                    </div>
                  </th>
                  <th scope="col" style={{ width: 120 }}>
                    <div
                      onClick={() => orderBy("lastname")}
                      style={{ cursor: "pointer" }}
                    >
                      {t("Last Name")}
                      {arrow("lastname")}
                    </div>
                  </th>
                  <th scope="col" style={{ width: 100, textAlign: "center" }}>
                    <div
                      onClick={() => orderBy("language")}
                      style={{ cursor: "pointer" }}
                    >
                      {t("Language")}
                      {arrow("language")}
                    </div>
                  </th>
                  <th scope="col" style={{ width: 120, textAlign: "center" }}>
                    <div
                      onClick={() => orderBy("departments")}
                      style={{ cursor: "pointer" }}
                    >
                      {t("Departments")}
                      {arrow("departments")}
                    </div>
                  </th>
                  <th scope="col" style={{ width: 140, textAlign: "center" }}>
                    <div
                      onClick={() => orderBy("completion")}
                      style={{ cursor: "pointer" }}
                    >
                      {t("Completion")}
                      {arrow("completion")}
                    </div>
                  </th>
                  <th scope="col" style={{ width: 120 }}>
                    <div
                      onClick={() => orderBy("status")}
                      style={{ cursor: "pointer" }}
                    >
                      {t("Status")}
                      {arrow("status")}
                    </div>
                  </th>
                  {(isSubscribed ? subscribedCourses : courses).map(
                    (course) => (
                      <CourseHeader key={course.id} scope="col">
                        <p title={course.label_name}>{course.label_name}</p>
                      </CourseHeader>
                    ),
                  )}
                </tr>
              </thead>
              <tbody>
                {progress.users.map((user) => (
                  <UserProgressRow
                    key={user.id}
                    user={user}
                    courses={isSubscribed ? subscribedCourses : courses}
                  />
                ))}
              </tbody>
            </CourseProgressTable>
            <FilterOverlay>
              <TextField
                onChange={filterBy("firstname")}
                label={t("First Name")}
                size="small"
                hidden={shouldHide("firstname")}
                style={{ width: 150, marginRight: "7px" }}
              />
              <TextField
                onChange={filterBy("lastname")}
                label={t("Last Name")}
                size="small"
                hidden={shouldHide("lastname")}
                style={{ width: 150, marginRight: "7px" }}
              />
              <Autocomplete
                options={languages}
                onChange={filterByLanguage}
                getOptionLabel={(option) => option.code}
                size="small"
                hidden={shouldHide("language")}
                style={{ width: 115, marginRight: "7px" }}
                renderInput={(params) => (
                  <TextField {...params} label={t("Language")} />
                )}
                loadingText={t("Loading") + "..."}
              />
              <Autocomplete
                options={departments}
                onChange={filterByDepartment}
                size="small"
                style={{ width: 240 }}
                hidden={shouldHide("department")}
                renderInput={(params) => (
                  <TextField {...params} label={t("Department")} />
                )}
                loadingText={t("Loading") + "..."}
              />
              <Autocomplete
                options={all_status_options}
                onChange={filterByStatus}
                size="small"
                style={{ width: 240 }}
                hidden={shouldHide("status")}
                renderInput={(params) => (
                  <TextField {...params} label={t("Status")} />
                )}
                loadingText={t("Loading") + "..."}
              />
              <TextField
                onChange={filterBy("min_completion")}
                label="Min"
                size="small"
                hidden={shouldHide("min_completion")}
                style={{ width: 50, marginLeft: "7px" }}
              />
              <TextField
                onChange={filterBy("max_completion")}
                label={t("Max")}
                size="small"
                hidden={shouldHide("max_completion")}
                style={{ width: 50, marginLeft: "7px" }}
              />
              {activeFilters.length < FILTERS.length && (
                <Select
                  value={"add-filter"}
                  variant="standard"
                  onChange={addFilter}
                  style={{ marginLeft: "7px" }}
                >
                  <MenuItem value="add-filter" disabled>
                    {t("Add filter")}
                  </MenuItem>
                  {FILTERS.filter((f) => activeFilters.indexOf(f) < 0).map(
                    (f) => (
                      <MenuItem key={f} value={f}>
                        {FILTERS_LABELS[f]}
                      </MenuItem>
                    ),
                  )}
                </Select>
              )}
            </FilterOverlay>
          </div>
          <SpinnerOverlay hidden={!isLoading}>
            <ButtonSpinner thickness={5} />
          </SpinnerOverlay>
        </div>
        <PageControls>
          <Button
            onClick={decrementPage}
            variant="outlined"
            style={{ fontSize: 14, marginRight: "10px" }}
            disabled={isLoading}
          >
            {"<"}
          </Button>
          <TextField
            onChange={onPageNumberTyped}
            value={currentPage}
            label="Page"
            size="small"
            style={{ width: 60 }}
          />
          <div style={{ marginLeft: "7px" }}>/ {progress.page_count}</div>
          <Button
            onClick={incrementPage}
            variant="outlined"
            style={{ fontSize: 14, marginLeft: "10px" }}
            disabled={isLoading}
          >
            {">"}
          </Button>
        </PageControls>
      </CardContent>
    </Card>
  );
}
