import React, { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useUserContext } from "@/utils/contexts/UserContext";
import { sentryCaptureException } from "@/utils/sentry";
import {
  Box,
  Chip,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import LaunchIcon from "@mui/icons-material/Launch";
import { Spinner } from "@/components/Spinner";
import { Link } from "react-router-dom";
import { BROWSER_SCRIBEHOW_DEPLOYMENT_POLICIES } from "@/utils/constants";
import { Trans, useTranslation } from "react-i18next";
import { CopyToClipboard } from "@/pages/browser-defender/components/CopyToClipboard";
import { BrowserDefenderGenerations } from "@/pages/browser-defender/config";

const MicrosoftDeployment = ({ enterpriseToken, extensionGeneration }) => {
  const { t } = useTranslation();

  function downloadScript(browser, extensionGeneration) {
    const extensionID =
      extensionGeneration === "legacy"
        ? BrowserDefenderGenerations.legacy
        : BrowserDefenderGenerations.enterprise;
    const browserDependantPath =
      browser === "edge" ? "Microsoft\\Edge" : "Google\\Chrome";
    let scriptContent = `
      $regPath = "HKCU:\\Software\\Policies\\${browserDependantPath}\\3rdparty\\extensions\\${extensionID}\\policy"
      $enterpriseToken = "${enterpriseToken}"

      # Ensure the registry path exists
      New-Item -Path $regPath -Force

      # Write the EnterpriseToken to the registry
      Set-ItemProperty -Path $regPath -Name "EnterpriseToken" -Value $enterpriseToken
    `;

    if (extensionGeneration !== "legacy") {
      scriptContent += `

      # Get the user email or UPN via whoami
      # If this does not work for you, please modify the script so that the $userDomainInfo variable contains the email address of the current user
      $userDomainInfo = whoami /upn
      

      # Write the UPN to the registry
      Set-ItemProperty -Path $regPath -Name "EnterpriseUserId" -Value $userDomainInfo
      `;
    }
    const blob = new Blob([scriptContent], { type: "text/plain" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `mantra_browser_defender_script_${browser}_${extensionGeneration}.ps1`;
    link.click();
  }

  return (
    <Box style={{ padding: "1rem" }}>
      <Divider textAlign="left">
        <Typography style={{ fontWeight: "bolder", fontSize: "1.1rem" }}>
          {t("Documentation")}
        </Typography>
      </Divider>

      <Stack
        direction="row"
        spacing={2}
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color="textSecondary" sx={{ mb: "1rem" }}>
          <Trans
            i18nKey="managedDeploymentBrowserDefender.deploymentDocumentation"
            values={{ browser: "Chrome", method: "Intune" }}
          >
            Chrome deployment via Intune
          </Trans>
        </Typography>
        <IconButton
          onClick={() =>
            window.open(
              BROWSER_SCRIBEHOW_DEPLOYMENT_POLICIES[extensionGeneration]
                .CHROMEINTUNE,
              "_blank",
            )
          }
          disabled={enterpriseToken ? false : true}
        >
          <LaunchIcon />
        </IconButton>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color="textSecondary" sx={{ mb: "1rem" }}>
          <Trans
            i18nKey="managedDeploymentBrowserDefender.deploymentDocumentation"
            values={{ browser: "Chrome", method: "GPO" }}
          >
            Chrome deployment via GPO
          </Trans>
        </Typography>
        <IconButton
          onClick={() =>
            window.open(
              BROWSER_SCRIBEHOW_DEPLOYMENT_POLICIES[extensionGeneration]
                .CHROMEGPO,
              "_blank",
            )
          }
          disabled={enterpriseToken ? false : true}
        >
          <LaunchIcon />
        </IconButton>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color="textSecondary" sx={{ mb: "1rem" }}>
          <Trans
            i18nKey="managedDeploymentBrowserDefender.deploymentDocumentation"
            values={{ browser: "Edge", method: "Intune" }}
          >
            Edge deployment via Intune
          </Trans>
        </Typography>
        <IconButton
          onClick={() =>
            window.open(
              BROWSER_SCRIBEHOW_DEPLOYMENT_POLICIES[extensionGeneration]
                .EDGEINTUNE,
              "_blank",
            )
          }
          disabled={enterpriseToken ? false : true}
        >
          <LaunchIcon />
        </IconButton>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color="textSecondary" sx={{ mb: "1rem" }}>
          <Trans
            i18nKey="managedDeploymentBrowserDefender.deploymentDocumentation"
            values={{ browser: "Edge", method: "GPO" }}
          >
            Edge deployment via GPO
          </Trans>
        </Typography>
        <IconButton
          onClick={() =>
            window.open(
              BROWSER_SCRIBEHOW_DEPLOYMENT_POLICIES[extensionGeneration]
                .EDGEGPO,
              "_blank",
            )
          }
          disabled={enterpriseToken ? false : true}
        >
          <LaunchIcon />
        </IconButton>
      </Stack>
      <Divider
        style={{ marginBottom: "1rem", marginTop: "1rem" }}
        textAlign="left"
      >
        <Typography style={{ fontWeight: "bolder", fontSize: "1.1rem" }}>
          {t("Scripts")}
        </Typography>
      </Divider>
      <Stack
        direction="row"
        spacing={2}
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color="textSecondary" sx={{ mb: "1rem" }}>
          <Trans
            i18nKey="managedDeploymentBrowserDefender.script"
            values={{ browser: "Chrome" }}
          >
            PowerShell script for Chrome
          </Trans>
        </Typography>
        <IconButton
          onClick={() => downloadScript("chrome", extensionGeneration)}
          disabled={enterpriseToken ? false : true}
        >
          <FileDownloadIcon />
        </IconButton>
      </Stack>

      <Stack
        direction="row"
        spacing={2}
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color="textSecondary" sx={{ mb: "1rem" }}>
          <Trans
            i18nKey="managedDeploymentBrowserDefender.script"
            values={{ browser: "Edge" }}
          >
            PowerShell script for Edge
          </Trans>
        </Typography>
        <IconButton
          onClick={() => downloadScript("edge", extensionGeneration)}
          disabled={enterpriseToken ? false : true}
        >
          <FileDownloadIcon />
        </IconButton>
      </Stack>
    </Box>
  );
};

const InternalCommunicationPerProvider = ({ links }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Stack
        direction={"row"}
        spacing={2}
        style={{
          marginTop: "1rem",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography color="textSecondary">{t("Auto setup")}:</Typography>
        <Link to={links.auto.fr} target="_blank" rel="noopener">
          <Chip label="FR" variant="outlined" />
        </Link>
        <Link to={links.auto.en} target="_blank" rel="noopener">
          <Chip label="EN" variant="outlined" />
        </Link>
      </Stack>
      <Stack
        direction={"row"}
        spacing={2}
        style={{
          marginTop: "1rem",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography color="textSecondary">{t("Manual setup")}:</Typography>
        <a href={links.manual.fr} target="_blank" rel="noreferrer">
          <Chip label="FR" variant="outlined" />
        </a>
        <a href={links.manual.en} target="_blank" rel="noreferrer">
          <Chip label="EN" variant="outlined" />
        </a>
      </Stack>
    </Box>
  );
};

const InternalCommunication = ({ idProvider, extensionGeneration }) => {
  const { t } = useTranslation();

  const msLinks = {
    legacy: {
      auto: {
        fr: "https://www.notion.so/mantrams/Browser-Defender-auto-setup-Microsoft-11fe2913b23480bf8daeeaa2716227c0",
        en: "https://www.notion.so/mantrams/Browser-Defender-auto-setup-Microsoft-11fe2913b2348038b5b4ec1534db5188",
      },
      manual: {
        fr: "https://www.notion.so/mantrams/Browser-Defender-manual-setup-Microsoft-11fe2913b234801f80e2f5c344e80e13",
        en: "https://www.notion.so/mantrams/Browser-Defender-manual-setup-Microsoft-11fe2913b2348073b2d8c56f6808fb3c",
      },
    },
    enterprise: {
      auto: {
        fr: "https://www.notion.so/mantrams/Browser-Defender-Enterprise-auto-setup-Microsoft-170e2913b23480cabbbdf5dec73c8402",
        en: "https://www.notion.so/mantrams/Browser-Defender-Enterprise-auto-setup-Microsoft-170e2913b2348064ad8dce1b84cd55a4",
      },
      manual: {
        fr: "https://www.notion.so/mantrams/Browser-Defender-Enterprise-manual-setup-Microsoft-170e2913b2348017ab53e4047cf90baf",
        en: "https://www.notion.so/mantrams/Browser-Defender-Enterprise-manual-setup-Microsoft-170e2913b23480388428f97bc840db60",
      },
    },
  };

  const gwsLinks = {
    legacy: {
      auto: {
        fr: "https://www.notion.so/mantrams/Browser-Defender-auto-setup-Google-Workspace-11fe2913b2348014933dfecfe175195c",
        en: "https://www.notion.so/mantrams/Browser-Defender-auto-setup-Google-Workspace-11fe2913b234801393bfdfda0f777dd8",
      },
      manual: {
        fr: "https://www.notion.so/mantrams/Browser-Defender-manual-setup-Google-Workspace-11fe2913b234804e9ecfd67a5e575044",
        en: "https://www.notion.so/mantrams/Browser-Defender-manual-setup-Google-Workspace-11fe2913b234803b8860f5c286e65ee8",
      },
    },
    enterprise: {
      auto: {
        fr: "https://www.notion.so/mantrams/Browser-Defender-Enterprise-auto-setup-Google-Workspace-170e2913b234805b9defe4ae15c577da",
        en: "https://www.notion.so/mantrams/Browser-Defender-Enterprise-auto-setup-Google-Workspace-170e2913b23480f4ba64c75abb31a888",
      },
      manual: {
        fr: "https://www.notion.so/mantrams/Browser-Defender-Enterprise-manual-setup-Google-Workspace-170e2913b234807ba960f2d408ed6274",
        en: "https://www.notion.so/mantrams/Browser-Defender-Enterprise-manual-setup-Google-Workspace-170e2913b23480899233c12c96c22a65",
      },
    },
  };

  return (
    <>
      <Divider
        textAlign="left"
        style={{ marginBottom: "1rem", marginTop: "3rem" }}
      >
        <Typography style={{ fontWeight: "bolder", fontSize: "1.1rem" }}>
          {t("Internal communication")}
        </Typography>
      </Divider>

      <Typography color="textSecondary">
        {t(
          "You can customize the following templates to communicate with your organization about the Browser Defender deployment.",
        )}
      </Typography>

      {idProvider === "M365" && (
        <InternalCommunicationPerProvider
          links={msLinks[extensionGeneration]}
        />
      )}
      {idProvider === "GWS" && (
        <InternalCommunicationPerProvider
          links={gwsLinks[extensionGeneration]}
        />
      )}
    </>
  );
};

const MicrosoftSetup = ({ enterpriseToken, extensionGeneration }) => {
  return (
    <>
      <MicrosoftDeployment
        enterpriseToken={enterpriseToken}
        extensionGeneration={extensionGeneration}
      />
      <InternalCommunication
        idProvider={"M365"}
        extensionGeneration={extensionGeneration}
      />
    </>
  );
};

const GoogleSetup = ({ enterpriseToken, extensionGeneration }) => {
  const { t } = useTranslation();

  const jsonContent = JSON.stringify({
    EnterpriseToken: {
      Value: enterpriseToken,
    },
  });

  return (
    <Box style={{ padding: "1rem" }}>
      <Divider textAlign="left">
        <Typography style={{ fontWeight: "bolder", fontSize: "1.1rem" }}>
          {t("Google Workspace")}
        </Typography>
      </Divider>

      <Stack
        direction="row"
        spacing={2}
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color="textSecondary" sx={{ mb: "1rem" }}>
          <Trans i18nKey="managedDeploymentBrowserDefender.deploymentDocumentationGWS">
            {"Documentation for Chrome deployment"}
          </Trans>
        </Typography>
        <IconButton
          onClick={() =>
            window.open(
              BROWSER_SCRIBEHOW_DEPLOYMENT_POLICIES[extensionGeneration].GWS,
              "_blank",
            )
          }
          disabled={enterpriseToken ? false : true}
        >
          <LaunchIcon />
        </IconButton>
      </Stack>
      <CopyToClipboard
        callToAction={
          <Trans i18nKey="managedDeploymentBrowserDefender.copyEnterprisePolicyToClipboard">
            {"Copy Enterprise Token policy to clipboard"}
          </Trans>
        }
        content={jsonContent}
      />
      <InternalCommunication
        idProvider={"GWS"}
        extensionGeneration={extensionGeneration}
      />
    </Box>
  );
};

export function ManagedDeployment() {
  const [config, setConfig] = useState({
    enterpriseToken: null,
    idProvider: null,
    extensionGeneration: null,
  });
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const { getAccessTokenSilently } = useAuth0();
  const info_user = useUserContext();

  const fetchConfig = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const url = `${
        import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
      }/setup/company`;

      let resource;

      try {
        resource = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } catch (e) {
        if (e.response.status === 400) {
          setConfig({
            enterpriseToken: null,
            idProvider: null,
            extensionGeneration: null,
          });
          setLoading(false);
          return;
        }
      }

      setConfig({
        enterpriseToken: resource.data.enterprise_token,
        idProvider: resource.data.id_provider_configuration,
        extensionGeneration: resource.data.extension_generation,
      });
      setLoading(false);
    } catch (e) {
      sentryCaptureException(e);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (!info_user.email) {
      return;
    }

    fetchConfig();
  }, [fetchConfig, info_user]);

  return (
    <>
      {loading && <Spinner />}

      {!loading && (
        <>
          <Typography variant="h5" style={{ marginBottom: "1rem" }}>
            {t("Documentation & Resources")}
          </Typography>
          {config.idProvider.provider === "M365" && (
            <MicrosoftSetup
              enterpriseToken={config.enterpriseToken}
              extensionGeneration={config.extensionGeneration}
            />
          )}
          {config.idProvider.provider === "GWS" && (
            <GoogleSetup
              enterpriseToken={config.enterpriseToken}
              extensionGeneration={config.extensionGeneration}
            />
          )}
        </>
      )}
    </>
  );
}
