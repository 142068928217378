import React, { Ref, forwardRef } from "react";
import {
  IconButton,
  InputAdornment,
  InputProps,
  TextField,
} from "@mui/material";
import { CalendarIcon } from "@mui/x-date-pickers";
import { DateRange } from "./DateRange";

const dateRangeToString = (dateRange: DateRange): string => {
  return dateRange.from.format("ll") + " - " + dateRange.to.format("ll");
};

const dateRangeTextFieldStyle = () => ({
  "& .MuiInputBase-root": {
    height: "25px",
    width: "200px",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    paddingRight: 0,
    cursor: "pointer",
  },
  "& .MuiInputBase-input": {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
    fontSize: "10px",
    cursor: "pointer",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderLeft: 0,
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderLeft: "1px solid black",
  },
  "&:hover .Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderLeft: "2px solid #7576fe",
  },
});

const DateRangeTextField = forwardRef<
  HTMLInputElement,
  InputProps & { value: DateRange }
>((props, ref: Ref<HTMLDivElement>) => (
  <TextField
    ref={ref}
    onClick={props.onClick}
    // @ts-ignore
    onChange={props.onClick}
    value={dateRangeToString(props.value)}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton>
            <CalendarIcon />
          </IconButton>
        </InputAdornment>
      ),
    }}
    sx={dateRangeTextFieldStyle()}
  />
));

DateRangeTextField.displayName = "DateRangeTextField";

export default DateRangeTextField;
