import React, { useEffect, useState } from "react";
import CoursesCatalogStyle from "../courses-catalog/CoursesCatalog.module.css";
import {
  selectCourses,
  selectCustomCourseBySearch,
  setCourses,
  setIsAdmin,
  setUserInfos,
} from "@/pages/awareness/courses/corporate-course-sharing/courseSharingSlice";
import { useDispatch, useSelector } from "react-redux";
import { ShareAllModal } from "@/pages/awareness/courses/corporate-course-sharing/ShareAllModal";
import StickyHeader from "@/components/StickyHeader";
import { CourseSharingHeaderFirstRow } from "@/pages/awareness/courses/corporate-course-sharing/CourseSharingHeaderFirstRow";
import { CourseSharingHeaderSecondRow } from "@/pages/awareness/courses/corporate-course-sharing/CourseSharingHeaderSecondRow";
import { CourseSharingCardsContainerList } from "@/pages/awareness/courses/corporate-course-sharing/CourseSharingCardsContainerList";
import {
  useGetCompanyCoursesQuery,
  useGetUserInfoQuery,
} from "./courseSharingApi";
import { AllowedSharingPolicy } from "@/pages/awareness/courses/utils/sharingPolicies";
import {
  useHasCorporateAdminRights,
  useHasCourseCatalogAdminRights,
} from "@/utils/misc";
import { useUserContext } from "@/utils/contexts/UserContext";
import { FullPageSpinner } from "@/components/Spinner";
import { useTranslation } from "react-i18next";

export const CourseSharing = () => {
  const user = useUserContext();
  const isCourseCatalogAdmin = useHasCourseCatalogAdminRights();
  const isCorporateAdmin = useHasCorporateAdminRights();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const courses = useSelector(selectCourses);
  const filteredCourses = useSelector(selectCustomCourseBySearch);
  const { t } = useTranslation();
  const {
    data: coursesData,
    isLoading: isCoursesLoading,
    error: coursesError,
  } = useGetCompanyCoursesQuery(user?.current_company?.id, {
    skip: !isCorporateAdmin || !user?.current_company?.id,
  });

  const {
    data: userInfoData,
    isLoading: isUserInfoLoading,
    error: userInfoError,
  } = useGetUserInfoQuery();

  const isLoading = isCoursesLoading || isUserInfoLoading;
  const error = coursesError || userInfoError;

  useEffect(() => {
    if (coursesData) {
      // @ts-ignore
      dispatch(setCourses(coursesData.courses_info));
    }
  }, [coursesData, dispatch]);

  useEffect(() => {
    if (userInfoData) {
      dispatch(setUserInfos(userInfoData));
      if (isCourseCatalogAdmin) {
        dispatch(setIsAdmin(true));
      }
    }
  }, [userInfoData, dispatch, isCourseCatalogAdmin]);

  const onClickShareAll = async () => {
    setOpenModal(true);
  };

  const handleUpdateSharingPolicy = (courseId, newPolicy) => {
    const updatedCourses = courses.map((course) =>
      course.id === courseId
        ? { ...course, sharing_policy: newPolicy }
        : course,
    );
    dispatch(setCourses(updatedCourses));
  };

  const onShareAll = () => {
    const updatedCourses = courses.map((course) => ({
      ...course,
      sharing_policy: AllowedSharingPolicy.COMPANIES_OF_CORPORATE,
    }));
    dispatch(setCourses(updatedCourses));
  };

  if (isLoading) {
    return <FullPageSpinner />;
  }

  if (error) {
    return <div>{t("Error: Unable to fetch courses")}</div>;
  }

  const belongsToCorporateGroup = user?.companies && user.companies.length > 1;

  if (isCorporateAdmin && !belongsToCorporateGroup) {
    return (
      <div className={CoursesCatalogStyle.noCorporateGroup}>
        <p>
          {t(
            "Your company does not belong to any corporate group. Course sharing is not available.",
          )}
        </p>
      </div>
    );
  }

  return (
    <>
      <StickyHeader height="120px">
        <CourseSharingHeaderFirstRow onClickShareAll={onClickShareAll} />
        <CourseSharingHeaderSecondRow />
      </StickyHeader>
      {filteredCourses.length > 0 && (
        <CourseSharingCardsContainerList
          courses={filteredCourses}
          learningUserInfos={userInfoData}
          onUpdateSharingPolicy={handleUpdateSharingPolicy}
        />
      )}
      <ShareAllModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        onShareAll={onShareAll}
      />
    </>
  );
};
