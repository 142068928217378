import React from "react";
import { Navigate } from "react-router-dom";
import { useUserContext } from "@/utils/contexts/UserContext";
import SplashScreen from "@/components/SplashScreen";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Permission } from "@/pages/general-settings/permissions-attribution/permissions";

type AuthenticatedComponentProps = {
  children: React.ReactNode;
  permissions?: Permission[];
};

export function AuthenticatedComponent({
  children,
  permissions,
}: AuthenticatedComponentProps) {
  const { is_authenticated: isUserReady, permissions: userPermission } =
    useUserContext();

  if (!isUserReady) {
    return <SplashScreen />;
  }
  if (
    permissions !== undefined &&
    !permissions.some((x) => userPermission.includes(x))
  ) {
    return <Navigate to="/my-progress" />;
  } else {
    return <>{children}</>;
  }
}

export default withAuthenticationRequired(AuthenticatedComponent, {
  onRedirecting: () => <SplashScreen />,
});
