import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export function ErrorPage404() {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h1">404</Typography>
      <Typography variant="h3">
        {t("OOPS! Something went wrong here")}
      </Typography>
    </>
  );
}
