import { createApi } from "@reduxjs/toolkit/query/react";
import { simulationBaseQuery } from "@/utils/rtkQuery";

interface User {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  departments: string[];
}

interface FetchUsersResponse {
  users: User[];
  total_count: number;
  total_pages: number;
}

export const usersApi = createApi({
  reducerPath: "usersApi",
  refetchOnMountOrArgChange: true,
  baseQuery: simulationBaseQuery(),
  endpoints: (builder) => ({
    fetchGeneralSettingsUsers: builder.query<
      FetchUsersResponse,
      {
        startIndex: number;
        pageSize: number;
        sort?: string;
        searchValue?: string;
        departmentId?: string;
        activeOnly?: boolean;
      }
    >({
      query: (queryData) => ({
        url: `/users`,
        method: "GET",
        params: {
          page_number:
            Math.floor(queryData.startIndex / queryData.pageSize) + 1,
          per_page: queryData.pageSize,
          sort: queryData.sort || "lastname",
          active_only: queryData.activeOnly,
          email_details: false,
          only_has_sent_messages: false,
          search: queryData.searchValue,
        },
      }),
    }),
  }),
});

export const { useFetchGeneralSettingsUsersQuery } = usersApi;
