import React, { Dispatch, SetStateAction } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Tooltip,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import {
  AuditData,
  PreviewData,
} from "@/pages/phishing-simulation/audit/Audit";
import { TemplateCatalog } from "@/pages/templates/TemplateCatalog";
import {
  TemplateCatalogContextProvider,
  TemplateCatalogContextType,
} from "@/pages/templates/TemplateCatalogContextProvider";
import {
  processLabel,
  processSaasLabel,
  TemplateType,
} from "@/pages/templates/templateUtils";
import {
  AudienceSelector,
  AudienceSelectionDepartment,
  AudienceSelectionUser,
  AudienceSelectionMode,
} from "@/components/AudienceSelector";
import { useTranslation } from "react-i18next";

export enum AuditStep {
  DEFINE = "DEFINE",
  PREVIEW = "PREVIEW",
  NEWLY_VALIDATED = "NEWLY_VALIDATED",
  ALREADY_VALIDATED = "ALREADY_VALIDATED",
}

interface RenderStepDefineProps {
  auditData: AuditData;
  handleDateChange: (
    field: "start_date" | "end_date",
  ) => (date: Dayjs | null) => void;
  handleSimulationsChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handlePreviewAudit: () => void;
  isStartDateValid: boolean;
  isDateRangeValid: boolean;
  isFormValid: boolean;
  isTemplateSelectionOpen: boolean;
  setIsTemplateSelectionOpen: Dispatch<SetStateAction<boolean>>;
  templateCatalogContext: TemplateCatalogContextType;
  audienceSelectionMode: AudienceSelectionMode;
  setAudienceSelectionMode: Dispatch<SetStateAction<AudienceSelectionMode>>;
  setSelectedUsers: Dispatch<SetStateAction<AudienceSelectionUser[]>>;
  setSelectedDepartments: Dispatch<
    SetStateAction<AudienceSelectionDepartment[]>
  >;
  userList: AudienceSelectionUser[];
  fetchUsers: any;
}

interface RenderStepPreviewProps {
  previewData: PreviewData[];
  handleCancel: () => void;
  handleValidateAudit: () => void;
}

interface RenderStepValidatedProps {
  step: AuditStep.NEWLY_VALIDATED | AuditStep.ALREADY_VALIDATED;
  auditData: AuditData;
  handleCancel: () => void;
  handleViewResults: () => void;
}

export function StepDefine({
  auditData,
  handleDateChange,
  handleSimulationsChange,
  handlePreviewAudit,
  isStartDateValid,
  isDateRangeValid,
  isFormValid,
  isTemplateSelectionOpen,
  setIsTemplateSelectionOpen,
  templateCatalogContext,
  audienceSelectionMode,
  setAudienceSelectionMode,
  setSelectedUsers,
  setSelectedDepartments,
  userList,
  fetchUsers,
}: RenderStepDefineProps) {
  const { t } = useTranslation();

  return (
    <Stack spacing={3}>
      <Box sx={{ maxWidth: "300px" }}>
        <Typography variant={"h5"}>{t("Send campaign to")}</Typography>
        <AudienceSelector
          audienceSelectionMode={audienceSelectionMode}
          setAudienceSelectionMode={setAudienceSelectionMode}
          setSelectedUsers={setSelectedUsers}
          setSelectedDepartments={setSelectedDepartments}
          userList={userList}
          fetchUsers={fetchUsers}
        />
      </Box>
      <Stack direction={"row"} sx={{ alignItems: "center" }}>
        <Typography variant={"h5"}>{t("Templates")}</Typography>
        <Button
          onClick={() => setIsTemplateSelectionOpen(true)}
          sx={{ cursor: "pointer" }}
          size={"small"}
        >
          {"+ " + t("Add templates")}
        </Button>
      </Stack>
      <Box>
        {templateCatalogContext.selectedTemplates.map(
          (template: TemplateType, index: number) => (
            <Stack direction={"row"} key={index}>
              <Typography
                onClick={() => templateCatalogContext.selectTemplate(template)}
                sx={{ cursor: "pointer", userSelect: "none" }}
                color={"#ADABB8"}
                fontWeight={"bold"}
              >
                {"x"}
              </Typography>
              <Typography color={"#2B17A3"}>
                <strong>{processSaasLabel(template.saas)}</strong>{" "}
                {processLabel(template.name)}
              </Typography>
            </Stack>
          ),
        )}
        {templateCatalogContext.selectedTemplates.length === 0 && (
          <Typography>{t("No templates selected")}</Typography>
        )}
      </Box>
      <DatePicker
        label={t("Start Date")}
        value={auditData.start_date}
        onChange={handleDateChange("start_date")}
        format="YYYY/MM/DD"
        slotProps={{
          textField: {
            helperText: isStartDateValid
              ? t("When the first simulation can be sent to a user")
              : t("Start date must be in the future"),
            fullWidth: true,
            error: !isStartDateValid,
            sx: { width: "300px" },
          },
        }}
      />
      <DatePicker
        label={t("End Date")}
        value={auditData.end_date}
        onChange={handleDateChange("end_date")}
        format="YYYY/MM/DD"
        slotProps={{
          textField: {
            helperText: isDateRangeValid
              ? t("When the last simulation can be sent to a user")
              : t("End date must be greater than or equal to start date"),
            fullWidth: true,
            error: !isDateRangeValid,
            sx: { width: "300px" },
          },
        }}
      />
      <TextField
        label={t("Simulation(s) per user")}
        type="number"
        value={auditData.simulations_per_user}
        onChange={handleSimulationsChange}
        fullWidth
        helperText={t("The number of simulations each user will receive")}
        inputProps={{ min: 1 }}
        sx={{ width: "300px" }}
      />
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          onClick={handlePreviewAudit}
          disabled={!isFormValid}
        >
          {t("Preview audit")}
        </Button>
      </Box>
      <Dialog
        open={isTemplateSelectionOpen}
        onClose={() => setIsTemplateSelectionOpen(false)}
        fullWidth={true}
        maxWidth={"xl"}
      >
        <DialogContent>
          <Box sx={{ height: "60%" }}>
            <TemplateCatalogContextProvider context={templateCatalogContext}>
              <TemplateCatalog />
            </TemplateCatalogContextProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant={"contained"}
            color={"primary"}
            sx={{ margin: "20px" }}
            size={"large"}
            onClick={() => setIsTemplateSelectionOpen(false)}
          >
            {t("Done")}
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}

export function StepPreview({
  previewData,
  handleCancel,
  handleValidateAudit,
}: RenderStepPreviewProps) {
  const { t } = useTranslation();

  return (
    <Stack
      spacing={3}
      sx={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      <TableContainer sx={{ flexGrow: 1, overflow: "auto" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{t("Recipient")}</TableCell>
              <TableCell>{t("Template title")}</TableCell>
              <TableCell>{t("Tag")}</TableCell>
              <TableCell>{t("Language")}</TableCell>
              <TableCell>{t("Payload")}</TableCell>
              <TableCell>{t("Sending date")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {previewData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.recipient}</TableCell>
                <TableCell>{row.template_title}</TableCell>
                <TableCell>{row.tag}</TableCell>
                <TableCell>{row.language}</TableCell>
                <TableCell>{row.payload}</TableCell>
                <TableCell>{row.sending_date}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {previewData.length === 0 && (
        <Typography color="error" align="center">
          {t("Error: No simulations could be scheduled.")}
          <br />
          {t(
            "Please make sure you selected at least one active user as a recipient and at least one template to send.",
          )}
        </Typography>
      )}
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Button variant="outlined" onClick={handleCancel}>
          {t("Change parameters")}
        </Button>
        <Tooltip
          title={
            previewData.length === 0
              ? t("Cannot schedule an empty trial run")
              : ""
          }
        >
          <span>
            <Button
              variant="contained"
              color="primary"
              onClick={handleValidateAudit}
              disabled={previewData.length === 0}
            >
              {t("Schedule audit")}
            </Button>
          </span>
        </Tooltip>
      </Box>
    </Stack>
  );
}

export function StepValidated({
  step,
  auditData,
  handleCancel,
  handleViewResults,
}: RenderStepValidatedProps) {
  const { t } = useTranslation();
  return (
    <Paper
      elevation={3}
      sx={{ p: 4, mt: 4, width: "100%", maxWidth: 600, mx: "auto" }}
    >
      <Stack spacing={4} alignItems="center">
        <Typography variant="h4" align="center">
          {step === AuditStep.NEWLY_VALIDATED
            ? t("Audit successfully planned!")
            : t(
                "Audit is planned, come back on start date ({{date}}) to see the first results.",
                { date: auditData.start_date?.format("YYYY-MM-DD") },
              )}
        </Typography>
        <Box display="flex" justifyContent="space-between" width="100%" mt={4}>
          <Button variant="contained" color="error" onClick={handleCancel}>
            {t("Cancel audit")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleViewResults}
            disabled={step === AuditStep.ALREADY_VALIDATED}
          >
            {t("View results")}
          </Button>
        </Box>
      </Stack>
    </Paper>
  );
}
