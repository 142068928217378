import { ThreatCommentDialog } from "./ThreatCommentDialog";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import StickyHeader from "@/components/StickyHeader";
import {
  Button,
  Checkbox,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { FeedbackButtons } from "./FeedbackButtons";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { debounce } from "lodash";
import { ButtonSpinner } from "@/components/Spinner";
import { ConfirmationDialog } from "@/components/modals/ConfirmationDialog";
import dayjs from "dayjs";
import { threatColumns } from "./utils";
import CloudDownloadButton from "@/components/buttons/cloudDownloadButton";
import SortComponent from "@/components/sort/SortComponent";
import useDownloadThreatsAsCSV from "./useDownloadThreatsAsCsv";
import { useTranslation } from "react-i18next";
import { OrderBy } from "@/components/sort/SelectOrderByComponent";

type ThreatStickyHeaderProps = {
  isArchiveDisplayed: boolean;
  selectedThreats: number[];
  onSearch: (term: string) => void;
  updateSelectedThreats: (action: ThreatState | "delete") => void;
  isUpdatingSelectedThreats: boolean;
  threats: Threat[];
  onSort: (sortColumn: OrderBy[]) => void;
  numPages: number;
};

export default function ThreatStickyHeader({
  isArchiveDisplayed,
  selectedThreats,
  onSearch,
  updateSelectedThreats,
  isUpdatingSelectedThreats,
  threats,
  onSort,
  numPages,
}: ThreatStickyHeaderProps) {
  const { t } = useTranslation();

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const selectedThreatsCount = selectedThreats.length;
  const [shouldAddComment, setShouldAddComment] = useState(false);
  const [showCommentDialog, setShowCommentDialog] = useState(false);
  const [action, setAction] = useState("spam");
  const { downloadAsCsv, isLoading: isDownloadAsCSVLoading } =
    useDownloadThreatsAsCSV({ archived: isArchiveDisplayed, numPages });
  const updateShouldAddComment = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setShouldAddComment(event.target.checked);
  };

  const onClickCategoriseThreat = (action: ThreatState | "delete") => {
    if (shouldAddComment) {
      setShowCommentDialog(true);
      setAction(action);
    } else {
      updateSelectedThreats(action);
    }
  };

  const debouncedUpdate = useMemo(
    () =>
      debounce((term: string) => {
        onSearch(term);
        setIsSearching(false);
      }, 250),
    [onSearch, setIsSearching],
  );

  /*
   * Hide popup on delete
   */
  useEffect(() => {
    setShowDeletePopup(false);
  }, [selectedThreats]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsSearching(true);
    const value = e.target.value;
    debouncedUpdate(value);
  };

  const headerVisibility =
    selectedThreatsCount > 0 && !isArchiveDisplayed ? "visible" : "hidden";

  return (
    <StickyHeader>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            gap: "5px",
            alignItems: "center",
            width: "100%",
            height: "35px",
          }}
        >
          <div
            style={{
              display: "flex",
              marginRight: "auto",
              alignItems: "center",
              flex: "0 0 auto",
              gap: ".5rem",
            }}
          >
            <TextField
              InputProps={{
                startAdornment: <SearchIcon />,
                endAdornment: isSearching ? <ButtonSpinner /> : null,
              }}
              size={"small"}
              sx={{
                maxWidth: "150px",
                minWidth: "50px",
              }}
              label={t("Search")}
              onChange={onChange}
            />
            <SortComponent setOrderBy={onSort} orderChoices={threatColumns} />
            {isDownloadAsCSVLoading ? (
              <ButtonSpinner style={{ margin: "0 10px" }} />
            ) : (
              <CloudDownloadButton
                title="Export to CSV"
                onClick={downloadAsCsv}
                disabled={isDownloadAsCSVLoading}
              />
            )}
            <Typography
              style={{
                color: "black",
              }}
              noWrap
            >
              {t("Add comment")}
            </Typography>
            <Checkbox
              onChange={updateShouldAddComment}
              inputProps={{ "aria-label": "controlled" }}
              checked={shouldAddComment}
              sx={{
                padding: 0,
              }}
            />
            <ThreatCommentDialog
              setOpenCommentDialog={setShowCommentDialog}
              openCommentDialog={showCommentDialog}
              updateSelectedThreats={updateSelectedThreats}
              selectedThreatsCount={selectedThreatsCount}
              action={action}
            />
          </div>
          {isUpdatingSelectedThreats ? <ButtonSpinner /> : null}
          <Typography
            style={{
              minWidth: "80px",
              color: "black",
              visibility: headerVisibility,
            }}
            noWrap
          >
            {t("Mark")}"{" "}
            <span style={{ fontWeight: 600 }}>{selectedThreatsCount}</span>{" "}
            email{selectedThreatsCount > 1 ? "s" : ""} as :
          </Typography>
          {!isArchiveDisplayed ? (
            <>
              <FeedbackButtons
                updateThreatsStatuses={onClickCategoriseThreat}
                disabled={
                  selectedThreatsCount <= 0 || isUpdatingSelectedThreats
                }
              />
              <Divider
                orientation="vertical"
                style={{ width: "1px", background: "rgba(0, 0, 0, 0.12)" }}
                flexItem
              />
            </>
          ) : (
            <Button
              variant="outlined"
              color="neutral"
              sx={{ flex: "0 0 auto", marginRight: "10px" }}
              onClick={() => updateSelectedThreats("unknown")}
              startIcon={<UnarchiveIcon />}
              disabled={selectedThreatsCount <= 0 || isUpdatingSelectedThreats}
              title="Unarchive threat"
            >
              Unarchive
            </Button>
          )}
          <Button
            variant="outlined"
            color="neutral"
            sx={{ flex: "0 0 auto" }}
            onClick={() => setShowDeletePopup(true)}
            startIcon={<DeleteOutlineIcon />}
            disabled={selectedThreatsCount <= 0 || isUpdatingSelectedThreats}
            title={t("delete one or more threat")}
          >
            Delete
          </Button>
          <ConfirmationDialog
            isShown={showDeletePopup}
            onConfirm={() => updateSelectedThreats("delete")}
            isLoading={isUpdatingSelectedThreats}
            disabled={isUpdatingSelectedThreats}
            onConfirmText="Delete"
            onCancel={() => setShowDeletePopup(false)}
            onCancelText="Cancel"
            title={
              selectedThreatsCount > 1 ? (
                <span>
                  Do you really want to delete those{" "}
                  <b>{selectedThreatsCount}</b> threats ?
                </span>
              ) : (
                <div>Do you really want to delete this threat ?</div>
              )
            }
          >
            <div
              style={{
                overflowY: "scroll",
                maxHeight: "100px",
                maxWidth: "500px",
              }}
            >
              {threats
                .filter((el) => selectedThreats.includes(el.id))
                .map((el) => (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                    key={el.subject + el.report_date + el.id}
                  >
                    <Typography
                      sx={{
                        color: "#5012FF",
                        fontWeight: 500,
                        maxWidth: "225px",
                      }}
                      noWrap
                    >
                      {el.subject}
                    </Typography>
                    <div style={{ fontWeight: "400" }}>
                      {dayjs(el.report_date).format("DD-MM-YYYY")}
                    </div>
                  </div>
                ))}
            </div>
          </ConfirmationDialog>
        </div>
      </div>
    </StickyHeader>
  );
}
