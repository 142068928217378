import React from "react";
import { Button } from "@mui/material";
import { useDownloadUsers } from "@/pages/general-settings/UsersManagement/utils";
import { useTranslation } from "react-i18next";

export function ExportButton() {
  const { startDownload } = useDownloadUsers();
  const { t } = useTranslation();
  return (
    <div>
      <Button variant="contained" onClick={() => startDownload()}>
        {t("Export Users")}
      </Button>
    </div>
  );
}
