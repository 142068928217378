import dayjs from "dayjs";
import { cloneDeep } from "lodash";
import { Column, OrderBy } from "@/components/sort/SelectOrderByComponent";

export function threatsToCSV(threats: Threat[]): string {
  if (threats.length < 1) {
    return;
  }
  const formattedThreats = cloneDeep(threats).map((el: Threat) => {
    delete el.id;
    delete el.headers;
    return el;
  });
  const csvHeaders = Object.keys(formattedThreats[0]);
  const data = formattedThreats
    .map((el: Omit<Threat, "id">) => {
      return Object.values(el)
        .map((col) => {
          if (col == null) {
            return "";
          }
          return col.toString().replace(";", ",");
        })
        .join(";");
    })
    .join("\n");
  return csvHeaders.join(";") + "\n" + data;
}

export function downloadThreatCSV(threats: Threat[]) {
  const csv = threatsToCSV(threats);
  let el = document.createElement("a");
  el.setAttribute(
    "href",
    "data:text/csv;charset=utf-8," + encodeURIComponent(csv),
  );
  el.setAttribute("download", `threats-${dayjs().format("DD-MM-YYYY")}.csv`);
  el.style.display = "none";
  document.body.appendChild(el);
  el.click();
  document.body.removeChild(el);
}

export function adaptSortToDjangoORM(sortArray: SortColumn[]): string[] {
  return sortArray.map((el) => {
    if (el.order === "desc") {
      return "-" + el.key;
    }
    return el.key;
  });
}

export function adaptSortComponentToThreat(sortArray: OrderBy[]): SortColumn[] {
  const sortColumn: SortColumn[] = [];
  for (let sort of sortArray) {
    if (sort?.column?.name === null) {
      continue;
    }
    sortColumn.push({
      key: sort.column.name,
      order: sort.order,
    });
  }
  return sortColumn;
}

export const threatColumns: Column[] = [
  { label: "Subject", name: "subject" },
  { label: "From email", name: "from_email" },
  { label: "From name", name: "from_name" },
  { label: "Report date", name: "report_date" },
  { label: "To email", name: "to_user__email" },
  { label: "Category", name: "feedback" },
];
