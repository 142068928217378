import React from "react";
import { Navigate } from "react-router-dom";
import { useUserContext } from "@/utils/contexts/UserContext";
import { useTranslation } from "react-i18next";

export function HomeRedirect() {
  const { t } = useTranslation();
  const { is_admin } = useUserContext();
  if (is_admin !== null) {
    return (
      <Navigate
        to={is_admin ? "/phishing-simulation/dashboard" : "/my-progress"}
      />
    );
  } else {
    return <div>{t("Waiting for admin rights")}</div>;
  }
}
