import React, { PropsWithChildren } from "react";
import TablePagination from "@mui/material/TablePagination";
import { FullPageSpinner } from "@/components/Spinner";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { PaginationData } from "@/utils/pagination";

type ThreatTableProps = {
  isLoading: boolean;
  data: any[];
};

export const ThreatTable = ({
  isLoading,
  data,
  children,
}: PropsWithChildren<ThreatTableProps>) => {
  const { t } = useTranslation();
  return (
    <>
      {isLoading ? (
        <FullPageSpinner />
      ) : (
        <Box sx={{ flex: 1 }}>
          <TableContainer component={Paper}>
            <Table>
              {data.length < 1 ? (
                <TableBody>
                  <TableRow>
                    <TableCell>{t("No data available")}</TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                children
              )}
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
};

type ThreatPaginationProps = {
  pagination: PaginationData;
  totalCount: number;
};

export const ThreatPagination = ({
  pagination,
  totalCount,
}: ThreatPaginationProps) => {
  return (
    <TablePagination
      rowsPerPageOptions={[10, 25, 50]}
      component="div"
      count={totalCount}
      rowsPerPage={pagination.rowsPerPage}
      page={pagination.currentPage}
      onPageChange={(_, newPageIndex) => {
        pagination.changePage(newPageIndex);
      }}
      onRowsPerPageChange={(event) => {
        const value = parseInt(event.target.value, 10);
        pagination.changePageSize(value);
      }}
    />
  );
};
