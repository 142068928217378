import { createApi } from "@reduxjs/toolkit/query/react";
import { learningBaseQuery } from "@/utils/rtkQuery";

interface LearningApiDepartment {
  id: number;
  name: string;
}

export const learningApi = createApi({
  reducerPath: "learning-api",
  baseQuery: learningBaseQuery(),
  endpoints: (builder) => ({
    searchByEmail: builder.query<string[], string>({
      query: (search_string) => ({
        url: "/users/search_by_email",
        method: "GET",
        params: { email: search_string },
      }),
    }),
    departments: builder.query<LearningApiDepartment[], void>({
      query: () => ({
        url: "/departments",
        method: "GET",
      }),
    }),
  }),
});

export const { useSearchByEmailQuery, useDepartmentsQuery } = learningApi;
