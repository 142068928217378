import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { sentryCaptureException } from "@/utils/sentry";
import { useUserContext } from "@/utils/contexts/UserContext";
import {
  Checkbox,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Spinner } from "@/components/Spinner";
import { useTranslation } from "react-i18next";

export default function CompanyConfigurationForm({
  record = null,
  formType = "company",
  groups = null,
}) {
  const { t } = useTranslation();
  const [userCanDisableExtension, setUserCanDisableExtension] = React.useState(
    record ? record.user_can_disable_extension : false,
  );

  const protectionTypes = [
    {
      key: "flexible",
      label: t("Prevent with bypass"),
      description: {
        password: t("User can manage their own trusted domains list"),
        browsing: t("User can navigate to page despite alerts"),
        download: t("User can proceed with blocked file download"),
      },
    },
    {
      key: "strict",
      label: t("Prevent"),
      description: {
        password: t("User cannot disable password alerts on any domain"),
        browsing: t("User cannot navigate to page in case of alert"),
        download: t("User cannot proceed with blocked file download"),
      },
    },
    {
      key: "off",
      label: t("Off"),
      description: {
        password: t("Feature disabled"),
        browsing: t("Feature disabled"),
        download: t("Feature disabled"),
      },
    },
  ];

  const [downloadsPolicies, setDownloadsPolicies] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const { getAccessTokenSilently } = useAuth0();
  const info_user = useUserContext();

  const fetchDownloadsPolicies = React.useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const url = `${
        import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
      }/settings/downloads_policies`;
      let resource;
      try {
        resource = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } catch (e) {
        if (e.response.status === 400) {
          return;
        }
      }

      setDownloadsPolicies(resource.data);
      setLoading(false);
    } catch (e) {
      sentryCaptureException(e);
    }
  }, [getAccessTokenSilently]);

  React.useEffect(() => {
    if (!info_user.email) {
      return;
    }

    fetchDownloadsPolicies();
  }, [fetchDownloadsPolicies, info_user]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Table>
          <TableBody>
            {groups && (
              <TableRow>
                <TableCell style={{ align: "left", width: "40%" }}>
                  {t("Group Name")}
                </TableCell>
                <TableCell style={{ align: "left" }}>
                  <Select
                    style={{ width: "100%" }}
                    name="group_id"
                    size="small"
                    defaultValue={""}
                  >
                    {groups.map((item) => (
                      <MenuItem key={item.group_id} value={item.group_id}>
                        {item.group_name}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell style={{ align: "left", width: "40%" }}>
                {t("Password Protection")}
              </TableCell>
              <TableCell style={{ align: "left" }}>
                <Select
                  style={{ width: "100%" }}
                  name="password_protection"
                  defaultValue={record ? record.password_protection : ""}
                  size="small"
                  renderValue={(selected) => {
                    const selectedItem = protectionTypes.find(
                      (item) => item.key === selected,
                    );
                    return selectedItem ? selectedItem.label : "";
                  }}
                >
                  {protectionTypes.map((item) => (
                    <MenuItem key={item.key} value={item.key}>
                      <div>
                        <Typography>{item.label}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {item.description["password"]}
                        </Typography>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ align: "left", verticalAlign: "top" }}>
                {t("Downloads Protection")}
              </TableCell>
              <TableCell style={{ align: "left" }}>
                <Select
                  style={{ width: "100%" }}
                  name="downloads_protection"
                  defaultValue={record ? record.downloads_protection : ""}
                  size="small"
                  renderValue={(selected) => {
                    const selectedItem = protectionTypes.find(
                      (item) => item.key === selected,
                    );
                    return selectedItem ? selectedItem.label : "";
                  }}
                >
                  {protectionTypes.map((item) => (
                    <MenuItem key={item.key} value={item.key}>
                      <div>
                        <Typography>{item.label}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {item.description["download"]}
                        </Typography>
                      </div>
                    </MenuItem>
                  ))}
                </Select>

                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                    mt: "1rem",
                  }}
                >
                  <Typography>{t("Policy")}</Typography>
                  <Select
                    style={{ width: "100%" }}
                    name="downloads_protection_policy_id"
                    defaultValue={
                      record && record.downloads_protection_policy
                        ? record.downloads_protection_policy.id
                        : 0
                    }
                    size="small"
                  >
                    {downloadsPolicies.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        <Typography>{item.name}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("Browsing Protection")}</TableCell>
              <TableCell>
                <Select
                  style={{ width: "100%" }}
                  name="browsing_protection"
                  defaultValue={record ? record.browsing_protection : ""}
                  size="small"
                  renderValue={(selected) => {
                    const selectedItem = protectionTypes.find(
                      (item) => item.key === selected,
                    );
                    return selectedItem ? selectedItem.label : "";
                  }}
                >
                  {protectionTypes.map((item) => (
                    <MenuItem key={item.key} value={item.key}>
                      <div>
                        <Typography>{item.label}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {item.description["browsing"]}
                        </Typography>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("User can disable extension")}</TableCell>
              <TableCell>
                <Checkbox
                  style={{ display: "inline-flex" }}
                  name="user_can_disable_extension"
                  checked={userCanDisableExtension}
                  onChange={() =>
                    setUserCanDisableExtension(() => !userCanDisableExtension)
                  }
                />
              </TableCell>
            </TableRow>
            {formType === "company" && (
              <TableRow>
                <TableCell>{t("Authorized login domain(s)")}</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "100%" }}
                    size="small"
                    name="safe_domains"
                    defaultValue={record ? record.safe_domains : ""}
                    helperText={t("Multiple values, separated by comma")}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </>
  );
}
