import React, { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useUserContext } from "@/utils/contexts/UserContext";
import { sentryCaptureException } from "@/utils/sentry";
import { Box, Stack, TextField, Typography } from "@mui/material";
import StickyHeader from "@/components/StickyHeader";
import SearchIcon from "@mui/icons-material/Search";
import TableSort from "@/components/sort/TableSort";
import { ActivateBrowserDefender } from "@/pages/browser-defender/components/ActivateBrowserDefender";
import DataPerCompany from "@/pages/browser-defender/extensionGovernance/DataPerCompany";
import ExtensionActions from "@/pages/browser-defender/extensionGovernance/ExtensionActions";
import { useTranslation } from "react-i18next";

export function ExtensionGovernance() {
  const { t } = useTranslation();

  const sortingColumns = [
    { label: t("Name"), name: "name" },
    { label: t("Number of Users"), name: "users_count" },
    { label: t("Permissions"), name: "risk_level" },
  ];
  const [data, setData] = useState({
    values: [],
    filteredValues: [],
    loading: true,
  });
  const [sorting, setSorting] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedExtension, setSelectedExtension] = useState(null);
  const [currentTab, setCurrentTab] = useState("allowed");
  const [actionLoading, setActionLoading] = useState(false);

  const [activeFeature, setActiveFeature] = useState(true);
  const [error, setError] = useState(false);

  const onChange = (e) => {
    if (e.keyCode !== 13) {
      return;
    }
    const value = e.target.value;
    setSearchTerm(value);
  };

  const { getAccessTokenSilently } = useAuth0();
  const info_user = useUserContext();

  const fetchData = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const url = `${
        import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
      }/logs/browser_extensions_per_company`;
      let resource;

      try {
        resource = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } catch (e) {
        if (e.response.status === 400) {
          setData({
            values: [],
            filteredValues: [],
            loading: false,
          });
          setActiveFeature(false);
          return;
        }
      }

      setData({
        values: resource.data,
        filteredValues: resource.data,
        loading: false,
      });
    } catch (e) {
      sentryCaptureException(e);
      setError(true);
    }
  }, [getAccessTokenSilently]);

  const handleBlock = async () => {
    if (!selectedExtension) return;
    setActionLoading(true);

    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.post(
        `${
          import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
        }/admin_actions/extensions/block`,
        { internal_id: selectedExtension.internal_id },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      if (response.data.status === "success") {
        // Update local state to reflect the change
        setData((prevData) => ({
          ...prevData,
          values: prevData.values.map((ext) =>
            ext.internal_id === selectedExtension.internal_id
              ? { ...ext, blocked: true }
              : ext,
          ),
          filteredValues: prevData.filteredValues.map((ext) =>
            ext.internal_id === selectedExtension.internal_id
              ? { ...ext, blocked: true }
              : ext,
          ),
        }));
        setSelectedExtension(null);
      }
    } catch (e) {
      sentryCaptureException(e);
    } finally {
      setActionLoading(false);
    }
  };

  const handleAllow = async () => {
    if (!selectedExtension) return;
    setActionLoading(true);

    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.post(
        `${
          import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
        }/admin_actions/extensions/allow`,
        { internal_id: selectedExtension.internal_id },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      if (response.data.status === "success") {
        setData((prevData) => ({
          ...prevData,
          values: prevData.values.map((ext) =>
            ext.internal_id === selectedExtension.internal_id
              ? { ...ext, blocked: false }
              : ext,
          ),
          filteredValues: prevData.filteredValues.map((ext) =>
            ext.internal_id === selectedExtension.internal_id
              ? { ...ext, blocked: false }
              : ext,
          ),
        }));
        setSelectedExtension(null);
      }
    } catch (e) {
      sentryCaptureException(e);
    } finally {
      setActionLoading(false);
    }
  };

  useEffect(() => {
    if (!info_user.email) {
      return;
    }

    fetchData();
  }, [fetchData, info_user]);

  useEffect(() => {
    const filterColumns = ["name"];
    let filteredValues = [];
    if (!searchTerm) {
      filteredValues = data.values;
    } else {
      filteredValues = data.values.filter(function (row) {
        return Object.keys(row).some(function (attribute) {
          if (!filterColumns.includes(attribute)) {
            return false;
          }

          if (!row[attribute]) {
            return false;
          }

          let value;

          try {
            value = row[attribute].toLowerCase();
          } catch {
            value = row[attribute];
          }

          return value.indexOf(searchTerm) > -1;
        });
      });
    }

    if (sorting.length === 0) {
      setSorting([{ column: "potentially_dangerous", direction: -1 }]);
    }

    filteredValues.sort((p1, p2) => {
      let condition = 1;
      for (let sortRule of sorting) {
        if (sortRule.column === "users_count") {
          const activeUsers1 = p1.users.filter(
            (obj) => obj.uninstalled === false && obj.enabled === true,
          ).length;
          const activeUsers2 = p2.users.filter(
            (obj) => obj.uninstalled === false && obj.enabled === true,
          ).length;
          condition *=
            activeUsers1 < activeUsers2
              ? -1 * sortRule.direction
              : activeUsers1 > activeUsers2
                ? 1 * sortRule.direction
                : 0;
        } else if (sortRule.column === "risk_level") {
          const highRisk1 = p1.risk_details.some(
            (element) => element.level === "high",
          );
          const mediumRisk1 = p1.risk_details.some(
            (element) => element.level === "medium",
          );
          const riskLevel1 = highRisk1 ? 2 : mediumRisk1 ? 1 : 0;

          const highRisk2 = p2.risk_details.some(
            (element) => element.level === "high",
          );
          const mediumRisk2 = p2.risk_details.some(
            (element) => element.level === "medium",
          );
          const riskLevel2 = highRisk2 ? 2 : mediumRisk2 ? 1 : 0;

          condition *=
            riskLevel1 < riskLevel2
              ? -1 * sortRule.direction
              : riskLevel1 > riskLevel2
                ? 1 * sortRule.direction
                : 0;
        } else {
          condition *=
            p1[sortRule.column] < p2[sortRule.column]
              ? -1 * sortRule.direction
              : p1[sortRule.column] > p2[sortRule.column]
                ? 1 * sortRule.direction
                : 0;
        }
      }
      return condition;
    });

    setData((d) => {
      return { ...d, filteredValues: filteredValues };
    });
  }, [searchTerm, sorting, data.values]);

  if (!activeFeature) {
    return (
      <div style={{ position: "relative", top: 0, left: 0 }}>
        <div
          style={{ position: "absolute", left: "0%", right: "0%", top: "0%" }}
        >
          <ActivateBrowserDefender />
        </div>
      </div>
    );
  }

  if (error) return <div>{t("An error occurred")}</div>;

  return (
    <Box>
      <StickyHeader>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: ".5rem",
          }}
        >
          <Typography
            variant="h2"
            style={{
              marginRight: "2rem",
              maxHeight: "2rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {t("Extension governance")}
          </Typography>

          <TextField
            InputProps={{
              startAdornment: <SearchIcon />,
            }}
            size={"small"}
            label={"Search"}
            onKeyDown={onChange}
          />
          <TableSort
            setOrderBy={setSorting}
            columns={sortingColumns}
            defaultOrder={[{ column: "potentially_dangerous", direction: -1 }]}
          />
          <Box sx={{ ml: "auto" }}>
            <ExtensionActions
              selectedExtension={selectedExtension}
              currentTab={currentTab}
              onAllow={handleAllow}
              onBlock={handleBlock}
            />
          </Box>
        </Stack>
      </StickyHeader>

      <Box style={{ height: "86vh" }}>
        <DataPerCompany
          content={data.filteredValues}
          onExtensionSelect={setSelectedExtension}
          onTabChange={setCurrentTab}
          selectedExtension={selectedExtension}
          loading={data.loading || actionLoading}
        />
      </Box>
    </Box>
  );
}
