import React from "react";
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import GenericCard from "@/pages/browser-defender/components/GenericCard";
import { Spinner } from "@/components/Spinner";
import CustomDialog from "@/pages/browser-defender/components/CustomDialog";
import CompanyConfigurationForm from "@/pages/browser-defender/setup/CompanyConfigurationForm";
import CondensedTableCell from "@/pages/browser-defender/styles/CondensedTableCell";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

export function GroupsSettings({
  groupSettings,
  addGroupSettings,
  saveGroupSettings,
  deleteGroupSettings,
  statusColors,
}) {
  const { t } = useTranslation();

  return (
    <GenericCard title={t("Group level settings")}>
      {groupSettings.loading && <Spinner />}
      <Box style={{ display: "flex", flexDirection: "row-reverse" }}>
        <CustomDialog
          callToActionText={t("Add group settings")}
          actionButtonText={t("Save configuration")}
          actionButtonCallback={(formData) => addGroupSettings(formData)}
          dialogTitle={t("Add group settings")}
        >
          <CompanyConfigurationForm
            formType="group"
            groups={groupSettings.groups}
          />
        </CustomDialog>
      </Box>
      {!groupSettings.loading && (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="left">{t("Group Name")}</TableCell>
                <TableCell align="center">{t("Password Protection")}</TableCell>
                <TableCell align="center">
                  {t("Downloads Protection")}
                </TableCell>
                <TableCell align="center">{t("Browsing Protection")}</TableCell>
                <TableCell align="center">
                  {t("User can disable extension")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groupSettings.data &&
                groupSettings.data.map((setting, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <CondensedTableCell>
                      <CustomDialog
                        callToAction={<EditIcon />}
                        callToActionText={t("Edit group settings")}
                        actionButtonText={t("Save configuration")}
                        actionButtonCallback={(formData) =>
                          saveGroupSettings(
                            setting.group_id,
                            setting.group_name,
                            formData,
                          )
                        }
                        dialogTitle={
                          t("Group settings") + " - " + setting.group_name
                        }
                      >
                        <CompanyConfigurationForm
                          record={setting}
                          formType="group"
                        />
                      </CustomDialog>
                      <Tooltip
                        style={{
                          display: "inline-block",
                          paddingLeft: 0,
                        }}
                        title={t("Delete group settings")}
                      >
                        <DeleteIcon
                          style={{ color: "#fd397a", cursor: "pointer" }}
                          onClick={() => deleteGroupSettings(setting.group_id)}
                        />
                      </Tooltip>
                    </CondensedTableCell>
                    <CondensedTableCell align="left">
                      {setting.group_name}
                    </CondensedTableCell>
                    <CondensedTableCell align="center">
                      {statusColors[setting.password_protection]}
                    </CondensedTableCell>
                    <CondensedTableCell align="center">
                      <Stack
                        direction="row"
                        sx={{
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        {statusColors[setting.downloads_protection]}

                        {setting.downloads_protection_policy ? (
                          setting.downloads_protection_policy.name
                        ) : (
                          <Typography
                            style={{
                              fontStyle: "italic",
                              color: "gray",
                              fontSize: "0.8rem",
                            }}
                          >
                            {t("(Mantra Default)")}
                          </Typography>
                        )}
                      </Stack>
                    </CondensedTableCell>
                    <CondensedTableCell align="center">
                      {statusColors[setting.browsing_protection]}
                    </CondensedTableCell>
                    <CondensedTableCell align="center">
                      {setting.user_can_disable_extension
                        ? statusColors["on"]
                        : statusColors["off"]}
                    </CondensedTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </GenericCard>
  );
}
