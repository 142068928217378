import React, { useEffect, useState } from "react";
import { Drawer } from "@mui/material";
import CollapsibleTable from "@/pages/browser-defender/components/CollapsibleTable";
import TableRowWithMoreButton from "@/pages/browser-defender/components/TableRowWithMoreButton";
import TableCell from "@mui/material/TableCell";
import ActionChip from "@/pages/browser-defender/components/ActionChip";
import {
  CompanyDomainLabel,
  MantraDomainLabel,
} from "@/pages/browser-defender/passwordMisuse/domain-label/DomainLabel";
import PotentialPhishingDetails from "@/pages/browser-defender/passwordMisuse/phishing-attack/PotentialPhishingDetails";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const filterColumns = [
  "firstname",
  "lastname",
  "email",
  "domain",
  "company_domain_label",
];

export default function DataTable({ data, setData, setLabelSelectionStatus }) {
  const [sorting, setSorting] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  const [domainFilter, setDomainFilter] = useState("");
  const [openDetails, setOpenDetails] = useState({});
  const { t } = useTranslation();

  const headers = [
    { label: t("Domain"), alignment: "left" },
    { label: t("Mantra analysis"), alignment: "center" },
    { label: t("User action"), alignment: "center" },
    { label: t("Admin label"), alignment: "center" },
    { label: t("User"), alignment: "left" },
    { label: t("Event date (UTC)"), alignment: "right" },
    { label: t("Remediation"), alignment: "center" },
  ];

  const sortingColumns = [
    { label: t("Email"), name: "email" },
    { label: t("Domain"), name: "domain" },
    { label: t("Admin label"), name: "company_domain_label" },
  ];

  useEffect(() => {
    let filteredValues = data.values;

    if (searchTerm) {
      filteredValues = data.values.filter(function (row) {
        return Object.keys(row).some(function (attribute) {
          if (!filterColumns.includes(attribute)) {
            return false;
          }

          if (!row[attribute]) {
            return false;
          }

          let value;

          try {
            value = row[attribute].toLowerCase();
          } catch {
            value = row[attribute];
          }

          return value.indexOf(searchTerm) > -1;
        });
      });
    }

    function parseDates(stringDate) {
      let [dateComp, timeComp] = stringDate.split(" ");
      let [day, month, year] = dateComp.split("-");
      let [hours, minutes] = timeComp.split(":");
      return new Date(+year, +month - 1, +day, +hours, +minutes);
    }

    if (sorting) {
      filteredValues.sort((p1, p2) => {
        let condition = 1;

        for (let sortRule of sorting) {
          if (sortRule.column === "event_date") {
            const parsedDate1 = parseDates(p1[sortRule.column]);
            const parsedDate2 = parseDates(p2[sortRule.column]);

            condition *=
              parsedDate1 < parsedDate2
                ? 1 * sortRule.direction
                : parsedDate1 > parsedDate2
                  ? -1 * sortRule.direction
                  : 0;
            continue;
          } else if (sortRule.column === "user_action") {
            const value1 = p1[sortRule.column] || "";
            const value2 = p2[sortRule.column] || "";

            condition *=
              value1 < value2
                ? -1 * sortRule.direction
                : value1 > value2
                  ? 1 * sortRule.direction
                  : 0;
          } else if (sortRule.column === "impersonated_brand") {
            const value1 = p1[sortRule.column] ? 1 : 0;
            const value2 = p2[sortRule.column] ? 1 : 0;

            condition *=
              value1 < value2
                ? -1 * sortRule.direction
                : value1 > value2
                  ? 1 * sortRule.direction
                  : 0;
          } else {
            condition *=
              p1[sortRule.column] < p2[sortRule.column]
                ? -1 * sortRule.direction
                : p1[sortRule.column] > p2[sortRule.column]
                  ? 1 * sortRule.direction
                  : 0;
          }
        }

        return condition;
      });
    }

    setData({
      values: data.values,
      filteredValues: filteredValues,
      loading: false,
    });
  }, [searchTerm, sorting, data.values, setData, domainFilter]);

  function selectAll(event, page, itemsPerPage) {
    const items = data.filteredValues
      .slice(page * itemsPerPage, (page + 1) * itemsPerPage)
      .map((row) => row.id);

    if (event.target.checked) {
      setSelectedRows([...new Set(selectedRows.concat(items))]);
      return;
    }
    setSelectedRows(selectedRows.filter((el) => !items.includes(el)));
  }

  function selectSingleItem(id) {
    const selectedIndex = selectedRows.indexOf(id);

    let newSelected = [];

    if (selectedIndex === -1) {
      return newSelected.concat(selectedRows, id);
    }
    if (selectedIndex === 0) {
      return newSelected.concat(selectedRows.slice(1));
    }

    if (selectedIndex === selectedRows.length - 1) {
      return newSelected.concat(selectedRows.slice(0, -1));
    }

    return newSelected.concat(
      selectedRows.slice(0, selectedIndex),
      selectedRows.slice(selectedIndex + 1),
    );
  }

  function addToSelect(id) {
    let newSelected = selectSingleItem(id);
    setSelectedRows(newSelected);
  }

  useEffect(() => {
    setLabelSelectionStatus({
      archive: selectedRows.length > 0,
      label: selectedRows.length > 0,
      selectedRows: selectedRows,
    });
  }, [selectedRows, data.filteredValues, setLabelSelectionStatus]);

  function addFilterChip(domain) {
    setDomainFilter(domain);
    setSearchTerm(domain);
  }

  function clearDomainFilter() {
    setDomainFilter("");
    setSearchTerm("");
  }

  return (
    <CollapsibleTable
      headers={headers}
      sortingColumns={sortingColumns}
      setSorting={setSorting}
      setSearchTerm={setSearchTerm}
      customFilter={domainFilter}
      clearCustomFilter={clearDomainFilter}
      defaultOrder={[{ column: "event_date", direction: 1 }]}
      checkboxSelection={true}
      selectAll={selectAll}
      rows={data.filteredValues.map((row, index) => {
        return (
          <>
            <TableRowWithMoreButton
              row={row}
              seeMore={setOpenDetails}
              isSelected={selectedRows.indexOf(row.id) > -1}
              selectCallback={addToSelect}
            >
              <TableCell
                component="th"
                scope="row"
                align={
                  headers[0].alignmentContent
                    ? headers[0].alignmentContent
                    : headers[0].alignment
                }
              >
                <Link
                  onClick={(event) => {
                    event.stopPropagation();
                    addFilterChip(row.domain);
                  }}
                >
                  {row.domain}
                </Link>
              </TableCell>
              <TableCell
                align={
                  headers[1].alignmentContent
                    ? headers[1].alignmentContent
                    : headers[1].alignment
                }
              >
                {(row.impersonated_brand ||
                  row.mantra_domain_is_credentials_reuse) && (
                  <MantraDomainLabel
                    potential_phishing={row.impersonated_brand}
                  />
                )}
              </TableCell>
              <TableCell
                align={
                  headers[2].alignmentContent
                    ? headers[2].alignmentContent
                    : headers[2].alignment
                }
              >
                {row.impersonated_brand ? (
                  <ActionChip
                    data={row}
                    colors={{
                      ignore_once: {
                        bgColor: "#e0704a",
                        textColor: "#c71e04",
                      },
                    }}
                    actionMapping={{ ignore_once: t("Alert ignored") }}
                  />
                ) : (
                  row.user_action === "mark_as_safe" && (
                    <ActionChip
                      data={row}
                      colors={{
                        mark_as_safe: {
                          bgColor: "#ffaf2c",
                          textColor: "#BE7A0C",
                        },
                      }}
                      actionMapping={{ mark_as_safe: t("Domain trusted") }}
                    />
                  )
                )}
              </TableCell>
              <TableCell
                align={
                  headers[3].alignmentContent
                    ? headers[3].alignmentContent
                    : headers[3].alignment
                }
              >
                <CompanyDomainLabel
                  company_domain_label={row.company_domain_label}
                  editor={row.editor}
                />
              </TableCell>
              <TableCell
                align={
                  headers[4].alignmentContent
                    ? headers[4].alignmentContent
                    : headers[4].alignment
                }
              >
                {row.email}
              </TableCell>
              <TableCell
                style={{ color: "#A2A7B1", fontStyle: "italic" }}
                align={
                  headers[5].alignmentContent
                    ? headers[5].alignmentContent
                    : headers[5].alignment
                }
              >
                {row.event_date}
              </TableCell>
            </TableRowWithMoreButton>
            <Drawer
              open={Object.keys(openDetails).length > 0}
              onClose={() => setOpenDetails(false)}
              anchor="right"
            >
              <PotentialPhishingDetails
                row={openDetails}
                close={() => setOpenDetails(false)}
              />
            </Drawer>
          </>
        );
      })}
    />
  );
}
