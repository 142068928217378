import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ThreatSourceParams } from "@/pages/threats/service/types";

type ThreatListTab =
  | "active-threats"
  | "active-user-threats"
  | "active-system-threats"
  | "archived-threats";

type ThreatTabsProps = {
  disableBannersInThreats?: boolean;
  onChange: (props: ThreatSourceParams) => void;
};

const ThreatTabs = ({
  onChange,
  disableBannersInThreats = false,
}: ThreatTabsProps) => {
  const [activeTab, setActiveTab] = useState<ThreatListTab>(
    disableBannersInThreats ? "active-user-threats" : "active-threats",
  );
  const { t } = useTranslation();

  const handleTabChange = (newTab: ThreatListTab) => {
    setActiveTab(newTab);

    switch (newTab) {
      case "active-threats":
        onChange({ archived: false, source: null });
        break;
      case "active-user-threats":
        onChange({ archived: false, source: "user" });
        break;
      case "active-system-threats":
        onChange({ archived: false, source: "system" });
        break;
      case "archived-threats":
        onChange({ archived: true, source: null });
        break;
      default:
        throw new Error(t("Invalid tab: {{newTab}}", { newTab: newTab }));
    }
  };

  return (
    <Tabs
      value={activeTab}
      onChange={(_, nextTab) => handleTabChange(nextTab)}
      sx={{ minHeight: "45px", height: "45px" }}
    >
      {!disableBannersInThreats && (
        <Tab disableRipple label={t("All threats")} value="active-threats" />
      )}
      <Tab
        disableRipple
        label={t("User Reports")}
        value="active-user-threats"
      />
      {!disableBannersInThreats && (
        <Tab
          disableRipple
          label={t("Banner alerts")}
          value="active-system-threats"
        />
      )}
      <Tab disableRipple label={t("Archived")} value="archived-threats" />
    </Tabs>
  );
};

export default ThreatTabs;
