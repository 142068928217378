import {
  APIBannerUser,
  BannerToggleUsers,
} from "@/pages/banners/remote-apis/bannerUsers";
import { createApi } from "@reduxjs/toolkit/query/react";
import { enqueueSnackbar } from "notistack";
import { simulationBaseQuery } from "@/utils/rtkQuery";
import {
  StatsData,
  StatsDataApi,
} from "@/pages/banners/remote-apis/bannerStatistics";
import {
  ContactType,
  SafeContact,
} from "@/pages/banners/remote-apis/contact_types";

type CompanyApiSafeDomain = {
  domain: string;
  added_at: Date;
  added_by: string;
};

type CompanyApiSafeSender = {
  sender: string;
  added_at: Date;
  added_by: string;
};

export type CompanyApiType = {
  company_domain: string;
  safe_domains: CompanyApiSafeDomain[];
  safe_senders: CompanyApiSafeSender[];
};

export type CompanySafeListsType = {
  company_domain: string;
  safeDomains: SafeContact[];
  safeSenders: SafeContact[];
};

const snackbarError = async (arg, { queryFulfilled }) => {
  try {
    await queryFulfilled;
  } catch (error) {
    enqueueSnackbar(error?.error?.data?.error ?? "Unknown Error", {
      variant: "error",
    });
  }
};

export const bannersUsersApi = createApi({
  reducerPath: "banners-users-api",
  baseQuery: simulationBaseQuery(),
  tagTypes: ["UserList", "ActiveUserList", "Stats", "Company"],
  endpoints: (builder) => ({
    users: builder.query<APIBannerUser[], void>({
      query: () => ({
        url: "/banners/users",
        method: "GET",
      }),
      providesTags: ["UserList"],
      onQueryStarted: snackbarError,
    }),
    activeUsers: builder.query<APIBannerUser[], void>({
      query: () => ({
        url: "/banners/users",
        params: { status: "active" },
        method: "GET",
      }),
      providesTags: ["ActiveUserList"],
      onQueryStarted: snackbarError,
    }),
    engagedUsers: builder.query<APIBannerUser[], void>({
      query: () => ({
        url: "/banners/users",
        params: { status: "engaged" },
        method: "GET",
      }),
      providesTags: ["ActiveUserList"],
      onQueryStarted: snackbarError,
    }),
    engageOrDisengage: builder.mutation<void, BannerToggleUsers>({
      query: (body) => ({
        url: "/user/gmail_controller",
        body: body,
        method: "POST",
      }),
      invalidatesTags: ["UserList", "ActiveUserList"],
      onQueryStarted: snackbarError,
    }),
    disengageCompany: builder.mutation<void, void>({
      query: () => ({ url: "/company/disengage", method: "POST" }),
      invalidatesTags: ["UserList", "ActiveUserList"],
      onQueryStarted: snackbarError,
    }),
    batchEngage: builder.mutation<void, BannerToggleUsers[]>({
      query: (body) => ({
        url: "/user/gmail_controller/toggle_users",
        body: body,
        method: "POST",
      }),
      invalidatesTags: ["UserList", "ActiveUserList"],
      onQueryStarted: snackbarError,
    }),
    stats: builder.query<StatsData, { startDate: Date; endDate: Date }>({
      query: ({ startDate, endDate }) => ({
        url: "/banners/stats/banners",
        params: {
          start_date: startDate.toISOString(),
          end_date: endDate.toISOString(),
        },
      }),
      providesTags: ["Stats"],
      transformResponse: (response: StatsDataApi) => {
        const dailyStatistics = response.stats_per_day.map((stat) => {
          return {
            date: stat.date,
            bannerInfo: stat.with_info,
            bannerSuspicious: stat.with_suspicious,
            bannerVerySuspicious: stat.with_very_suspicious,
            mailsProcessed: stat.processed,
          };
        });

        return {
          dailyStatistics,
          messagesProcessed: response.messages_processed,
          messagesWithBanner: response.messages_with_banner,
          mailboxesMonitored: response.mailboxes_monitored,
          bannerTypes: response.banners_types,
        };
      },
    }),
    companySafeSenders: builder.query<
      CompanySafeListsType,
      { companyId: number }
    >({
      query: ({ companyId }) => ({ url: `/banners/companies/${companyId}/` }),
      onQueryStarted: snackbarError,
      providesTags: ["Company"],
      transformResponse: (response: CompanyApiType) => {
        return {
          company_domain: response.company_domain,
          safeSenders: response.safe_senders.map((e) => ({
            item: e.sender,
            type: ContactType.email_address,
            added_at: e.added_at,
            added_by: e.added_by,
          })),
          safeDomains: response.safe_domains.map((e) => ({
            item: e.domain,
            type: ContactType.domain,
            added_at: e.added_at,
            added_by: e.added_by,
          })),
        };
      },
    }),
    deleteCompanySafeSenders: builder.mutation<
      void,
      { companyId: number; type: ContactType; items: string[] }
    >({
      query: ({ companyId, type, items }) => {
        switch (type) {
          case ContactType.email_address:
            return {
              url: `/banners/companies/${companyId}/remove_safe_senders`,
              method: "DELETE",
              body: { email_addresses: items },
            };
          case ContactType.domain:
            return {
              url: `/banners/companies/${companyId}/remove_safe_domains`,
              method: "DELETE",
              body: { domains: items },
            };
        }
      },
      onQueryStarted: snackbarError,
      invalidatesTags: ["Company"],
    }),
    saveCompanySafeSenders: builder.mutation<
      void,
      { companyId: number; type: ContactType; items: string[] }
    >({
      query: ({ companyId, type, items }) => {
        switch (type) {
          case ContactType.email_address:
            return {
              url: `/banners/companies/${companyId}/add_safe_senders`,
              method: "POST",
              body: { email_addresses: items },
            };
          case ContactType.domain:
            return {
              url: `/banners/companies/${companyId}/add_safe_domains`,
              method: "POST",
              body: { domains: items },
            };
        }
      },
      onQueryStarted: snackbarError,
      invalidatesTags: ["Company"],
    }),
  }),
});

export const {
  useUsersQuery,
  useActiveUsersQuery,
  useEngagedUsersQuery,
  useEngageOrDisengageMutation,
  useDisengageCompanyMutation,
  useBatchEngageMutation,
  useStatsQuery,
  useCompanySafeSendersQuery,
  useDeleteCompanySafeSendersMutation,
  useSaveCompanySafeSendersMutation,
} = bannersUsersApi;
