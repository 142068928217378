import React from "react";
import { Tooltip, useTheme } from "@mui/material";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import { FeedbackLG } from "@/pages/threats/service/domain";
import {
  ThreatSafeIcon,
  ThreatSpamIcon,
  ThreatSuspiciousIcon,
  ThreatUnsafeIcon,
} from "@/pages/threats/components/FeedbackIcons";
import { Stack } from "@mui/system";

type ThreatStatusIconProps = {
  feedback: FeedbackLG | null;
};

const ThreatStatusIcon = ({ feedback }: ThreatStatusIconProps) => {
  const theme = useTheme();

  if (feedback == null) {
    return null;
  }
  let statusIcon = null;
  switch (feedback.type.toLowerCase()) {
    case "spam":
      statusIcon = <ThreatSpamIcon />;
      break;
    case "safe":
      statusIcon = <ThreatSafeIcon />;
      break;
    case "unsafe":
      statusIcon = <ThreatUnsafeIcon />;
      break;
    default:
    case "suspicious":
      statusIcon = <ThreatSuspiciousIcon />;
  }
  return (
    <Stack direction="row">
      {statusIcon}
      {feedback.comment && (
        <Tooltip
          title={feedback.comment}
          placement="right-end"
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [-7, -20],
                  },
                },
              ],
            },
          }}
        >
          <ChatOutlinedIcon htmlColor={theme.palette.info.main} />
        </Tooltip>
      )}
    </Stack>
  );
};

export default ThreatStatusIcon;
