import { MutableRefObject } from "react";
import { MenuItemType } from "@/components/menu/MenuItem";

/**
 * Return an array of strings contening all the elements and
 * their parents for a given menuConfig.
 */
export function getActivePaths(
  currentConfig: MenuItemType[],
  currentLocation: string,
): MenuItemType[] {
  const traverse = (
    node: MenuItemType,
    pathToSearch: string,
    path: MenuItemType[],
  ): MenuItemType | null => {
    if (!node.submenu) {
      if (node.path === pathToSearch) {
        path.push(node);
        return node;
      }
      if (node.relatedPaths) {
        for (const i of node.relatedPaths) {
          if (pathToSearch.match(i)) {
            path.push(node);
            return node;
          }
        }
      }
      path.pop();
      return null;
    }
    for (const i of node.submenu) {
      const givenNode: MenuItemType | null = traverse(i, pathToSearch, path);
      if (givenNode) {
        path.push(node);
        return givenNode;
      }
    }
    path.pop();
    return null;
  };

  // need to threat currentConfig as a submenu
  // of a root item to simplify the traversal
  const root: MenuItemType = {
    title: null,
    pathname: "ROOTPATH-DUMMY",
    submenu: currentConfig,
  };
  const path: MenuItemType[] = [];
  traverse(root, currentLocation, path);
  return path;
}

export function animateSubmenu(
  menu: MutableRefObject<HTMLDivElement>,
  isOpen: boolean,
): void {
  if (!menu.current) {
    return;
  }
  if (isOpen) {
    menu.current.style.height = `${menu.current.scrollHeight}px`;
  } else {
    menu.current.style.height = "0";
  }
}
