import React from "react";
import styled from "styled-components";

const colorPerLanguage = {
  fr: "rgba(36, 61, 204, .3)",
  en: "rgba(204, 54, 36, .3)",
  it: "rgba(114, 201, 103, .4)",
  de: "rgba(185, 185, 185, .8)",
  es: "rgba(231,231,30,0.5)",
};

const TagDisplay = styled.div<{ $language: string }>`
  color: #000000;
  text-align: center;
  position: relative;
  height: 20px;
  line-height: 20px;
  font-size: 10px;
  width: 30px;
  background-color: ${(props) =>
    colorPerLanguage[props.$language] || "rgba(185, 185, 185)"};
  border-radius: 7px;
`;

export const LanguageTag = ({ language }) => (
  <TagDisplay $language={language}>
    {language.slice(0, 2).toUpperCase()}
  </TagDisplay>
);
