import { Box, Card, CardContent } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";

export function OktaSync() {
  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Box my={2}>
          <Trans i18nKey="InstructionsOktaSync">
            Please follow{" "}
            <a
              href="https://www.notion.so/mantrams/Okta-SCIM-Provisioning-f45b2f511419461da2c59e6ea7ceb9f0"
              rel="noopener noreferrer"
              target="_blank"
            >
              the Mantra-Okta integration guide
            </a>{" "}
            to configure Okta user provisioning.
          </Trans>
        </Box>
      </CardContent>
    </Card>
  );
}
