import React, { useMemo } from "react";
import {
  Chart,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

Chart.register(BarController, BarElement, CategoryScale, LinearScale, Tooltip);

export default function ClicksPerDimensionChart({
  labels,
  clickRates,
  displayXTitle = true,
  backgroundColor = "#1662ff",
}) {
  const { t } = useTranslation();

  // calculate the max for the y axis. Should be 100 if there is no data
  let max = Math.max(...clickRates);
  if (max === 0) {
    max = 100;
  }

  clickRates = clickRates.map((clickRate) =>
    clickRate >= 100 ? 100 : clickRate,
  );

  // update labels and translate "No department" and UNKNOW to the correct translation or keep the label
  // We need to do this as is as these labels are hard coded in simulation API
  labels = labels.map((label) => {
    if (label === "No department") {
      return t("No department");
    } else if (label === "UNKNOW") {
      return t("UNKNOW");
    }
    return label;
  });

  const chartData = useMemo(
    () => ({
      labels: labels,
      datasets: [
        {
          fill: true,
          data: clickRates,
          categoryPercentage: 0.35,
          barPercentage: 0.7,
          backgroundColor: backgroundColor,
          borderRadius: 5,
        },
      ],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [backgroundColor, JSON.stringify(clickRates), JSON.stringify(labels)],
  );

  const chartConfig = {
    indexAxis: "y" as const,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: true,
        title: {
          display: displayXTitle,
          text: t("Click Rate") + " %",
        },
        grid: { display: false },
        ticks: {
          display: true,
          beginAtZero: true,
          fontSize: 13,
          padding: 0,
          backdropPadding: 0,
          max: max,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          callback: function (index) {
            const label = this.getLabelForValue(index);
            const labelLength = 20;
            if (label.length > labelLength) {
              return label.substring(0, labelLength - 3) + "...";
            }
            return label;
          },
          showLabelBackdrop: true,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: (tooltipItem) => tooltipItem.formattedValue + " %",
        },
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 5,
        bottom: 5,
      },
    },
  };

  return (
    <div style={{ height: "250px" }}>
      <Bar data={chartData} options={chartConfig} />
    </div>
  );
}
