import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/store";
import { AwarenessSetupChecklist } from "./SetupChecklistTypes";
import { SetupStepper } from "./Checklist/AwarenessSetupStepperService";

const initialState: AwarenessSetupChecklist = {
  messagingPlatform: null,
  oneCourseAssigned: false,
  courseDeliveryActivated: false,
  messagingPlatformInstalled: false,
  isLoading: true,
};

const awarenessChecklistSlice = createSlice({
  name: "awareness-checklist",
  initialState,
  reducers: {
    updateChecklistState: (state, action): AwarenessSetupChecklist => {
      return {
        messagingPlatform: action.payload.messaging_platform,
        oneCourseAssigned: action.payload.one_course_assigned,
        courseDeliveryActivated: action.payload.course_delivery_activated,
        messagingPlatformInstalled: action.payload.messaging_platform_installed,
        isLoading: state.isLoading,
      };
    },
    updateMessagingPlatformInstalledStatus: (state, action) => {
      state.messagingPlatformInstalled = action.payload;
    },
    updateMessagingPlatform: (state, action) => {
      state.messagingPlatform = action.payload;
    },
    updateIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  updateChecklistState,
  updateMessagingPlatformInstalledStatus,
  updateIsLoading,
} = awarenessChecklistSlice.actions;

export const selectAwarenessSetupChecklistState = (
  state: RootState,
): AwarenessSetupChecklist => {
  return state.awarenessChecklist;
};

export const selectIsLoading = (state: RootState): boolean => {
  return state.awarenessChecklist.isLoading;
};

export const selectStepperFromChecklistState = (state: RootState) => {
  return new SetupStepper(state.awarenessChecklist).steps;
};
export const selectMessagingPlatform = (state: RootState) => {
  return state.awarenessChecklist.messagingPlatform;
};

export default awarenessChecklistSlice.reducer;
