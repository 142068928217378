import React, { useMemo, useState } from "react";
import { useBackend } from "@/utils/misc";
import { Card, CardContent, CardHeader, Stack } from "@mui/material";
import { LeaderboardList } from "@/pages/my-progress/leaderboard/LeaderboardList";
import { useTranslation } from "react-i18next";
import { Spinner } from "@/components/Spinner";
import dayjs from "dayjs";
import DateRangePicker from "@/components/controls/DateRangePicker/DateRangePicker";
import { MANTRA_CREATION_DATE } from "@/utils/constants";
import { useUserContext } from "@/utils/contexts/UserContext";
import { DownloadLeaderboardButton } from "./DownloadLeaderboardButton";

const ROUTE =
  import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/users/leaderboard?";

export function Leaderboard() {
  const { t } = useTranslation();
  const { is_admin } = useUserContext();

  const dateRangePeriods = useMemo(() => {
    const to = dayjs();
    return [
      {
        period: t("Quarter"),
        dateRange: { from: to.subtract(3, "months"), to: to },
      },
      {
        period: t("Year"),
        dateRange: { from: to.subtract(1, "year"), to: to },
      },
      {
        period: t("All times"),
        dateRange: { from: MANTRA_CREATION_DATE, to: to },
      },
    ];
  }, [t]);

  const [dateRange, setDateRange] = useState(dateRangePeriods[0].dateRange);

  let urlParams = useMemo(
    () =>
      new URLSearchParams({
        date_from: dateRange.from.toISOString(),
        date_to: dateRange.to.toISOString(),
      }),
    [dateRange.from, dateRange.to],
  );

  const { data, error, isPending } = useBackend(ROUTE + urlParams.toString());

  let body: JSX.Element;
  if (isPending || !data) {
    body = <Spinner />;
  } else if (error && error?.response?.status === 403) {
    body = <div>{t("The leaderboard will be available shortly.")}</div>;
  } else if (error) {
    body = <div>{t("An error occurred")}</div>;
  } else if (data) {
    if (data?.length === 0) {
      body = <div>{t("No data available")}</div>;
    } else {
      body = <LeaderboardList data={data} />;
    }
  }

  return (
    <Card sx={{ height: "unset" }}>
      <CardHeader
        title={t("Leaderboard")}
        action={
          is_admin ? <DownloadLeaderboardButton dateRange={dateRange} /> : <></>
        }
      />
      <Stack direction="row" justifyContent="center" mx={2} mt={1}>
        <DateRangePicker
          periods={dateRangePeriods}
          onChange={(_, dateRange) => setDateRange(dateRange)}
        />
      </Stack>
      <CardContent sx={{ paddingTop: 2 }}>{body}</CardContent>
    </Card>
  );
}
