import * as React from "react";
import { Box, Typography } from "@mui/material";
import {
  mantraDomainLabelOptions,
  passwordMisuseDomainLabelButtonOptions,
} from "@/pages/browser-defender/passwordMisuse/domain-label/configuration";
import LabelItem from "@/pages/browser-defender/passwordMisuse/domain-label/LabelItem";
import { useTranslation } from "react-i18next";

const transformedAdminLabelOptions =
  passwordMisuseDomainLabelButtonOptions.reduce((acc, item) => {
    acc[item.key] = item.value;
    return acc;
  }, {});

const transformedMantraLabelOptions = mantraDomainLabelOptions.reduce(
  (acc, item) => {
    acc[item.key] = item.value;
    return acc;
  },
  {},
);

export function CompanyDomainLabel({ company_domain_label, editor }) {
  let label = company_domain_label;
  const { t } = useTranslation();

  return (
    <Box>
      {label ? (
        <LabelItem
          icon={transformedAdminLabelOptions[label].icon}
          title={transformedAdminLabelOptions[label].title}
          description={
            company_domain_label && t("Labelled by {{name}}", { name: editor })
          }
        />
      ) : (
        <Typography style={{ fontStyle: "italic", color: "#6A707B" }}>
          {t("Not labelled")}
        </Typography>
      )}
    </Box>
  );
}

export function MantraDomainLabel({ potential_phishing }) {
  let info =
    transformedMantraLabelOptions[
      potential_phishing ? "potential_phishing" : "reuse"
    ];
  const { t } = useTranslation();

  return (
    <Box>
      {info && (
        <LabelItem
          icon={info.icon}
          title={info.title}
          description={
            potential_phishing
              ? t("AI-based detection")
              : t("Mantra knowledge base")
          }
        />
      )}
    </Box>
  );
}
