import React, { useEffect, useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  AccordionDetails,
  Button,
  Collapse,
  Divider,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import { FullPageSpinner } from "@/components/Spinner";
import { ThreatAttachment } from "./ThreatAttachments";
import style from "./Threats.module.css";
import { useSnackbar } from "material-ui-snackbar-provider";
import { setIFrameHeight } from "@/utils/misc";
import { useTranslation } from "react-i18next";

type ThreatDetailsProps = {
  details: ThreatDetailType;
  isLoading: boolean;
};
type ThreatHeader = {
  name: string;
  value: string;
};

export function ThreatDetails({ details, isLoading }: ThreatDetailsProps) {
  const showThreat = !isLoading && details?.body?.length > 0;
  const [isOpenCollapse, setIsOpenCollapse] = useState(false);
  const snackBar = useSnackbar();
  const { t } = useTranslation();
  const showHeaders = !isLoading && details?.headers?.length > 0;
  const copyToClipboard = (headers) => {
    const headersToExport = headers.reduce(
      (key, header) => `${key}${header.name}:  ${header.value}\n`,
      "",
    );
    navigator.clipboard.writeText(headersToExport);
    snackBar.showMessage("Copy successfull");
  };

  useEffect(() => {
    setIsOpenCollapse(false);
  }, [details]);

  let color: string;
  switch (details.dkim_status) {
    case "Missing":
      color = "danger";
      break;
    case "Pass":
      color = "success";
      break;
    default:
      color = "light";
  }

  return (
    <Paper className={style.threatDetails}>
      {isLoading ? (
        <FullPageSpinner />
      ) : (
        <div
          style={{
            visibility: showThreat ? "visible" : "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.8rem",
              padding: "1.5rem 3rem",
            }}
          >
            <div style={{ display: "flex", gap: "5px" }}>
              <Typography fontSize={14} fontWeight={500} noWrap>
                {details.from_name}
              </Typography>
              <Typography fontSize={14} fontWeight={700} noWrap>
                &lt;{details.from_email}&gt;
              </Typography>
            </div>
            <div
              style={{ color: "#5012FF", fontSize: "14px", fontWeight: "600" }}
            >
              {details.subject}
            </div>
            <div style={{ fontSize: "14px" }}>
              <span style={{ fontWeight: "700" }}>{t("To")} : </span>
              <span style={{ fontWeight: "400" }}>{details.receiver}</span>
            </div>
            <div>
              <div className={`badge badge-${color}`}>
                {"Dkim"} : {details.dkim_status}
              </div>
            </div>
            <div style={{ display: "flex", gap: "16px", overflowX: "scroll" }}>
              {details?.attachments?.map((x: AttachmentType) => {
                return (
                  <ThreatAttachment
                    attachment={x}
                    key={x.name + x.content_type + x.size}
                  />
                );
              })}
            </div>

            <Tooltip
              title={
                showHeaders
                  ? "Show headers"
                  : "No headers available for this email."
              }
              style={{ alignSelf: "flex-end" }}
            >
              <div>
                <Button
                  variant="text"
                  startIcon={
                    isOpenCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />
                  }
                  disabled={!showHeaders}
                  style={!showHeaders ? { pointerEvents: "none" } : {}}
                  onClick={() => setIsOpenCollapse(!isOpenCollapse)}
                  sx={{ maxWidth: "200px", alignSelf: "flex-end" }}
                >
                  {t("Email headers")}
                </Button>
                <IconButton disabled={!showHeaders}>
                  <CopyAllIcon
                    fontSize={"small"}
                    sx={{ color: "#bdbdbd" }}
                    onClick={() => copyToClipboard(details.headers)}
                  />
                </IconButton>
              </div>
            </Tooltip>

            <Collapse
              in={showHeaders ? isOpenCollapse : false}
              sx={{ maxHeight: "200px", overflow: "auto" }}
            >
              <AccordionDetails sx={{ bgcolor: "#f5f5f5" }}>
                {details?.headers?.map((header: ThreatHeader, index) => {
                  return (
                    <div key={index}>
                      <strong>{header.name}</strong>: {header.value}
                    </div>
                  );
                })}
              </AccordionDetails>
            </Collapse>
          </div>

          <Divider sx={{ mx: "1rem", mb: "1.5rem" }} />
          <div
            style={{
              margin: "0 2rem",
              overflow: "scroll",
              height: "57vh",
            }}
          >
            <iframe
              sandbox="allow-same-origin"
              width="100%"
              title="Threat view"
              onLoad={(e) => setIFrameHeight(e.target as HTMLIFrameElement)}
              onChange={(e) => setIFrameHeight(e.target as HTMLIFrameElement)}
              srcDoc={details.body}
              style={{
                border: 0,
                outline: 0,
              }}
            />
          </div>
          <Divider sx={{ mx: "1rem", mb: "1.5rem" }} />
        </div>
      )}
    </Paper>
  );
}
