import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Button, Divider, TextField } from "@mui/material";
import { UserWithPermissions } from "@/pages/general-settings/permissions-attribution/permissions";
import { usePermissionService } from "@/pages/general-settings/permissions-attribution/permissionService";
import { useAdminOptions } from "@/pages/general-settings/permissions-attribution/useAdminOptions";
import { ButtonSpinner } from "@/components/Spinner";
import { useTranslation } from "react-i18next";

function PermissionsFooter({
  targetUser,
  cancel,
  confirm,
  loading,
}: {
  targetUser?: object;
  cancel: () => void;
  confirm: () => void;
  loading: boolean;
}) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: "1rem",
      }}
    >
      <Button variant="outlined" color="error" onClick={cancel}>
        {t("Cancel")}
      </Button>
      <Button
        variant="contained"
        startIcon={loading ? <ButtonSpinner /> : null}
        disabled={targetUser == null || loading === true}
        onClick={confirm}
      >
        {t("Define permissions")}
      </Button>
    </Box>
  );
}

export default function CreatePermission({
  closeDrawer,
  openUserEdit,
  admins,
}: {
  closeDrawer: () => void;
  openUserEdit: (u: UserWithPermissions) => void;
  admins: Array<UserWithPermissions>;
}) {
  const {
    selectedOption,
    setSelectedOption,
    options,
    isLoading,
    onSearchChange,
  } = useAdminOptions(admins);
  const { createUserWithPermissions, createIsLoading, createIsSuccess } =
    usePermissionService();
  const [selectedUser, setSelectedUser] = useState<UserWithPermissions>(null);

  function definePermissions() {
    const newUser: UserWithPermissions = {
      id: selectedOption.id,
      firstname: selectedOption.firstname,
      lastname: selectedOption.lastname,
      email: selectedOption.email,
      permissions: [],
    };
    setSelectedUser(newUser);
    createUserWithPermissions(newUser);
  }
  const { t } = useTranslation();

  useEffect(() => {
    if (
      selectedUser != null &&
      createIsSuccess === true &&
      createIsLoading === false
    ) {
      openUserEdit(selectedUser);
    }
  }, [createIsSuccess, createIsLoading, selectedUser, openUserEdit]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "30vw",
        height: "100%",
      }}
    >
      <Box sx={{ flex: 1, padding: "1rem 1.5rem 0.5rem" }}>
        <h4>{t("Create custom permissions for")}:</h4>
        <Autocomplete
          id="async-autocomplete"
          options={options}
          value={selectedOption}
          getOptionLabel={(option) => option.label}
          loading={isLoading}
          onChange={(event, option) => {
            setSelectedOption(option);
          }}
          onInputChange={(event, value) => onSearchChange(value)}
          renderInput={(params) => (
            <TextField {...params} label="Email" variant="outlined" />
          )}
          loadingText={t("Loading") + "..."}
        />
      </Box>
      <Divider />
      <PermissionsFooter
        targetUser={selectedOption}
        cancel={closeDrawer}
        confirm={definePermissions}
        loading={createIsLoading}
      />
    </Box>
  );
}
