import React from "react";
import { Stack, Typography } from "@mui/material";
import { ExtensionGenerationBadge } from "@/pages/browser-defender/components/ExtensionGenerationBadge";
import { useTranslation } from "react-i18next";
import GenericCard from "@/pages/browser-defender/components/GenericCard";

interface BrowserDefenderExtensionInformationProps {
  config: {
    extension_generation?: string;
  };
}

export function BrowserDefenderExtensionInformation({
  config,
}: BrowserDefenderExtensionInformationProps) {
  const { t } = useTranslation();

  return (
    <GenericCard title={t("Browser Defender extension information")}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography color="textSecondary">
          {t("Extension generation: ")}
        </Typography>
        <ExtensionGenerationBadge generation={config.extension_generation} />
      </Stack>
    </GenericCard>
  );
}
