import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const CourseSharingHeaderFirstRow = ({ onClickShareAll }) => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      justifyContent={"space-between"}
      sx={{
        gap: "1rem",
        display: "flex",
        paddingBottom: "10px",
        borderBottom: 1,
        borderColor: "divider",
      }}
    >
      <Grid item>
        <Typography variant="h3">{t("Course sharing")}</Typography>
      </Grid>
      <Grid item>
        <Button
          sx={{ marginLeft: "15px" }}
          size="small"
          variant="outlined"
          color={"primary"}
          onClick={onClickShareAll}
        >
          {t("Share all courses with all companies")}
        </Button>
      </Grid>
    </Grid>
  );
};
