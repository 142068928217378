import DataPerUserCollapseRow from "@/pages/phishing-simulation/dashboard/DataPerUsersTable/DataPerUserCollapseRow";
import { Box, Collapse, Table } from "@mui/material";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { useTranslation } from "react-i18next";

export default function DataPerUserCollapseComponent({
  openCollapse,
  rowData,
}) {
  const { t } = useTranslation();
  const TableCellThin = styled(TableCell)`
    border-bottom: 0;
    height: 1rem;
    padding-bottom: 5px;
    padding-top: 5px;
  `;

  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
        <Collapse
          in={openCollapse}
          timeout="auto"
          unmountOnExit
          key={rowData.id + "_collapsed"}
        >
          <Box sx={{ margin: 1, maxHeight: "540px", overflowY: "scroll" }}>
            <Table stickyHeader>
              <TableHead sx={{ backgroundColor: "unset" }}>
                <TableRow sx={{ width: "100%" }}>
                  <TableCellThin sx={{ width: "20%" }}>
                    {t("Date")}
                  </TableCellThin>
                  <TableCellThin sx={{ width: "30%" }}>
                    {t("Simulation template")}
                  </TableCellThin>
                  <TableCellThin sx={{ width: "25%" }}>
                    {t("Payload")}
                  </TableCellThin>
                  <TableCellThin sx={{ width: "25%" }}>
                    {t("Status")}
                  </TableCellThin>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData.emailmessages.map((emailMessage) => {
                  return (
                    <DataPerUserCollapseRow
                      emailMessage={emailMessage}
                      key={JSON.stringify(emailMessage)}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
}
